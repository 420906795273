/*
* This file contains the parentLocationTreeSelect component,
* a specialization of the formFieldTreeSelect component.
* In particular, it hides the currently opened Location (in the detail panel) and its descendants
* as valid parent options.
* */

import {
  AutocompleteTreeRenderer,
  AutocompleteTreeNode,
  AutocompleteTreeNodeData,
} from "../autocomplete/tree_select/autocompleteTreeSelect.js";


export class ParentLocationTreeNodeData extends AutocompleteTreeNodeData {

  init() {
    super.init();
    // Since the visibility of the options depend on the currently selected location, ensure they are in sync.
    this.$watch('controller.data', () => {
      this.updateVisibility();
    });
  }

  // When selecting a parent, hide the location itself + its descendants.
  updateVisibility() {
    const optionText = this.getOptionText(this.node).toLowerCase();
    const optionValue = this.getOptionValue(this.node);

    const keywordMatches = (
      !this.currentSearch
      || optionText.search(this.currentSearch.toLowerCase()) !== -1
    );
    this.isVisible = keywordMatches || !!this.visibleChildren.size;

    // If we do not want to display archived items, hide archived nodes
    if (this.isArchived() && !this.showArchivedOptions) this.isVisible = false;

    // Check whether this node is a descendant of the currently opened location.
    // If it is, it cannot be selected as a parent, and it will be hidden.
    // By using LTE and GTE, we also hide the node itself as a parent option.
    // This only applies if the current location and the option node belong to the same tree.
    const currentLocation = this.controller.data;
    if (
      currentLocation.tree_id === this.node.tree_id
      && currentLocation.left <= this.node.left
      && this.node.right <= currentLocation.right
    ) {
      this.isVisible = false;
    }

    if (this.parentNode) {
      this.isVisible
        ? this.parentNode.visibleChildren.add(optionValue)
        : this.parentNode.visibleChildren.delete(optionValue);
      this.parentNode.updateVisibility();
    }
  }
}

export class ParentLocationTreeNode extends AutocompleteTreeNode {
  dataClass = ParentLocationTreeNodeData;
}


export const parentLocationTreeSelect = (
) => ({
  [':class']() {
    return {
      'field-autocomplete': true,
      'field-tree-select': true,
      'is-invalid': this.isInvalid(),
      'is-missing': this.isMissing(),
    };
  },
  [':role']() {
    return 'tree';
  },
  ':id': 'fieldName',
  ':name': 'fieldName',
  'x-data': () => ({
    // Reference (x-ref) for the tree node template. This is set here because setting x-ref by binds does not work.
    treeNodeTemplateRef: 'treeNodeTemplate',

    init() {
      // For now, disable keyboard selection with arrow keys and enter.
      this.keyboardSelectionEnabled = false;
    },

    /* Component that evaluates a given expression of nodes to render and, for each, applies a `treeNode` template. */
    treeRenderer: new AutocompleteTreeRenderer().toArrowFunction(),
    treeNode: new ParentLocationTreeNode().toArrowFunction(),

  }),
});