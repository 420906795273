import { isObject } from "lodash-es";

export class ComponentStateError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ComponentStateError';
  }
}


const isolateProxyHandler = {
  has(target, prop) {
    return Reflect.has(target.__data__, prop);
  },
  get(target, prop, receiver) {
    // Do not intercept calls to __data__
    if (prop === '__data__') return Reflect.get(...arguments);
    
    // Prevent Alpine from turning our proxy target into a reactive component
    // We already did that ourselves
    if (prop === '__v_raw') return target.__data__;
    
    // Prevent returning the getOwnPropertyDescriptor of __data__
    if (prop === 'hasOwnProperty') return Reflect.get(...arguments);
    
    // Allows for the creation of private properties on the component
    if (typeof prop !== 'symbol' && prop.startsWith('__')) return Reflect.get(...arguments);
    
    // Get the value from __data__
    let value = Reflect.get(target.__data__, prop, receiver);
    
    // By binding functions to target.__data__ we set their this scope to the __data__ object
    return typeof value === "function" ? value.bind(target.__data__) : value;
  },
  set(target, prop, newVal, receiver) {
    return Reflect.set(target.__data__, prop, newVal);
  },
  deleteProperty(target, prop) {
    return Reflect.deleteProperty(target.__data__, prop);
  },
  defineProperty(target, key, descriptor) {
    return Reflect.defineProperty(target.__data__, key, descriptor);
  },
  ownKeys(target) {
    return Reflect.ownKeys(target.__data__);
  },
  getOwnPropertyDescriptor(target, p) {
    /** We always return a match, so Alpine doesn't jump to next layers in the data stack. */
    // When prop is our own __data__ we should not interfere.
    if (p === '__data__') return Reflect.getOwnPropertyDescriptor(...arguments);
    
    // Special case: Alpine uses `with (scope)` to evaluate string handlers (which is very not recommended)
    // and stores the result on `__self`. If we were to return a match for it, event handlers wouldn't work,
    // since `__self` would be undefined on the proxy.
    if (p === '__self') return undefined;
    
    // Special case where `obj.hasOwnProperty` is called.
    // There, we are not supposed to return a property descriptor.
    if (p === 'hasOwnProperty') return undefined;
    
    if (target.__data__.hasOwnProperty(p)) {
      const descriptor = Reflect.getOwnPropertyDescriptor(target.__data__, p);
      // We are not allowed to return descriptors for __data__ that are not configurable.
      descriptor["configurable"] = true;
      return descriptor;
    }
    return Reflect.getOwnPropertyDescriptor(...arguments);
  }
}

export const isolate = (component, key = undefined) => {
  return (props) => {
    const binds = component(props);
    if (key && !binds['x-key']) binds['x-key'] = key;
    const data = typeof binds['x-data'] === 'function' ? binds['x-data'](props) : binds['x-data'];
    delete binds['x-data'];
    
    // Move init to _init on data
    if (data.hasOwnProperty('init')) {
      Object.defineProperty(
        data,
        '_init',
        Object.getOwnPropertyDescriptor(data, 'init')
      );
    }
    
    // Move destroy to _destroy on data
    if (data.hasOwnProperty('destroy')) {
      Object.defineProperty(
        data,
        '_destroy',
        Object.getOwnPropertyDescriptor(data, 'destroy')
      );
    }
    
    // Assign default state handling
    Object.assign(data, {
      _state: undefined,
      async init() {
        this._state = 'initializing';
        
        if (!this._init) {
          this._state = 'initialized';
          return
        }
        
        const handleException = (error) => {
          if (
            (error instanceof ComponentStateError) ||
            (this._state === 'dying')
          ) {
            // Silently ignore dying errors
            return;
          }
          throw error;
        }
        
        if (this._init.constructor.name === 'AsyncFunction') {
          this._init()
            .then(res => this._state = 'initialized')
            .catch(error => {
              handleException(error);
            });
        } else {
          try {
            this._init();
            this._state = 'initialized';
          } catch (error) {
            handleException(error);
          }
        }
      },
      destroy() {
        this._state = 'dying';
        this._destroy?.();
      }
    });
    
    const wrappedData = {__data__: {}};
    if (isObject(data)) {
      Object.entries(Object.getOwnPropertyDescriptors(data))
        .forEach(([name, descriptor]) => {
          Object.defineProperty(wrappedData.__data__, name, descriptor);
        });
    }
    
    return {
      ...binds,
      'x-data': () => new Proxy(
        // Make the data reactive, so Alpine responds to changes
        Alpine.reactive(wrappedData),
        isolateProxyHandler
      )
    }
  }
}

export default isolate;