import formatters from '/formatters';
import {isNumber, join, replace, split, toNumber, toString} from "lodash-es";

const decimalSliderField = ({
  fieldName,
  decimalPlaces,
  maxDigits,
  initialValue
}) => ({
  'x-data': () => ({
    fieldName,
    decimalPlaces,
    maxDigits,
    initialValue,
    
    get fieldValue() {
      const getValue = this.$context('formField', 'getFieldValue');
      return getValue?.();
    },
    set fieldValue(newValue) {
      const setValue = this.$context('formField', 'setFieldValue');
      setValue?.(newValue);
    },
    
    validate(value) {
      if (!isNumber(value)) return false;
      if (isNaN(value)) return false;
      return value >= 0 && value <= 100;
    },
    
    convertToNumber(numberInput) {
      const strNumber = toString(numberInput);
      
      // First replace all , for .
      let number = replace(strNumber, ',', '.');
      
      // Then split on .
      if (number.includes('.')) {
        // Only convert multiple '.' when we have a decimal separator
        const parts = split(number, '.');
        const decimals = parts.pop();
        number = `${join(parts, '')}.${decimals}`;
      }
      
      return toNumber(number);
    },
    
    binds: {
      inputLeft: () => ({
        '@blur': 'handleBlur',
        '@input': 'handleInput',
        '@keypress': 'validateInput',
        [':class']() {
          const isValid = this.validate(this.value)
          return {
            'is-invalid': !isValid
          }
        },
        [`x-text.decimal:${decimalPlaces}`]: 'value',
        'x-data': () => ({
          get value() {
            return 100 - this.convertToNumber(this.fieldValue);
          },
          handleBlur(e) {
            e.target.textContent = formatters.decimal(this.value, this.decimalPlaces);
          },
          handleInput(e) {
            const newValue = this.convertToNumber(this.$el.textContent);
            if (!isNaN(newValue)) {
              this.fieldValue = this.convertToNumber(
                formatters.decimal(100 - newValue, this.decimalPlaces)
              );
            }
          },
          validateInput(e) {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
            }
          }
        })
      }),
      inputRight: () => ({
        '@blur': 'handleBlur',
        '@input': 'handleInput',
        '@keypress': 'validateInput',
        [':class']() {
          const isValid = this.validate(this.value)
          return {
            'is-invalid': !isValid
          }
        },
        [`x-text.decimal:${decimalPlaces}`]: 'value',
        'x-data': () => ({
          get value() {
            return this.convertToNumber(this.fieldValue);
          },
          handleBlur(e) {
            e.target.textContent = formatters.decimal(this.value, this.decimalPlaces);
          },
          handleInput(e) {
            const newValue = this.convertToNumber(this.$el.textContent);
            if (!isNaN(newValue)) {
              this.fieldValue = this.convertToNumber(
                formatters.decimal(newValue, this.decimalPlaces)
              );
            }
          },
          validateInput(e) {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
            }
          }
        })
      }),
      sliderInput: {
        'x-model': 'data.' + fieldName,
      },
    }
  })
});

export default () => {
  Alpine.bind('decimalSliderField', Alpine.isolate(decimalSliderField, 'decimalSliderField'));
}
