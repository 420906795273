import { getHeaders } from "/libs/ResourceCRUDAdapter";

export const application = (props) => ({
  '@resize.window.debounce': 'handleResize',
  '@license:reload.document': 'getLicense(true)',
  ':class': 'getStyles',
  'x-data': {
    theme: props?.theme,
    _displayMode: undefined,
    _license: undefined,
    
    get displayMode() {
      if (!this._displayMode) {
        this.evaluateDisplayMode();
      }
      return this._displayMode;
    },
    
    init() {
      this.evaluateDisplayMode();
    },
    
    getStyles() {
      return {
        [this.theme]: true,
      }
    },
    
    async getLicense(reload=false) {
      if (!this._license || reload) {
        const response = await fetch(ResourceURLs.licenceUsage, {
          method: 'GET',
          cache: 'no-cache',
          headers: getHeaders(),
        });
        
        if (response.ok) {
          this._license = response.json();
        }
      }
      return this._license;
    },

    evaluateDisplayMode() {
      const oldMode = `${this._displayMode}`;
      
      if (window.innerWidth <= 768) {
        this._displayMode = 'mobile';
      } else if (window.innerWidth <= 992) {
        this._displayMode = 'tablet';
      } else {
        this._displayMode = 'desktop';
      }
      
      if (this._displayMode !== oldMode) {
        this.$dispatch('displayMode:change', {displayMode: this._displayMode});
      }
    },

    // Event handlers
    handleResize(e) {
      this.evaluateDisplayMode();
    }

  }
});

export default () => {
  Alpine.bind('application', Alpine.isolate(application, 'application'));
}