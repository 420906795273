import { startsWith, camelCase, replace } from 'lodash-es';
export const bootstrapData = (data) => ({
  init() {
    this.initBootstrap();
  },
  initBootstrap() {
    this.loadXDAttributes();
  },
  loadXDAttributes() {
    [...this.$el.attributes]
      .filter(attr => startsWith(attr.name, 'x-data-'))
      .forEach(attr => {
        const name = camelCase(replace(attr.name, 'x-data-', ''));
        switch (attr.value) {
          case 'true':
            this[name] = true;
            break;
          case 'false':
            this[name] = false;
            break;
          default:
            this[name] = attr.value;
        }
      }
    )
  },
  print(attribute) {
    return JSON.stringify(attribute, null, 2);
  },
  ...data,
});

export default () => {
  Alpine.data('bootstrap', bootstrapData);
}