export function supplierTemplates(template) {
  // Make sure this is a regular function, not an arrow function to get `this` to point to the instance of choices.
  return {
    choice: ({classNames}, data) => {
      return template(`
        <div class="${ classNames.item } ${ classNames.itemChoice } ${ data.disabled ? classNames.itemDisabled : classNames.itemSelectable }"
            data-select-text="${ this.config.itemSelectText }"
            data-choice ${ data.disabled ? 'data-choice-disabled' : 'data-choice-selectable' }
            data-id="${ data.id }"
            data-value="${ data.value }"
            ${ data.groupId > 0 ? 'role=treeItem' : 'role="option"' }
        >
            <div class="title">${ data.label }</div>
            ${ data.customProperties.country_display
              ? `<span class="text-muted">${ data.customProperties.country_display }</span>`
              : '' }
        </div>
      `);
    }
  }
}