export function limitValuesTemplates(template) {
  // Make sure this is a regular function, not an arrow function to get `this` to point to the instance of choices.
  return {
    item: ({ classNames }, data) => {
      return template(`
        <div class="d-flex flex-column ${classNames.item}
            ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}
            ${data.placeholder ? classNames.placeholder : ''}"
            data-item data-id="${data.id}" data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''}
            ${data.disabled ? 'aria-disabled="true"' : ''}
        >
            ${ data.customProperties.country_display
                ? `<div class="text-muted">${ data.customProperties.country_display }</div>`
                : ''
            }
            <div class="d-flex flex-sm-row flex-column justify-content-between">
            ${ data.placeholder
              ? `<span>${ data.label }</span>`
              : `<span>${ data.customProperties.limit_type_display }</span>
                <span x-text.mgm3="${ data.customProperties.limit_value }"></span>`
            }
            </div>
        </div>
      `);
    },
    choice: ({classNames}, data) => {
      return template(`
        <div class="d-flex flex-column ${ classNames.item } ${ classNames.itemChoice } ${ data.disabled ? classNames.itemDisabled : classNames.itemSelectable }"
            data-select-text="${ this.config.itemSelectText }"
            data-choice ${ data.disabled ? 'data-choice-disabled' : 'data-choice-selectable' }
            data-id="${ data.id }"
            data-value="${ data.value }"
            ${ data.groupId > 0 ? 'role=treeItem' : 'role="option"' }
        >
            ${ data.customProperties.country_display !== undefined
              ? `<div class="text-muted">${ data.customProperties.country_display }</div>`
              : ``
            }
            <div class="d-flex flex-sm-row flex-column justify-content-between">
            ${ data.placeholder
              ? `<span class="text-overflow-ellipsis">${ data.label }</span>`
              : `<span>${ data.customProperties.limit_type_display }</span>
                <span class="text-nowrap" x-text.mgm3="${ data.customProperties.limit_value }"></span>`
            }
            </div>
        </div>
      `);
    }
  }
}