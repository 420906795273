import { isPlainObject } from 'lodash-es';

export const setByReference = (target, source) => {
  // First empty the target object
  Object.keys(target).forEach(k => delete target[k]);
  // Then assign new data
  target = Object.assign(target, source);
};

export const extendComponent = (base, extension) => (props) => {
  const baseBinds = base(props);
  const baseData = typeof baseBinds['x-data'] === 'function' ? baseBinds['x-data'](props) : baseBinds['x-data'];
  delete baseBinds['x-data'];
  
  const extensionBinds = extension(props);
  const extensionData = typeof extensionBinds['x-data'] === 'function' ? extensionBinds['x-data'](props) : extensionBinds['x-data'];
  delete extensionBinds['x-data'];
  
  Object.entries(Object.getOwnPropertyDescriptors(extensionData))
        .forEach(([name, descriptor]) => Object.defineProperty(baseData, name, descriptor));
  return {
    ...baseBinds,
    ...extensionBinds,
    'x-data': () => baseData
  }
};

export const objectValuesAreEmpty = (obj) => {
  if (!isPlainObject(obj)) return;
  const keys = Object.keys(obj);
  return keys.every((key) => ['', null, undefined].includes(obj[key]));
}