import { setByReference } from "/utils/utils.js";
import { isArray } from "lodash-es";

export const remoteDataContext = (props) => ({
  'x-effect': 'load(pks)',
  'x-data': {
    _store: undefined,
    data: {},

    get pks() {
      return props.pks || props.getFieldValue?.();
    },

    get store() {
      const {url} = props;
      if (!this._store)
        this._store = Alpine.getStoreByURL(url);
      return this._store;
    },

    get isMultiple() {
      return isArray(this.pks);
    },

    get list() {
      if (!this.isMultiple) return [];
      return Object.values(this.data);
    },
    
    async init() {
      await this.load(this.pks);
    },
    
    async load(pks) {
      // We reset any data that might have been there; otherwise we'd be adding only, not deleting old PKs.
      this.data = {};

      if (this.isMultiple) {
        (await this.store.getMany(pks)).forEach(item => {
          if (this.data[item.pk] === undefined) this.data[item.pk] = {};
          setByReference(this.data[item.pk], item);
        });
      } else {
        setByReference(this.data, await this.store.getOne(pks));
      }
    }
  }
});

export default () => {
  Alpine.bind('remoteDataContext', Alpine.isolate(remoteDataContext));
}