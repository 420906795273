export const boolicon = (value) => {
  if (value === null || value === '' || value === undefined) return value;
  const html = `<i class="fa-solid ${value ? 'fa-circle-check' : 'fa-circle-xmark'}"></i>`;
  return { mode: 'html', content: html };
}

export const html = (value) => {
  return { mode: 'html', content: value };
}

export const temperature = (value) => {
  if (!value) return '';
  return `${value} °C`;
};

export const percentage = (value) => {
  if (!value) return '';
  return `${value}%`;
}
export const pressure = (value) => {
  if (!value) return '';
  return `${value} Pa`;
};

export const mgm3 = (value) => {
  if (!value) return '';
  return `${value} mg/m³`;
};

export default {
  boolicon,
  html,
  temperature,
  percentage,
  pressure,
  mgm3
};
