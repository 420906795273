class PdfRenderError extends Error {
  constructor(message) {
    super(message);
    this.name = 'PdfRenderError';
  }
}

class PdfRetrieveError extends Error {
  constructor(message) {
    super(message);
    this.name = 'PdfDetailRetrieveError';
  }
}

export const scenarioVersions = (props) => ({
  '@ExposureScenarios:version-created.document': 'handleNewScenarioVersion',
  'x-data': {
    scenarioPk: props.instancePk,
    list: new window.controllers['ExposureScenarioVersions'].list(),
    
    getPDFBackgroundRequest: undefined,
    getPDFGenerateRequested: false,
    
    get store() {
      return this.list.store;
    },
    
    get versions() {
      return this.list.listItems || [];
    },

    async init() {
      await this.fetchVersions();
    },

    async fetchVersions() {
      await this.list.applyFilters({
        scenario: this.scenarioPk,
      });
    },

    async handleNewScenarioVersion() {
      await this.fetchVersions();
    },
    
    async generatePDF(versionId) {
      if (!!this.getPDFBackgroundRequest) return;
      const downloadURL = await this.requestPDF(versionId);
      const authToken = await this.getAuthToken();

      if (!!this.getPDFBackgroundRequest) {
        clearInterval(this.getPDFBackgroundRequest);
      }

      this.getPDFBackgroundRequest = setInterval(async () => {
        let pdfFile;
        try {
          pdfFile = await this.getPDF(downloadURL, authToken);
        }
        catch (error) {
          if (error instanceof PdfRetrieveError) {
            this.$toast('error', Alpine.enums.PdfErrorMessage.PDF_DETAILS_RETRIEVE_FAILED.label);
          } else if (error instanceof PdfRenderError) {
            this.$toast('error', Alpine.enums.PdfErrorMessage.PDF_RENDER_ERROR.label);
          } else {
            // Capture unhandled errors and log to sentry
            Sentry.captureMessage(error, 'error');
            this.$toast('error', Alpine.enums.ActionMessage.GENERIC_ERROR.label);
          }
          
          clearInterval(this.getPDFBackgroundRequest);
          this.getPDFBackgroundRequest = null;
          return;
        }
        finally {
          if (pdfFile.status === 'available') {
            clearInterval(this.getPDFBackgroundRequest);
            this.getPDFBackgroundRequest = null;
            await this.downloadPDFContents(pdfFile.content);
          }
        }
      }, 2000);
    },
    
    async getAuthToken() {
      const response = await this.store.api.formatResponse(await fetch(
        ApplicationURLs.refreshToken,
        {
          method: 'POST'
        }
      ));
      return response.body?.access;
    },
    
    async requestPDF(pk) {
      const response = await this.store.api.call({
        method: 'POST',
        action: `pdf`,
        pk: pk,
      });
      
      if (!response.ok || !response.body?.download_url) {
        throw new PdfRetrieveError('Could not retrieve details for generated PDF file.');
      }
      return response.body?.download_url;
    },
    
    async getPDF(url, token) {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      switch (response.status) {
        case 200:
          return {
            status: 'available',
            content: await response.blob()
          };
        case 202:  // Pending
          return await response.json();
        case 401:
          window.location.href = ApplicationURLs.loginPage;
          break;
        case 404:  // File not found
          throw new PdfRetrieveError('Could not retrieve details for generated PDF file.');
        case 410:  // Expired
          throw new PdfRetrieveError('Expired');
      }
    },

    async downloadPDFContents(fileBlob) {
      const pdfURL = URL.createObjectURL(fileBlob);
      window.open(pdfURL, '_blank');
    },

    binds: {
      pdfButton: (versionId) => ({
        ['@click.stop']() {
          this.generatePDF(versionId)
        },
        ':disabled': '!!getPDFBackgroundRequest',
      }),
      pdfIcon: () => ({
        [':class']() {
          const loading = !!this.getPDFBackgroundRequest;
          return {
            'loading-indicator': loading,
            'fa-solid fa-download': !loading,
          }
        }
      }),
    }
  }
});

export default () => {
  Alpine.bind('scenarioVersions', Alpine.isolate(scenarioVersions, 'scenarioVersions'));
};
