import { get } from 'lodash-es';

const physicalStateIconClasses = (state) => {
    let stateIcon;
    switch (state) {
        case Alpine.enums.PhysicalState.SOLID.value:
            stateIcon = 'state-solid';
            break;
        case Alpine.enums.PhysicalState.LIQUID.value:
            stateIcon = 'state-liquid';
            break;
        case Alpine.enums.PhysicalState.GAS.value:
            stateIcon = 'state-gas';
            break;
        case Alpine.enums.SubstanceType.WOOD.value:
            stateIcon = 'state-wood';
            break;
        case Alpine.enums.SubstanceType.STONE.value:
            stateIcon = 'state-stone';
            break;
        case Alpine.enums.SubstanceType.METAL.value:
            stateIcon = 'state-metal';
            break;
        default:
            stateIcon = 'state-unknown';
            break;
    }

    return {
      'icon': true,
      [stateIcon]: true
    };
}

export const PhysicalStateIcon = ({ state }) => ({
    [':class']() {
        return physicalStateIconClasses(state);
    }
});

export const DatagridPhysicalStateIcon = ({ fieldName }) => ({
    [':class']() {
        // We tried to define a `get physicalState()` here, but since this is not an isolated component,
        // the getter returns a Proxy(PhysicalState), which impairs the enum calculation.
        const state = get(this.controller.data, fieldName);
        return {'datagrid-icon': true, ...physicalStateIconClasses(state)};
    },
})

export default () => {
    Alpine.bind('physicalStateIcon', PhysicalStateIcon);
    Alpine.bind('datagridPhysicalStateIcon', DatagridPhysicalStateIcon);
}
