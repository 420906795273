import { form } from "/components/forms/base/form";
import { extendComponent } from "/utils/utils";


export const Stm8InhalationForm = extendComponent(form, (props={}) => ({
  '@click.outside': '$context("modal", "closeModal")?.()',
  'x-data': (props) => ({
    pk: props.pk,
    isEditing: true,
    _scenario: undefined,
    _substance: undefined,
    
    async init() {
      await this.initForm();
    },
    
    get scenario() {
      if (this._scenario === undefined) {
        this._scenario = {};
        this.fetchScenario();
      }
      return this._scenario;
    },
    
    get substance() {
      if (this._substance === undefined) {
        this._substance = {};
        this.fetchSubstance();
      }
      
      return this._substance;
    },
    
    async fetchScenario() {
      // Wait for form data to load
      await this.getFormController();
      const scenarioPK = this.getValue('scenario');
      if (!scenarioPK) return;
      const scenario = await Alpine.store('ExposureScenarios').getOne(scenarioPK);
      if (scenario) {
        this._scenario = scenario;
      }
    },
    async fetchSubstance() {
      await this.fetchScenario();
      
      let substance;
      switch (this.scenario.scenario_type) {
        case 'product':
          substance = await Alpine.store('Products').getOne(this.scenario.product.product);
          break;
        case 'pgc':
          substance = await Alpine.store('ProcessGeneratedContaminants').getOne(this.scenario.pgc);
          break;
      }
      if (substance) {
        this._substance = substance;
      }
    },
    
    async doSave() {
      const response = await this.controller.save(this.data);
      
      if (!response.ok) {
        if (response.body?.non_field_errors) {
          response.body.non_field_errors.forEach(error => this.$toast('error', error))
        } else {
          this.$toast('error', Alpine.enums.ActionMessage.NO_SAVE.label);
        }
        this.setValidationErrors(response.body);
        return
      }
      
      // We must have had a successful save
      this.$dispatch('configuration:saved', {pk: this.pk});
      const closeModal = this.$context('modal', 'closeModal');
      closeModal();
    }
  })
}));

export default () => {
  Alpine.bind('Stm8InhalationForm', Alpine.isolate(Stm8InhalationForm, 'form'));
}