import getCookie from '../getCookie.js';
import { forIn, isArray, isObject } from 'lodash-es';

export const getHeaders = (headers={}) => ({
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'X-CSRFToken': getCookie('csrftoken'),
  ...headers,
})

export const ResourceCRUDAdapter = (baseURL) => ({
  /**
   * CRUD adapter takes a single endpoint and exposes Create, List, Update and Delete actions.
   */
  // Base resource URL to craft the various crud calls on
  // ie. /inventory/components/
  baseURL: baseURL,

  getUrl(props={}) {
    const { pk, params, action } = props;
    let url = this.baseURL;

    if (pk) {
      url = `${url}${pk}/`;
    }
    if (!!action) {
      url = `${url}${action}/`;
    }

    // Appends params to url
    if (params) {
      const searchParams = new URLSearchParams(params);
      url = `${url}?${searchParams.toString()}`;
    }

    return url;
  },

  async formatResponse(response) {
    //  Redirect to login page on status 401
    if (response.status === 401) {
      window.location.href = ApplicationURLs.loginPage;
    }
    
    let formattedBody;
    if (response.headers.get('content-type') === 'application/json') {
      formattedBody = await response.json();
    } else {
      formattedBody = await response.text();
    }
    return {
      statusCode: response.status,
      ok: response.ok,
      headers: response.headers,
      body: formattedBody,
    };
  },

  async getOptions(pk) {
    const response = await fetch(this.getUrl({pk}), {
      method: 'OPTIONS',
      cache: 'no-cache',
      headers: getHeaders(),
    });
    return await this.formatResponse(response);
  },

  async getList({pagination, filters, sorting=null}, output='default') {
    const params = new URLSearchParams({
      offset: pagination?.offset || 0,
      limit: pagination?.limit || 100,
      output
    });

    if (sorting !== null) {
      params.set('ordering', sorting);
    }

    forIn(filters, (value, key) => {
      // When value is an Array, lets append each value separately to get
      // a querystring like: key1=v1&key1=v2&...
      if (isArray(value)) {
        value.forEach(v => params.append(key, v));
      } else {
        params.set(key, value);
      }
    });

    const response = await fetch(this.getUrl({ params }), {
      headers: getHeaders(),
    });
    return await this.formatResponse(response);
  },

  async getMany(pks, output='default') {
    // construct list url
    // call list api endpoint
    const requestParams = new URLSearchParams({output});
    if (!isArray(pks)) {
      pks = [pks];
    }
    // Add each pk separate to get ?pk=...&pk=...
    pks.forEach(pk => requestParams.append('pk', pk));
    const response = await fetch(this.getUrl({params: requestParams}), {
      headers: getHeaders(),
    });
    return await this.formatResponse(response);
  },

  async getDetail(pk) {
    // construct resource detail url
    // call detail api endpoint
    // return response
    const response = await fetch(this.getUrl({pk}), {
      method: 'GET',
      cache: 'no-cache',
      headers: getHeaders()
    });
    return await this.formatResponse(response);
  },

  async getHtmlFragment(urlData, format='panel') {
    const url = isObject(urlData) ? this.getUrl(urlData) : urlData;
    try {
      const response = await fetch(url, {
        headers: {
          Accept: 'text/html',
          'hx-request': true,
        },
      });
      return await this.formatResponse(response);
    } catch (error) {
      console.error(error);
    }
  },

  async call({ method, action=null, pk=null, data={}} ) {
    const fetchConfig = {
      method,
      headers: getHeaders(),
    };
    // Prevents `fetch` from crashing when using call() with HEAD/GET: such requests cannot have `body`
    if (!['HEAD', 'GET'].includes(method.toUpperCase())) {
      fetchConfig.body = JSON.stringify(data);
    }
    const response = await fetch(this.getUrl({ pk, action }), fetchConfig);
    return await this.formatResponse(response);
  },

  async create(options) {
    return await this.call({ method: 'POST', action: 'create', ...options });
  },

  async update(pk, options) {
    return await this.call({ method: 'PUT', pk, ...options });
  },

  async patch(pk, options) {
    return await this.call({ method: 'PATCH', pk, ...options });
  },

  async delete(pk) {
    return await this.call({ method: 'DELETE', pk })
  },
});

export default ResourceCRUDAdapter;
