import { componentsTemplates } from "./components";
import { hazardClassificationTemplates } from "./hazard_classification";
import { limitValuesTemplates } from "./limit_values";
import { supplierTemplates } from './supplier';
import { includingDescriptionTemplates } from "./including_description.js";
export const renderTemplates = (name='default') => {
  const templates = {
    default: (template) => ({}),
    including_description: includingDescriptionTemplates,
    components: componentsTemplates,
    hazard_classification: hazardClassificationTemplates,
    limit_values: limitValuesTemplates,
    supplier: supplierTemplates,
  }
  return templates[name]
}