import * as _ from 'lodash-es';
export const formFieldPictogram = (fieldName) => ({
  'x-data': () => ({
    fieldName,

    init() {
      if (this.pictogramItems === undefined) {
        _.set(this.data, this.fieldName, []);
      }
    },

    // Methods
    get pictogramItems() {
      return _.get(this.data, this.fieldName);
    },

    toggleCheckbox(key) {
      if (!this.pictogramItems) _.set(this.data, this.fieldName, []);
      const i = this.pictogramItems.indexOf(key);
      if (i > -1) {
        this.pictogramItems.splice(i, 1);
      } else {
        this.pictogramItems.push(key);
      }
    },

    isSelected(key) {
      return this.pictogramItems && this.pictogramItems.indexOf(key) > -1;
    },

    // Components
    pictogramCard: (key) => ({
      'x-data': () => ({
        key,
      }),
      [':class']() {
        return !!this.isSelected(this.key) ? 'checked' : '';
      },
      ['@click']() {
        this.toggleCheckbox(this.key);
      },
    }),

    pictogramCardCheckbox: {
      'x-model': 'data.' + fieldName,
    },

    pictogramCardDisplay: (key) => ({
      ['x-show']() {
        return !!this.isSelected(key);
      }
    })
  }),
});

export default formFieldPictogram;