import {
  TreeNode,
  TreeNodeData,
} from './treeHelpers.js';

class LocationsDatagridNodeData extends TreeNodeData {

  getData(...args) {
    return {
      ...super.getData(...args),
      ...{
        controller: null,
        getController: this.getController,
      },
    };
  }

  init() {
    super.init();
    this.getController();
  }

  getController() {
    this.controller = new window.controllers[this.instanceType].instance();
    this.controller.load(this.getTreeNodeValue());
  }

  getTreeNodeText() {
    return this.controller.data.name;
  }

  handleItemClick() {
    this.$dispatch('show-panel', {
      contentType: 'detail',
      instanceType: this.instanceType,
      pk: this.getTreeNodeValue(),
    });
  }

  isArchived() {
    return !!this.controller?.data?.date_archived;
  }
}

export class LocationsDatagridNode extends TreeNode {
  dataClass = LocationsDatagridNodeData;
}

export default {
  LocationsDatagridNode,
};