export const tabsData = () => ({
  tabs: [],
  activeTab: null,
  tabsElement: null,
  
  init() {
    this.initTabs();
  },
  initTabs() {
    this.loadTabs();
    if (!this.activeTab) {
      this.activeTab = this.tabs[0].id;
    }
  },
  loadTabs() {
    // TODO: This should be refactored to use our current implementation of passing Django variables to use as reactive
    // data for alpine.js using the x-bind directive.
    this.tabsElement = this.$el;
    const tabs = this.tabsElement.querySelectorAll('.tab-pane');
    if (tabs.length === 0) {
      this.tabs.push({id: 'main', label: 'main', el: undefined});
    } else {
      [...tabs].forEach((tab) => {
          this.tabs.push({
              id: tab.id,
              label: tab.dataset.title,
              el: tab
          });
          if (tab.classList.contains('active')) {
              this.activeTab = tab.id;
          }
      });
    }
  },
  
  activateTab(e) {
    // We use `currentTarget` instead of `target` because the former is where the event listener is located.
    // Now, a tab has two children (span + icon), but we are interested in the tab element itself.
    this.activeTab = e.currentTarget.getAttribute('data-target');
    this.$dispatch('tab-changed', {activeTab: this.activeTab});
  },
  hideTab({detail}) {
    this.tabs = this.tabs.filter(tab => tab.id !== detail?.tabId);
  },
  
  isActiveTab(id) {
    return this.activeTab === id;
  },
  reactivateTab() {
    // Ugly hack to force tab activation after htmx settled new content
    const active = this.activeTab;
    this.activeTab = null;
    this.activeTab = active;
  },
  tabHasErrors(id) {
    if (!this.tabsElement) return false;
    const tabElement = this.tabsElement.querySelector(`#${id}`);
    if (!tabElement) return false;
    return !!tabElement.querySelectorAll('.is-invalid').length;
  },
});

export default () => {
  Alpine.data('tabs', tabsData);
}