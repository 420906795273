import { choiceField } from "./base";
import { extendComponent } from "/utils/utils";
import isolate from "/plugins/isolate";

export const inhalationAssessmentRoomVentilation = extendComponent(choiceField,(props) => {
  return {
    '@form:loaded.document': 'handleFormLoad',
    'x-data': {
      async init() {
        const form = this.$context('form');
        await this.initChoices();
        form.registerEventListener('form:change', this.handleFormChange.bind(this));
      },
      
      async handleFormLoad() {
        const getValue = this.$context('form', 'getValue');
        if (!getValue) return;
        this.fieldValue = await getValue(this.fieldName);
        
        this.setChoicesByRoomVolume(getValue('configuration.workplace.room_volume'));
      },
      
      async handleFormChange({ detail }) {
        const { fieldName, value } = detail;
        
        switch(fieldName) {
          case 'configuration.workplace.room_volume':
            this.setChoicesByRoomVolume(value);
            break;
        }
      },
      
      setChoicesByRoomVolume(roomVolume) {
        const { onChange } = props;
        const RoomVolumeType = Alpine.enums.RoomVolume.get(roomVolume);
        if (RoomVolumeType === Alpine.enums.RoomVolume.OUTDOORS) {
          this.choices.disable();
          const hardValue = Alpine.enums.RoomVentilation.NONE.value;
          onChange(hardValue);
          this.fieldValue = hardValue;
          return;
        }
        this.choices.enable();
      }
    }
  }
});

export default () => {
  Alpine.bind('inhalationAssessmentRoomVentilation', isolate(inhalationAssessmentRoomVentilation, 'choiceField'))
}
