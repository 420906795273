import application from "./app";
import assessmentCard from "./form/fields/assessments/assessmentCard";
import bootstrap from "./bootstrap";
import collapse from "./collapse";
import comments from "./comments";
import content from "./content";
import datagrid from "./datagrid";
import filterRow from "./filterRow";
import form from "./form";    // Old form implementation
import forms from "./forms";  // New form implementation
import hazardIndications from "./products/hazardIndications";
import license from "./license";
import modal from "./modal";
import navigation from "./navigation";
import panel from "./panel";
import remoteDataContext from "./remoteDataContext";
import tabs from "./tabs";
import toasts from "./toasts";
import todo from "./demo/todo";
import tree from "./tree";
import utils from "./utils";

export const registerPublicComponents = () => {
  application();
  bootstrap();
  toasts();
}

export default () => {
  application();
  assessmentCard();
  bootstrap();
  collapse();
  comments();
  content();
  datagrid();
  filterRow();
  form();
  forms();
  hazardIndications();
  license();
  modal();
  navigation();
  panel();
  remoteDataContext();
  tabs();
  toasts();
  todo();
  tree();
  utils();
}
