export const checkboxField = (props) => ({
  'x-data': () => ({
    multiple: !!props?.multiple,
    fieldName: props?.fieldName,
    
    get fieldData() {
      const formValue = this.$context('form', 'getValue')?.(this.fieldName);
      
      if ([undefined, null].includes(formValue)) {
        return this.multiple ? [] : null
      }
      return formValue;
    },
    set fieldData(value) {
      this.$context('form', 'setValue')?.(this.fieldName, value);
    },
    
    binds: {
      checkboxOption: ({ value=true }) => ({
        'x-data': () => ({
          id: null,
          value: value,
          init() {
            this.id = this.$id(`${this.fieldName}-${value}`);
          },
          binds: {
            input: () => ({
              'type': 'checkbox',
              ':id': 'id',
              ':name': 'fieldName',
              ':value': 'value',
              'x-model': 'fieldData',
            }),
            label: () => ({
              ':for': 'id',
            })
          }
        })
      }),
    }
  }),
});

export default () => {
  Alpine.bind('checkboxField', Alpine.isolate(checkboxField, 'checkboxField'));
}