import { bootstrapData } from "./bootstrap.js";

export default () => {
  Alpine.data('collapse', () => ({
    ...bootstrapData(),
    open: false,
    
    show() {
      this.open = true;
    },
    hide() {
      this.open = false;
    },
    toggle() {
      this.open = !this.open;
    },
    
    binds: {
      collapsetarget: {
        'x-show': 'open',
      },
    },
    
  }));
  
  Alpine.bind('collapse', () => ({
    'x-data': 'collapse',
  }))
}