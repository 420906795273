export const inputField = (props) => ({
  ':id': 'fieldName',
  ':name': 'fieldName',
  ...(!!props?.mask && {'x-mask': props.mask}),
  '@blur': 'handleBlur',
  '@input.stop.prevent': 'handleInput',
  '@keypress': 'validateKeyPress',
  'x-effect': 'handleEffect',
  'x-model': 'data',
  ':class': 'getStyle',
  ':lang': 'navigator.language',
  'x-data': () => ({
    fieldName: props.fieldName,
    getFormValue: undefined,
    setFormValue: undefined,
    instancePk: undefined,
    
    data: '',
    
    init() {
      this.getFormValue = this.$context('form', 'getValue');
      this.setFormValue = this.$context('form', 'setValue');
      this.instancePk = this.$context('form', 'instancePk');
      
      if (props.shouldFocusSelf && !this.instancePk) {
        this.$focus.focus(this.$el);
      }
    },
    
    getStyle() {
      const hasValidationErrors = this.$context('formField', 'hasFieldValidationErrors');
      const isMissingField = this.$context('form', 'isMissingField');
      return {
        'is-invalid': hasValidationErrors?.(),
        'is-missing': isMissingField?.(this.fieldName),
      }
    },
    
    // Event handlers
    validateKeyPress(e) {
      // Enter keys caused click event triggers on Remove buttons for LimitValue rows
      // As far as I'm aware we don't need the enter to register anywhere.
      if (['Enter'].includes(e.key)) {
        e.preventDefault();
      }
    },
    handleInput(e) {
      this.setFormValue(this.fieldName, this.data);
    },
    handleEffect() {
      this.data = this.getFormValue(this.fieldName);
    },
    handleBlur(e) {
      this.$dispatch('fieldUpdated');
    }
  })
});

export default () => {
  Alpine.bind('inputField', Alpine.isolate(inputField, 'inputField'));
}
