
export const todoComponent = (props) => ({
  'id': 'todo',
  'class': 'todoComponent',
  'x-data': () => ({
    items: [],
    init() {
      console.log('Init todoComponent', props);
    },
    newItem(props) {
      return {
        label: undefined,
        checked: false,
        ...props
      }
    },
    getItems() {
      return this.items;
    },
    setItem(label) {
      if (!this.items) this.items = [];
      this.items.push(this.newItem({label}));
    },
    delItem(index) {
      console.log('Attempt to delete item:', index);
      this.items = this.items.filter((item, i) => i !== index);
    }
  }),
  
});

export const todoItem = ({index, item}) => ({
  'x-data': {
    index,
    item,
    label() {
      return this.item.label;
    }
  }
});

export const inputComponent = (props) => ({
  placeholder: 'I need to do...',
  'x-model': 'value',
  '@keydown.enter': 'handleSubmit',
  'x-data': (props) => ({
    value: null,
    init() {
      console.log('Init inputComponent');
    },
    handleSubmit() {
      const {submitCallback} = props;
      submitCallback(this.value);
      this.value = '';
    }
  })
});

const buttonComponent = (props) => ({
  class: 'btn',
  ['x-text']() {return props.label},
  ['@click.stop']() {
    const {callback} = props;
    callback();
  }
});

export default () => {
  Alpine.bind('todoComponent', Alpine.isolate(todoComponent, 'todoComponent'));
  Alpine.bind('todoItem', Alpine.isolate(todoItem, 'todoItem'));
  Alpine.bind('inputComponent', Alpine.isolate(inputComponent, 'inputComponent'));
  Alpine.bind('buttonComponent', Alpine.isolate(buttonComponent, 'buttonComponent'));
}
