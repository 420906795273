import { form } from "/components/forms/base/form";
import { manyForm } from "/components/forms/base/manyForm";
import { cloneDeep, get, isArray, merge } from "lodash-es";
import isolate from "/plugins/isolate";
import { extendComponent } from "/utils/utils.js";

export const InhalationAssessmentConfigurationsForm = extendComponent(form, (props={}) => ({
  '@tab-changed.document': 'handleTabChange',
  ':class': 'getStyle',
  'x-data': {
    initialData: {
      product_concentration: 100,
      assessment_workplace: {},
      assessment_process: {},
      composition: [],
      job_titles: [],
      vapour_pressures: {
        product_vp: null,
        component_vps: {},
      },
    },
    
    missingFields: [],
    
    get calculation_results() {
      return get(this.data, 'calculation_results', {});
    },
    
    get isArchived() {
      return !!this.data?.date_archived;
    },
    get isDraft() {
      return !this.isArchived && !!this.data?.is_draft;
    },
    
    getStyle() {
      return {
        'is-draft': this.isDraft,
        'is-archived': this.isArchived
      }
    },

    async onFieldChange(fieldName) {
      switch (fieldName) {
        case 'product':
          await this.onProductChange();
          break;
        case 'location':
          this.setValue('product', null);
          break;
      }
      
      this.resetValidationErrors(fieldName);
      this.$dispatch('form:change', {fieldName, value: this.getValue(fieldName)});
    },
    async onProductChange() {
      const productPk = this.data?.product;
      
      this.setValue('composition', []);
      this.setValue('vapour_pressures', cloneDeep(this.initialData.vapour_pressures));
      
      if ([undefined, null, ''].includes(productPk)) {
        this.setValue('product_physical_state', null);
        this.setValue('product_intrinsic_emission', null);
      } else {
        const product = await Alpine.store('Products').getOne(productPk, 'extended');
        this.setValue('product_physical_state', product?.physical_state);
        this.setValue('product_intrinsic_emission', product?.intrinsic_emission);
        this.setValue('composition', product?.composition);
      }
    },
    
    async evaluateMissingFields() {
      const controller = await this.getFormController();
      this.missingFields = await controller.getMissingFieldsForCalculation(this.data);
    },
    
    getFiltersForField(fieldName, context) {
      if (!context) context = this.data;
      
      switch (fieldName) {
        // The product field is filtered down by the selected location (or any of its descendants).
        case 'product':
          return {
            locations: get(context, 'location'),
            include_family: true,
          };
        // For each limit value selector, the options are filtered down by the component they are related to.
        case 'limit_value_task':
        case 'limit_value_time':
          return { component: get(context, 'component') };
        case 'assessment_process.activity':
        case 'assessment_process.inhalation_protection':
          return { physical_state: get(context, 'product_physical_state', null) };
        default:
          return {};
      }
    },

    canFinalise() {
      if (this.isEditing || this.isArchived || !this.isDraft) return false;
      return this.controller?.canFinalise && !!this.controller.canFinalise();
    },
    async doFinalise(e) {
      if (!this.canFinalise()) return;
      const btn = e.target.closest('button.action-button');
      const confirmText = btn.dataset.confirmtext;

      if (confirmText && confirm(confirmText)) {
        return await this.controller.doFinalise();
      }
    },
  
    async calculate() {
      const controller = await this.getFormController();
      const cleanedData = controller.cleanData(this.data);
      if (await controller.canCalculate(cleanedData)) {
        const calculateResponse = await controller.store.api.call({
          method: 'POST',
          action: 'calculate',
          data: cleanedData
        });
        if (calculateResponse.ok) {
          this.setValue('calculation_results', calculateResponse.body);
        } else {
          this.setValue('calculation_results', null);
          this.setValidationErrors(calculateResponse.body);
          Alpine.dispatch(document, 'trigger-messages', {
            messages: [
              {
                tag: 'error',
                message: Alpine.enums.ActionMessage.NO_CALC.label
              }
            ]
          });
        }
      } else {
        this.setValue('calculation_results', null);
        this.evaluateMissingFields();
      }
    },
    handleTabChange({ detail }) {
      const { activeTab } = detail;
      if (!this.isEditing) return;
      if (['tab-summary', 'tab-results'].includes(activeTab)) {
        this.calculate();
      }
    },
    
    // Results
    get compositionResults() {
      /**
       * Merge composition and calculation results
       */
      if (!isArray(this.data.composition)) return [];
      const compositionResults = get(this.data, 'calculation_results.components', []);
      return this.data.composition.map(item => {
        const componentResults = compositionResults.find(
          c => c.reference_id === item.component
        );
        
        // Use merge to maintain original reference to item
        return merge(item, {results: componentResults?.quantitative?.[0]})
      });
    },
    
    binds: {
      compositionManyForm: extendComponent(manyForm, (props) => ({
        'x-data': () => ({
          get items() {
            return this.injectRowIds(this.compositionResults);
          },
        })
      })),
      missingField: ({ fieldName }) => ({
        ['x-text']() {
          const field = get(this.fields, fieldName);
          return field.fieldShortLabel || field.fieldLabel;
        }
      }),
    }
  }
}));

export default () => {
  Alpine.bind('InhalationAssessmentConfigurationsForm', isolate(InhalationAssessmentConfigurationsForm, 'form'))
}