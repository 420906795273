import formatters from '/formatters';

export default () => {
Alpine.directive('text', (el, { modifiers, value: filter, expression }, { effect, evaluateLater }) => {
  let evaluate = evaluateLater(expression);

  effect(() => {
    
    evaluate(value => {
      if (document.activeElement === el) {
        // Avoid updating the dom if the element has focus. This was added to prevent cursor jumping when x-text is
        // used in combination with @input as a workaround for contenteditable elements.
        // see: https://archive.ph/Zk1Rm
        return;
      }

      if (value === null || value === '' || value === undefined) {
        if (filter !== 'empty') {
          Alpine.mutateDom(() => {
            el.textContent = '-';
          });
          return;
        }
      }
      
      // Chains additional formatters
      modifiers.forEach(modifier => {
        let modifier_filters = [];
        if (modifier.includes(':')) {
          let [m, ...mf] = modifier.split(':');
          modifier = m;
          modifier_filters = mf;
        }
        
        const formatterFunction = formatters[modifier];
        if (!formatterFunction) {
          console.error(`Error in x-text: modifier ${modifier} not found`, el);
          return;
        }
        
        value = formatterFunction(value, modifier_filters);
      });
      
      Alpine.mutateDom(() => {
        switch (value?.mode) {
          case 'html':
            el.innerHTML = value.content;
            el._x_ignoreSelf = true;
            Alpine.initTree(el);
            delete el._x_ignoreSelf;
            break;
          default:
            el.textContent = value;
        }
      });
    });
  });
});
};
