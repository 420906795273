import { bootstrapData } from "./bootstrap.js";
import { get } from "lodash-es";
import contextMagic from "/magics/context";

export const content = () => ({
  ...bootstrapData(),

  // Article
  articleVisible: false,
  articleContent: {},
  articleContentId: null,
  isArticleLoading: false,

  // Panel
  panelVisible: false,
  panelReference: null,
  panelContentId: null,
  // PanelProps will contain panelId: [{obj}]
  // Used to loop over by the panel templates
  panelProps: {},
  isPanelLoading: false,
  
  panelWidth: 750,
  mousePosition: null,
  isResizing: false,
  
  // Modal
  modalProps: {},
  modalReference: null,
  isModalLoading: false,
  
  /*
   * Init
   */
  init() {
    this.initBootstrap();
    this.initContent();
  },
  async initContent() {
    // Init panel state
    // When we receive a pk in the url params, lets open the needed side panel.
    const params = new URLSearchParams(window.location.search);
    if (params.has('pk')) {
      const pk = params.get('pk');
      const store = Alpine.getStoreByURL(window.location.pathname);
      this.showPanel({pk, contentType: 'detail', instanceType: store?.instanceType});
    }
    this.$watch('panelProps', value => this.syncPanelPkToUrl());
  },

  /*
   * Generic Methods
   */
  syncPanelPkToUrl() {
    // Keeps the URL param for PK in sync with the panel props for PK
    const pk = get(this.panelProps, `${this.panelContentId}.0.pk`, false)

    // Current URL search params
    const searchParams = new URLSearchParams(window.location.search);
    !pk ? searchParams.delete('pk') : searchParams.set('pk', pk);

    // Set new URL to history
    const url = new URL(window.location);
    url.search = searchParams.toString();
    history.pushState(null, null, url.toString());
  },

  renderTemplate(contentType, contentId, elementToAppend, responseHTML) {
    const template = document.createElement('template');
    template.setAttribute('id', contentId);
    template.setAttribute('x-for', `props in ${contentType}Props.${contentId}`);
    template.innerHTML = responseHTML;

    const el = document.getElementById(elementToAppend);
    el.appendChild(template);
  },

  /*
   * Modal
   */
  async fetchModal(modalId, props={}) {
    const { pk, contentType, instanceType } = props;

    const urlParams = {pk, params: { contentType, instanceType, template: 'modal' }};
    const response = await Alpine.store(instanceType).api.getHtmlFragment(urlParams);

    if (response.ok) {
      this.renderTemplate('modal', modalId, 'content', response.body);
    }
    return response;
  },
  async showModal(props = {}) {
    const { contentType, instanceType, contextReference= null, templateId = null } = props;
    
    if (this.isModalLoading) return;

    this.isModalLoading = true;
    const modalId = templateId !== null ? templateId : `${instanceType}${contentType}`;

    let response = { ok: true };
    let templateElement = document.querySelector( `template#${modalId}`);
    if (!templateElement) {
      response = await this.fetchModal(modalId, props);
      templateElement = document.querySelector( `template#${modalId}`);
    }
    if (response.ok) {
      this.deregisterModal(modalId);
      
      const contextElement = contextReference || templateElement;
      
      // Await next tick to avoid @click.outside handlers of modals from auto triggering
      await this.$nextTick();
      this.modalProps[modalId] = [{
        context: contextMagic(contextElement, {Alpine}),
        open: true,
        ...props,
      }];
    }
    this.isModalLoading = false;
  },
  hideModal() {
    this.deregisterModal();
  },
  registerModal(modalId, reference) {
    this.modalReference = reference;
  },
  deregisterModal(modalId) {
    this.modalProps = {};
    const modal = document.querySelector('.modal');
    if (modal) modal.remove();
    delete this.modalProps[modalId];
  },

  /*
   * Article
   */
  registerArticle(articleId, reference, makeVisible=false) {
    this.articleContent[articleId] = reference;
    if (makeVisible) {
      this.articleVisible = true;
      this.articleContentId = articleId;
    }
  },
  showArticle(props = {}) {
    const { pk, contentType, instanceType } = props;

    if (!instanceType || this.isArticleLoading) {
      if (this.articleContentId) {
        this.articleVisible = true;
      }
      return;
    }

    this.isArticleLoading = true;
    const articleId = `${instanceType}${contentType}`;
    const article = this.articleContent[articleId];

    this.articleContentId = articleId;
    this.articleVisible = true;
    this.isArticleLoading = false;
  },
  hideArticle() {
    this.articleVisible = false;
  },
  isArticleVisible(articleId) {
    return this.articleVisible
      && this.articleContentId === articleId
      && this.articleContent[articleId] !== undefined;
  },
  isArticleSet() {
    return this.articleVisible && this.articleContentId;
  },

  /*
   * Panel
   */
  registerPanel(panelId, reference) {
    this.panelVisible = true;
    this.panelReference = reference;
  },

  deregisterPanel() {
    this.panelProps = {};
  },

  async fetchPanel(panelId, props={}) {
    const { pk, contentType, instanceType } = props;

    const action = (contentType === 'create') ? contentType : null;
    const urlParams = {pk, params: { contentType, instanceType }, action};
    const response = await Alpine.store(instanceType).api.getHtmlFragment(urlParams);

    if (response.ok) {
      this.renderTemplate('panel', panelId, 'content-side', response.body);
    } else {
      this.$toast('error', 'Could not load panel.');
      this.deregisterPanel();
    }
    return response;
  },

  async setPanelProps(panelId, props) {
    this.deregisterPanel(); // Reset open panel

    // Alpine will ignore a state change when at the end of a tick the state is unchanged.
    // Therefor we need to wait for nextTick before we trigger a new panel.
    await this.$nextTick();
    this.panelProps[panelId] = [props];
  },

  async showPanel(props = {}) {
    const { contentType, instanceType } = props;

    if (this.isPanelLoading) return;

    // Load the panel
    this.isPanelLoading = true;
    const panelId = `${instanceType}${contentType}`;

    let response = { ok: true };  // set response.ok to true if panel template already exists
    if (!document.querySelector(`template#${panelId}`)) {
      response = await this.fetchPanel(panelId, props);
    }

    if (response.ok) {
      this.panelContentId = panelId;
      await this.setPanelProps(panelId, {panelId, ...props });
      this.panelVisible = true;
    }
    this.isPanelLoading = false;
  },

  hidePanel(e) {
    if (!this.isArticleSet()) {
      this.showArticle();
    }
    this.panelVisible = false;
    this.deregisterPanel();
  },

  isPanelVisible() {
    return this.panelVisible && !!this.panelReference;
  },

  // Methods for Resizing Panel
  startResizing(e) {
    this.isResizing = true;
    this.mousePosition = e.x;
  },
  stopResizing(e) {
    this.isResizing = false;
    this.mousePosition = null;

    if (this.panelVisible) {
      const offset = this.$refs.contentside.offsetWidth;

      // If panel dragHandle is outside of bounds, reset to the element's offsetWidth.
      if (this.panelWidth < offset || this.panelWidth > offset) {
        this.panelWidth = offset;
      }
    }
  },
  applyResizing(e) {
    if (!this.isResizing) return;
    const diff = this.mousePosition - e.x;
    this.mousePosition = e.x;
    this.panelWidth = this.panelWidth + diff;
  },

  binds: {
    content: () => ({
      '@show-article.document': 'showArticle($event.detail)',
      '@hide-article.document': 'hideArticle',

      '@show-panel.document.debounce': 'showPanel($event.detail)',
      '@hide-panel.document': 'hidePanel',

      '@show-modal.document': 'showModal($event.detail)',
      '@hide-modal.document': 'hideModal',
    }),
    article: (articleId, makeVisible=true) => ({
      'x-data': () => ({
        ...bootstrapData(),
        articleId: articleId,
        init() {
          this.initBootstrap();
          this.registerArticle(this.articleId, this, makeVisible);
        },
      }),
      ['x-show']() {
        return this.isArticleVisible(this.articleContentId);
      }
    }),
    aside: () => ({
      ['x-show']() {
        return this.isPanelVisible();
      },
      [':style']() {
        return !this.isArticleVisible(this.articleContentId)
          ? `flex-basis: 100%;`
          : `flex-basis: ${this.panelWidth}px;`;
      },
      'x-transition.enter.duration.500ms': null,
      'x-transition.leave.duration.50ms': null,
      'x-ref': 'contentside'
    }),
    dragHandle: () => ({
      '@pointerdown.prevent': 'startResizing',
      '@pointermove.document.prevent': 'applyResizing',
      '@pointerup.document.prevent': 'stopResizing',
      '@pointerleave.document.prevent': 'stopResizing',
      '@pointercancel.document.prevent': 'stopResizing'
    }),
  }
});

export default () => {
  Alpine.data('content', content);
}
