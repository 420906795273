import { choiceField } from "./base";
import { extendComponent } from "/utils/utils";
import isolate from "/plugins/isolate";

export const inhalationAssessmentActivity = extendComponent(choiceField,(props) => {
  return {
    '@form:loaded.document': 'handleFormLoad',
    'x-data': {
      async init() {
        const form = this.$context('form');
        await this.initChoices();
        form.registerEventListener('form:change', this.handleFormChange.bind(this));
      },
      async getPreDefinedChoices() {
        const { getFieldPreDefinedData } = props;
        let preDefinedData = getFieldPreDefinedData && getFieldPreDefinedData(`${this.fieldName}__choices`) || [];
        
        const fetchScenario = this.$context('form', 'fetchScenario');
        await fetchScenario();
        
        const scenario = this.$context('form', 'scenario');
        
        return preDefinedData.filter(c => c.substance_types.includes(scenario.substance_type))
      },
      async handleFormLoad() {
        const getValue = this.$context('form', 'getValue');
        if (!getValue) return;
        this.fieldValue = await getValue(this.fieldName);
        this.setChoices(await this.getPreDefinedChoices(), true);
      },
      async handleFormChange({ detail }) {
        const { fieldName, value } = detail;
        const { onChange } = props;
        switch (fieldName) {
          case 'product.physical_state':
            this.setChoices(await this.getPreDefinedChoices(), true);
            if (!this.hasValue(this.fieldValue)) {
              // Set the choices.js value to empty
              this.fieldValue = null;
              // Make sure the form data also gets reset
              onChange(null);
            }
            break;
        }
      }
    }
  }
});

export default () => {
  Alpine.bind('inhalationAssessmentActivity', isolate(inhalationAssessmentActivity, 'choiceField'))
}