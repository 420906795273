import { get } from "lodash-es";

export default (el, {Alpine}) => {
  return (keyOrElement, contextPath, defaultValue) => {
    // `keyOrElement` can either be a string matching an `x-key` attribute on a component,
    // or can be directly a DOM element ( for instance, calling `$context($refs.myComponent)` )
    let componentNode = typeof keyOrElement === 'string'
      ? el.parentNode?.closest(`[x-key="${keyOrElement}"]`)
      : keyOrElement;
    const contextObj = componentNode ? Alpine.evaluate(componentNode, '$data') : {};
    if (contextPath === undefined) {
      // For backwards compatibility, we still allow to return the entire context
      return contextObj;
    }
    let value = get(contextObj, contextPath, defaultValue);
    return typeof value === 'function' ? value.bind(contextObj) : value;
  };
};