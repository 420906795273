export default {
  naturaldt(value, filters=null) {
    if (!value) return '';
    const filter = filters?.[0];

    const locale = navigator.language;
    let date = new Date(value);
    if (isNaN(date.getUTCMilliseconds())) return value;

    switch (filter) {
      case 'date':
        return date.toLocaleDateString(locale);
      case 'time':
        return date.toLocaleTimeString(locale);
      default:
        return date.toLocaleString(locale);    // toLocalDateString() + toLocalTimeString()
    }
  }
};
