import base from './base';
import inhalationAssessmentActivity from './inhalationAssessmentActivity';
import respiratoryProtectionEquipment from './respiratoryProtectionEquipment';
import inhalationAssessmentEmployeeSeparation from './inhalationAssessmentEmployeeSeparation';
import inhalationAssessmentRoomVentilation from './inhalationAssessmentRoomVentilation';

export default () => {
  base();
  inhalationAssessmentActivity();
  inhalationAssessmentEmployeeSeparation();
  inhalationAssessmentRoomVentilation();
  respiratoryProtectionEquipment();
}
