import { isFunction } from "lodash-es";
import { bootstrapData } from "./bootstrap.js";

const datagrid = ({instanceType, bulkSelection}) => ({
  ...bootstrapData(),

  instanceType,
  listController: null,
  
  gridRef: undefined,
  datagridColumns: {},
  selectedRows: [],
  allowBulkSelection: bulkSelection,
  
  init() {
    this.gridRef = this.$el;
    this.initBootstrap();
    this.getController();
  },
  
  getController() {
    this.listController = new window.controllers[this.instanceType].list();
    this.listController.getNextPage();
  },
  
  // Event Handlers
  handleItemAdded(item) {
    const itemToPin = {
      ...item,
      created: true, // TODO decide what additional information to pass
    };
    this.listController.pinItem(itemToPin);
  },
  async handleItemUpdated(item) {
    // When an item is updated, check if it is present in the current list.
    if (!this.listController.isItemInList(item)) return;

    // If the item doesn't match the currently active filters anymore, remove it from the grid.
    if (!(await this.listController.itemMatchesFilters(item))) {
      this.listController.deleteItem(item.pk);
    }
  },
  handleItemDeleted(item) {
    const {pk} = item;
    this.listController.deleteItem(pk);
  },
  
  // Select ALL Checkbox
  get allValues() {
    const items = this.listController.listItems || [];
    return items.map(i => i.pk);
  },
  get isAllSelected() {
    return (this.selectedRows.length > 0 && this.selectedRows.length === this.allValues.length);
  },
  get isIndeterminate() {
    // Determines if the selectAll checkbox should be in an indeterminate state
    return (this.selectedRows.length > 0 && !this.isAllSelected);
  },
  toggleAll() {
    this.isAllSelected
        ? this.deselectAll()
        : this.selectAll()
  },
  selectAll() {
     this.selectedRows = this.allValues;
  },
  deselectAll() {
     this.selectedRows = [];
  },
  
  // Bindings
  bindDatagrid: () => ({
    [`@${instanceType}-created.document`]: 'handleItemAdded($event.detail)',
    [`@${instanceType}-updated.document`]: 'handleItemUpdated($event.detail)',
    [`@${instanceType}-deleted.document`]: 'handleItemDeleted($event.detail)'
  }),
  bindColumnHeader: (field, sortable=false) => ({
    'x-data': () => ({
      ...bootstrapData(),

      // Column properties
      field,
      sortable,

      init() {
        this.initBootstrap();
        this.registerToDatagrid();
      },

      // Registers itself in the grid as a column
      registerToDatagrid() {
        this.datagridColumns[this.field] = {
          fieldName: this.field,
          sortable: this.sortable,
          // TODO: visible
        };
      },

      async handleSort() {
        if (!this.sortable) return;
        await this.listController.applySorting(this.field);
      },
    }),
    [':class']() {
      const classObject = {
        sorting: this.sortable,
      };
      const { field: currentSortField, reversed } = this.listController.currentSort;
      if (currentSortField === this.field) {
        const sortingClassName = reversed ? 'sorting_desc' : 'sorting_asc';
        classObject[sortingClassName] = true;
      }

      return classObject;
    },
    '@click': 'handleSort',
  }),
  bindCheckAll: () => ({
    'id': 'select-all',
    ':value': 'isAllSelected',
    '@click': 'toggleAll',
    ['x-effect']() {
      this.$el.indeterminate = this.isIndeterminate;
    },
  }),
  bindPaginationRow: () => ({
    'x-data': () => ({
      async paginate() {
        if (this.listController.canPaginate()) {
          await this.listController.getNextPage();
        }
      }
    }),
    'x-intersect': 'paginate',
    '@click': 'paginate',
  }),
  bindListWrapper: () => ({
    '@pointerdown.prevent': 'startDragging',
    '@pointermove.document.prevent': 'applyDragging',
    '@pointerup.document.prevent': 'stopDragging',
    '@pointerleave.document.prevent': 'stopDragging',
    '@pointercancel.document.prevent': 'stopDragging',
    'x-data': () => ({
      mouseStartX: null,
      scrollLeft: null,
      clickRange: 5,  // The max distance the mouse can travel during click
      clickCallback: undefined,
      
      get dragging() {
        return this.scrollLeft !== null;
      },
      
      considerClick({x, y}) {
        const diffX = this.mouseStartX - x;
        if (Math.abs(diffX) < this.clickRange) {
          isFunction(this.clickCallback) && this.clickCallback();
        }
      },
      
      startDragging(e) {
        this.mouseStartX = e.x;
        this.scrollLeft = this.$el.scrollLeft;
      },
      stopDragging(e) {
        if (!this.dragging) return;
        
        this.considerClick(e);
        this.mouseStartX = null;
        this.scrollLeft = null;
        this.clickCallback = undefined;
      },
      applyDragging(e) {
        if (!this.dragging) return;
        
        const diffX = this.mouseStartX - e.x;
        this.$el.scrollLeft = this.scrollLeft + diffX;
      },
      
    })
  }),
});

const datagridRow = (listItem) => ({
  ...bootstrapData(),
  instancePk: listItem.pk,

  controller: null,
  hasFetchErrors: false,
  fetchRetryTimeout: 500,

  init() {
    this.loadXDAttributes();
    this.getController();
  },
  async getController() {
    this.controller = new window.controllers[this.instanceType].instance();
    const data = await this.controller.load(this.instancePk);
    if (data?.pk === undefined) {
      this.hasFetchErrors = true;
      setTimeout(async () => {
        const data = await this.controller.load(this.instancePk);
        this.hasFetchErrors = data?.pk === undefined;
        if (this.hasFetchErrors) {
          this.$toast('error', 'An error happened while loading data.');
        }
      }, this.fetchRetryTimeout);
    }
  },
  get isClickable() {
    return true;
  },
  openDetail() {
    this.$dispatch('show-panel', {
      contentType: 'detail',
      instanceType: this.instanceType,
      pk: this.instancePk
    });
  },
  remove(e) {
    const shouldRemove = e.detail?.version_group_deleted;
    if (shouldRemove) {
      this.$el.parentNode.removeChild(this.$el);
    } else {
      this.refresh();
    }
  },
  
  binds: {
    gridRow: () => ({
      [':class']() {
        return {
          'grid-row-clickable': this.isClickable,
          'archived': !!this.controller?.data?.date_archived,
          'grid-row-newly-created': !!listItem.created,
          'has-errors': this.hasFetchErrors,
        }
      },
      'id': `row-${listItem.pk}`,
      ['@pointerup']() {
        this.clickCallback = this.openDetail.bind(this)
      },
      [`@${listItem.pk}-deleted.document`]: 'remove'
    }),
    gridRowCheckbox: {
      '@pointerdown.stop': undefined,
      '@pointerup.stop': undefined,
      'name': 'selected',
      'x-model': 'selectedRows'
    },
  }
});

export default () => {
  Alpine.data('datagrid', datagrid);
  Alpine.data('datagridRow', datagridRow);
};
