import { isArray, isEmpty, maxBy } from "lodash-es";


export const exposureCategory = (props) => ({
  'x-show': 'models?.length > 0',
  'x-data': {
    category: props?.category,
    getValue: null,
    
    get models() {
      if (!this.getValue) return undefined;
      
      const models = this.$context('form', 'models');
      return models?.filter(m => m.exposure_type === this.category);
    },
    
    async init() {
      this.getValue = await this.$context('form', 'getValue');
    },
    
    openLimitValuesModal() {
      this.$dispatch('show-modal', {
        instanceType: 'ExposureScenarioLimitValues',
        contentType: 'modal',
        scenario: this.getValue('pk'),
        exposureType: this.category,
      });
    },
    
    binds: {
      priorityClassBadge: () => ({
        'x-data': () => ({
          get priorityClass() {
            const qualitativeSelectedModel = this.models?.find(m => m.is_selected && m.qualitative_results);
            if (!qualitativeSelectedModel) return undefined;
            const hazardCategory = (
              [Alpine.enums.ExposureType.INHALABLE, Alpine.enums.ExposureType.RESPIRABLE].includes(
                Alpine.enums.ExposureType.get(this.category)
              )
              ? Alpine.enums.HazardCategory.INHALATION.value
              : null
            );
            if (!hazardCategory) return undefined;
            const qualitativeResults = qualitativeSelectedModel.qualitative_results?.results;
            return (qualitativeResults?.[hazardCategory]?.priority_class);
          },
        }),
        [':class']() {
          if (!this.priorityClass) return '';
          return `badge bg-priority-class-${this.priorityClass}`;
        },
        ['x-text']() {
          return this.priorityClass ? `Risk priority ${this.priorityClass}` : '';
        },
      }),
    },
  }
});

export const exposureModel = (props) => ({
  [':class']() {
    return {
      [this.status]: true,
      'exposure-model': true,
    }
  },
  'x-data': () => ({
    isExpanded: false,
    model: props?.model,
    displayTaskValues: true,
    displayWithRPE: true,
    
    get displayMode() {
      return this.displayTaskValues ? 'task' : 'daily';
    },
    get worstCaseTaskRCR() {
      return maxBy(this.quantitative, i => i.rcr_task_lt_with_ppe)?.rcr_task_lt_with_ppe;
    },
    get worstCaseDailyRCR() {
      return maxBy(this.quantitative, i => i.rcr_daily_lt_with_ppe)?.rcr_daily_lt_with_ppe;
    },
    
    get quantitative() {
      return this.model?.quantitative_results;
    },
    get qualitative() {
      return this.model?.qualitative_results;
    },
    get resultType() {
      if (!isEmpty(this.quantitative)) return 'quantitative';
      if (!isEmpty(this.qualitative)) return 'qualitative';
      return undefined;
    },
    
    get hasResults() {
      return ['quantitative', 'qualitative'].includes(this.resultType);
    },
    get hasError() {
      return (!isEmpty(this.model?.quantitative_errors) || !isEmpty(this.model?.qualitative_errors));
    },
    get hasPPE() {
      const getValue = this.$context('form', 'getValue');
      const rpe = getValue?.('rpe');
      return [undefined, null, Alpine.enums.RespiratoryProtectionEquipment.RPE_NONE.value].includes(rpe) === false;
    },
    
    get status() {
      if (this.hasError || !this.scenario.is_valid) {
        return 'error';
      }
      if (this.hasResults) {
        return this.model?.is_selected ? 'selected' : 'selectable';
      }

      return 'available';
    },
    
    handleClick() {
      if (this.hasResults) {
        this.toggle();
      } else {
        this.openConfigurationModal();
      }
    },
    
    openConfigurationModal() {
      this.$dispatch('show-modal', {
        templateId: `ExposureScenarioConfigurationsmodal_${this.model.model_family}`,
        contentType: 'modal',
        instanceType: 'ExposureScenarioConfigurations',
        pk: this.model.configuration_id,
        contextReference: this.$el
      });
    },
    
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
    toggleDisplayMode() {
      this.displayMode = this.displayMode === 'task' ? 'daily' : 'task';
    },
    
    getStyles() {
      return {
        'active': !!this.model?.is_selected
      }
    },
    
    hasPGCResults() {
      return this.getPGCResults().length > 0;
    },
    hasProductResults() {
      return this.getProductResults().length > 0;
    },
    hasSubstanceResults() {
      return this.getSubstanceResults().length > 0;
    },
    hasComponentResults() {
      return this.getComponentResults().length > 0;
    },
    getProductResults() {
      if (!this.quantitative) return [];
      return this.quantitative.filter(r => ['product'].includes(r.reference_type));
    },
    getPGCResults() {
      if (!this.quantitative) return [];
      return this.quantitative.filter(r => ['pgc'].includes(r.reference_type));
    },
    getSubstanceResults() {
      if (!this.quantitative) return [];
      return this.quantitative.filter(r => ['product', 'pgc'].includes(r.reference_type));
    },
    getComponentResults() {
      if (!this.quantitative) return [];
      return this.quantitative.filter(r => r.reference_type === 'component');
    },
    
    // Utilities
    getConcentration() {
    
    },
    getRCRCategory(RCRvalues) {
      if (!isArray(RCRvalues)) {
        RCRvalues = [RCRvalues]
      }
      
      if (RCRvalues.includes(null) || RCRvalues.includes(undefined)) return 'unknown';
      return RCRvalues.every(rcr => rcr < 1) ? 'low' : 'high';
    },
    getRCRClass(rcr) {
      if (isNaN(rcr)) return;
      return rcr < 1 ? 'rcr-low' : 'rcr-high';
    },
    
    binds: {
      modelSelect: () => ({
        'class': 'exposure-model-select',
        '@click.prevent.stop': 'handleClick',
        'x-data': () => ({
          getStyles() {
            return {
              [this.status]: true,
            }
          },
          handleClick() {
            this.openConfigurationModal();
          },
          binds: {
            icon: () => ({
              'class': 'icon',
            })
          }
        })
      }),
      quantitativeResult: (resultProps) => ({
        'x-data': () => ({
          reference_id: resultProps.reference_id,
          reference_type: resultProps.reference_type,
          
          get result() {
            const { reference_id } = resultProps;
            const quantitativeResults = this.$context('model', 'quantitative');
            return quantitativeResults?.find(r => r.reference_id === reference_id);
          },

          async _getItemDetailsForReferenceType() {
            switch (this.reference_type) {
              case 'component':
                return await Alpine.store('Components').getOne(this.reference_id, 'extended');
              case 'product':
                return await Alpine.store('Products').getOne(this.reference_id, 'extended');
              case 'pgc':
                return await Alpine.store('ProcessGeneratedContaminants').getOne(this.reference_id, 'extended');
            }
          },

          async stateIcon() {
            if (this.reference_type === 'product') {
              return 'inhalable-dust';
            }
            const item = await this._getItemDetailsForReferenceType();
            const physicalState = await Alpine.enums.PhysicalState;
            const itemPhysicalState = await physicalState.get(item?.physical_state);
            switch (itemPhysicalState) {
              case physicalState.SOLID:
                return 'state-solid';
              case physicalState.LIQUID:
                return 'state-liquid';
              case physicalState.GAS:
                return 'state-gas';
              default:
                return 'state-pgc';
            }
          },

          async label() {
            const item = await this._getItemDetailsForReferenceType();
            
            switch (this.reference_type) {
              case 'component':
              case 'product':
                return item?.name;
            }
            
            switch (this.model.exposure_type) {
              case Alpine.enums.ExposureType.INHALABLE.value:
                return Alpine.enums.Translation.INHALABLE_DUST.label;
              case Alpine.enums.ExposureType.RESPIRABLE.value:
                return Alpine.enums.Translation.RESPIRABLE_DUST.label;
            }
          },
          
          async cas_number() {
            const item = await this._getItemDetailsForReferenceType();
            return item?.cas_number;
          },

          get concentration() {
            switch (this.displayMode) {
              case 'task':
                return (
                  this.displayWithRPE
                    ? this.result?.task_concentration_with_ppe
                    : this.result?.task_concentration_no_ppe
                );
              case 'daily':
                return (
                  this.displayWithRPE
                    ? this.result?.daily_concentration_with_ppe
                    : this.result?.daily_concentration_no_ppe
                );
              default:
                return null;
            }
          },
          
          get RCR15m() {
            switch (this.displayMode) {
              case 'task':
                return (
                  this.displayWithRPE
                    ? this.result?.rcr_task_st_with_ppe
                    : this.result?.rcr_task_st_no_ppe
                );
              case 'daily':
                return (
                  this.displayWithRPE
                    ? this.result?.rcr_daily_st_with_ppe
                    : this.result?.rcr_daily_st_no_ppe
                );
              default:
                return null;
            }
          },
          
          get RCR8h() {
            switch (this.displayMode) {
              case 'task':
                return (
                  this.displayWithRPE
                    ? this.result?.rcr_task_lt_with_ppe
                    : this.result?.rcr_task_lt_no_ppe
                );
              case 'daily':
                return (
                  this.displayWithRPE
                    ? this.result?.rcr_daily_lt_with_ppe
                    : this.result?.rcr_daily_lt_no_ppe
                );
              default:
                return null;
            }
          },
          
          RCRColor: (use15m) => ({
            ':class': 'getStyles',
            'x-data': () => ({
              getStyles() {
                const rcr = use15m ? this.RCR15m : this.RCR8h;
                return this.getRCRClass(rcr);
              }
            })
          }),
        }),
      }),
    }
  })
});

export default () => {
  Alpine.bind('exposureCategory', Alpine.isolate(exposureCategory, 'category'));
  Alpine.bind('exposureModel', Alpine.isolate(exposureModel, 'model'));
}
