import { concentrationMixin } from "./concentrationMixin.js";
import * as _ from 'lodash-es';

export const formFieldInhalableDust = (fieldName) => ({
  'x-data': () => ({
    ...concentrationMixin(),
    fieldName,
    getFieldData() {
      return _.get(this.data, this.fieldName);
    },

    getConcentrationData() {
      return this.getFieldData();
    },

    hasValidationErrors(fieldName) {
      return _.has(this.validationErrors, fieldName);
    },
  }),
});

export default formFieldInhalableDust;