import { formFieldTreeSelect } from './fields/autocomplete/tree_select/formFieldTreeSelect.js';
import { formFieldAutocomplete } from './fields/autocomplete/formFieldAutocomplete.js';
import { parentLocationTreeSelect } from './fields/locations/parentLocationTreeSelect.js';
import { formFieldPictogram } from './fields/formFieldPictogram.js';
import { formFieldInhalableDust } from './fields/assessments/formFieldInhalableDust.js';
import { cloneDeep, get, isEmpty, set } from 'lodash-es';

export const formField = (fieldName, fieldLabel, fieldShortLabel, required=false) => ({
  fieldName,
  fieldLabel,
  fieldShortLabel,
  required,
  showLabel: true,
  initialValue: undefined,
  
  init() {
    this.registerField();
  },
  
  registerField(initialValue=null) {
    // Register the field to the form
    this.initialValue = initialValue;
    if (typeof this.fields === 'object') {
      this.fields[this.fieldName] = this;
    }
  },

  // Methods
  isInvalid() {
    return this.hasValidationErrors(this.fieldName);
  },

  isMissing() {
    return this.isMissingField(this.fieldName);
  },

  getFieldValidationErrors() {
    return this.getValidationErrors(this.fieldName);
  },
  
  setFieldValidationErrors(errors) {
    if (!this.validationErrors) return;
    const newValidationErrors = cloneDeep(this.validationErrors);
    set(newValidationErrors, this.fieldName, errors);
    
    return this.setValidationErrors(newValidationErrors);
  },
  
  getFieldData() {
    return get(this.data, this.fieldName);
  },

  setFieldData(value) {
    return set(this.data, this.fieldName, value);
  },
  
  validate() {
    // Raise error for empty fields if field is required
    if (this.required) {
      const fieldValue = this.getFieldData();
      if (typeof fieldValue === 'boolean') return;
      if (["", null, undefined].includes(fieldValue) || isEmpty(fieldValue)) {
        this.setFieldValidationErrors([Alpine.enums.ActionMessage.FIELD_REQUIRED.label]);
        return;
      }
    }
  },
  
  handleChange(e) {
    this.onFieldChange(fieldName);
    this.validate();
  },

  // Components
  formFieldBinding: ({ required }) => ({
    [':class']() {
      return {
        'required': this.isEditing && required
      }
    },
    '@change.stop.debounce': 'handleChange',
  }),

  formFieldLabel: () => ({
    ':for': 'fieldName',
    'x-text': 'fieldLabel',
    ['x-show']() {
      return Alpine.evaluate(this.$el, this.showLabel);
    }
  }),

  formFieldDisplay: (directive='x-text') => {
    const displayExpression = `() => controller.display && controller.display("${fieldName}", data)`;
    return {
      'x-show': '!isEditing',
      [directive]: displayExpression,
    };
  },

  formFieldAutocomplete: formFieldAutocomplete,
  formFieldTreeSelect: formFieldTreeSelect,
  parentLocationTreeSelect: parentLocationTreeSelect,
  formFieldPictogram: formFieldPictogram,
  formFieldInhalableDust: formFieldInhalableDust,
});

export default formField;
