import { choiceField } from "./base";
import { extendComponent } from "/utils/utils";
import isolate from "/plugins/isolate";

export const respiratoryProtectionEquipment = extendComponent(choiceField,(props) => {
  return {
    'x-data': {
      async init() {
        const form = this.$context('form');
        await this.initChoices();
        form.registerEventListener('form:change', this.handleFormChange.bind(this));
      },
      async getPreDefinedChoices() {
        const { getFieldPreDefinedData } = props;
        const formData = this.$context('form').data;
        const scenarioType = Alpine.enums.ScenarioType.get(formData?.scenario_type);
        let preDefinedData = getFieldPreDefinedData && getFieldPreDefinedData(`${this.fieldName}__choices`) || [];
        
        let substanceType;
        switch (scenarioType) {
          case Alpine.enums.ScenarioType.PRODUCT:
            // For product we assume physical state matches value with substanceType
            substanceType = formData?.product?.physical_state;
            return preDefinedData.filter(c => c.substance_types?.includes(substanceType));
          case Alpine.enums.ScenarioType.PGC:
            substanceType = Alpine.enums.SubstanceType.SOLID.value;
            return preDefinedData.filter(c => c.substance_types?.includes(substanceType));
          default:
            return preDefinedData;
        }
      },
      async handleFormChange({ detail }) {
        const { fieldName, value } = detail;
        const { onChange } = props;
        switch (fieldName) {
          case 'scenario_type':
          case 'product.physical_state':
            const predefinedChoices = await this.getPreDefinedChoices();
            this.setChoices(predefinedChoices, true);
            if (!this.hasValue(this.fieldValue)) {
              this.fieldValue = null;
              onChange(null);
            }
            break;
        }
      }
    }
  }
});

export default () => {
  Alpine.bind('respiratoryProtectionEquipment', isolate(respiratoryProtectionEquipment, 'choiceField'))
}