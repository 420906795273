import scenariosForm from './form';
import exposureCategory from "./exposureCategory";
import vapourPressuresForm from './vapourPressuresForm';
import configurations from "./configurations";
import limitValues from "./limitValuesForm";
import versions from "./versions";

export default () => {
  scenariosForm();
  exposureCategory();
  vapourPressuresForm();
  configurations();
  limitValues();
  versions();
}
