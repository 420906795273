import { form } from "/components/forms/base/form";
import { extendComponent } from "/utils/utils.js";
import { get } from "lodash-es";

export const ScenarioForm = extendComponent(form, (props={}) => ({
  '@configuration:saved.document': 'handleConfigurationChange',
  'x-data': {
    _substance: undefined,        // Product or PGC
    _components: undefined,       // Detail instances of composition components
    _storeComponents: Alpine.store('Components'),  // Components store
    _storeProducts: Alpine.store('Products'),    // Products store
    _storePGC: Alpine.store('ProcessGeneratedContaminants'), // PGC store
    
    scenario: undefined,
    
    get substance() {
      // Returns product or pgc object
      switch (this.scenarioType) {
        case 'product':
          const productId = this.getValue('product.product');
          if (!productId) break;
          if (productId !== this._substance?.pk) {
            this._substance = this.fetchProduct(productId);
            this._components = undefined;
          }
          break;
        case 'pgc':
          const pgcId = this.getValue('pgc');
          if (!pgcId) break;
          if (pgcId !== this._substance?.pk) {
            this._substance = this.fetchPGC(pgcId);
            this._components = undefined;
          }
          break;
        default:
          this._substance = undefined;
      }
      
      return this._substance;
    },
    
    get components() {
      if (this._components === undefined) {
        this._components = this.fetchComponents();
      }
      
      return this._components;
    },
    
    get scenarioType() {
      return this.getValue('scenario_type');
    },
    get version() {
      return this.scenario?.version_number;
    },
    
    get configurations() {
      return this.controller.configurations;
    },
    get limitValues() {
      return this.controller.limitValues;
    },
    get models() {
      return this.controller.models;
    },
    get isValid() {
      return get(this.data, 'is_valid', false);
    },
    
    async init() {
      await this.initForm();
      if (this.instancePk) {
        this.scenario = await Alpine.store('ExposureScenarios').getOne(this.instancePk);
      }
      await this.registerEventListener('form:change', this.handleFieldChange.bind(this));
      
      if (!this.instancePk) {
        this.isEditing = true;
        this.canCancel = false;
        this.canArchive = false;
      } else if (this.isValid === false) {
        const controller = await this.initControllerPromise;
        const response = await controller.validate?.();
        if (!response.ok) {
          this.setValidationErrors(response.body);
        }
      }
    },
    
    resetSubstance() {
      this._substance = undefined;
      this._components = undefined;
    },
    
    async handleConfigurationChange({ detail }) {
      const { pk: configuration_id } = detail;
      await this.controller.fetchModels(this.getValue('pk'), configuration_id);

      // Re-fetch the scenario to ensure the RCR and risk priority are updated in the datagrid
      await Alpine.store('ExposureScenarios').fetchOne(this.getValue('pk'), 'extended');
    },
    
    async handleFieldChange({ detail }) {
      const { fieldName } = detail;
      const value = this.getValue(fieldName);
      switch(fieldName) {
        case 'scenario_type':
          this.resetSubstance();
          break;
        case 'product.product':
          this.resetSubstance();
          await this.substance;
          this.setValue('product.physical_state', this.substance?.physical_state);
          this.setValue('product.dustiness', this.substance?.intrinsic_emission);
          break;
        case 'pgc':
          this.resetSubstance();
          break;
        case 'product.physical_state':
          switch(Alpine.enums.PhysicalState.get(value)) {
            case Alpine.enums.PhysicalState.SOLID:
              this.setValue('product.dustiness', this.substance?.intrinsic_emission);
              break;
            case Alpine.enums.PhysicalState.LIQUID:
              this.setValue('product.concentration', 100);
              this.setValue('product.process_temperature', 20);
              break;
          }
      }
    },
    
    async fetchProduct(pk) {
      this._substance = await this._storeProducts.getOne(pk, 'extended');
      return this._substance;
    },
    
    async fetchPGC(pk) {
      this._substance = await this._storePGC.getOne(pk, 'extended');
      return this._substance;
    },
    
    async fetchComponents() {
      /**
       * Fetches extended information for the components of the composition
       */
      await this.substance;
      if (!this.substance?.composition) {
        return;
      }
      const pks = this.substance.composition.map(pc => pc.component);
      this._components = await this._storeComponents.getMany(pks, 'extended');
      return this._components;
    },
    
    fieldVisible(fieldName) {
      const isEditForm = this.instancePk !== undefined;
      switch(fieldName) {
        case 'scenario_type':
          return !isEditForm;
        case 'pgc':
          return this.scenarioType === Alpine.enums.ScenarioType.PGC.value && !isEditForm;
        case 'product.product':
          return this.scenarioType === Alpine.enums.ScenarioType.PRODUCT.value && !isEditForm;
        case 'product.physical_state':
          return (
            this.scenarioType === Alpine.enums.ScenarioType.PRODUCT.value
            && this.substance !== undefined
            && !isEditForm
          );
        case 'product.dustiness':
          return (
            this.scenarioType === Alpine.enums.ScenarioType.PRODUCT.value
            && this.substance !== undefined
            && this.getValue('product.physical_state') === Alpine.enums.PhysicalState.SOLID.value
          )
        case 'product.process_temperature':
        case 'product.concentration':
        case 'product.vapour_pressures':
          return (
            this.scenarioType === Alpine.enums.ScenarioType.PRODUCT.value
            && this.substance !== undefined
            && this.getValue('product.physical_state') === Alpine.enums.PhysicalState.LIQUID.value
          )
        case 'rpe':
          return (
              (this.scenarioType === Alpine.enums.ScenarioType.PRODUCT.value && this.getValue('product.physical_state'))
              ||
              (this.scenarioType === Alpine.enums.ScenarioType.PGC.value  && this.getValue('pgc'))
          )
      }
    },
    
    getFiltersForField(fieldName, context) {
      // Needed for the Autocomplete field of location
      if (!context) context = this.data;
      switch (fieldName) {
        default:
          return {};
      }
    },

    canVersion() {
      return !this.isEditing && !this.isArchived && !this.version && this.isValid;
    },

    async doVersion(e) {
      if (!this.canVersion()) return;

      // TODO: Make this a standard "warning" modal at some point.
      const btn = e.target.closest('button.action-button');
      const confirmText = btn.dataset.confirmtext;

      if (confirmText && confirm(confirmText)) {
        const response = await this.controller.doVersion();
        if (response.ok) {
          this.$dispatch('ExposureScenarios:version-created', { scenario: response.body?.scenario });
          // Re-fetch the scenario to ensure the version number is updated in the datagrid
          await Alpine.store('ExposureScenarios').fetchOne(this.getValue('pk'), 'extended');
          this.$toast('success', Alpine.enums.ActionMessage.SCENARIO_VERSION_SUCCESSFUL.label);
        } else {
          this.$toast('error', Alpine.enums.ActionMessage.SCENARIO_VERSION_FAILED.label);
        }
      }
    },

  }
}));

const ScenarioCreateForm = extendComponent(ScenarioForm, (props={}) => ({
  'x-data': {
    isEditing: true,
    canCancel: false,
    canArchive: false,
    version: props.version
  }
}));

export default () => {
  Alpine.bind('ScenarioForm', Alpine.isolate(ScenarioForm, 'form'));
  Alpine.bind('ScenarioCreateForm', Alpine.isolate(ScenarioCreateForm, 'form'));
}
