import { tabsData } from "/components/tabs";

export const modal = (props) => ({
  'id': props?.modalId,
  [`@open-modal:${props?.modalId}.document`]: 'show',
  [`@hide-modal:${props?.modalId}.document`]: 'hide',
  [':class']() {
    return {
      'open': open,
      'show': open
    }
  },
  'x-show': 'open',
  'role': 'dialog',
  ':aria-hidden': '!open',
  ':aria-modal': 'open',
  'x-data': () => ({
    open: props.open,
    
    ...tabsData(),
    modalId: props.modalId,
    context: props.context,
    
    init() {
      this.initModal();
    },
    initModal() {
      this.context?.('content', 'registerModal')?.(this.panelId, this);
      this.initTabs();
    },
    
    show() {
      this.open = true;
    },
    hide() {
      this.open = false;
    },
    
    async closeModal() {
      this.$dispatch('hide-modal');
    },
    
    canSave() {
      return false;
    }
  }),
});

export default () => {
  Alpine.bind('modal', Alpine.isolate(modal, 'modal'));
}
