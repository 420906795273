import { get } from 'lodash-es'

export const GHSPictogram = ({staticPath, fieldName, index}) => ({
    [':src']() {
      return `${staticPath}${this.ghsCode}.svg`
    },
    [':alt']() {
        return Alpine.enums.GHSPictogram[this.ghsCode].label
    },
    'x-data': () => ({
        fieldName,
        index,

        get ghsCode() {
            return get(this.controller.data, `${this.fieldName}.${this.index}`)
        }
    })
});

export default () => {
    Alpine.bind('GHSPictogram', GHSPictogram);
}
