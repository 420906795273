import { AlpineBaseData, AlpineBaseBind } from '../ABC.js';


export class TreeRendererData extends AlpineBaseData {

  init() {
    this.render();
    this.$watch(this.expression, () => {
      this.render();
    });
  }

  render() {
    // x-refs inside x-if components may become unreliable if the containing node was removed by Alpine
    // before this function is called. Always fall back to document.querySelector() for safety.
    this.templateElement = (
      this.$refs[this.treeNodeTemplateRef]
      || document.querySelector(`template#${this.treeNodeTemplateRef}`)
    );
    if (!this.templateElement) return;

    let contentRoot = this.$el;
    contentRoot.replaceChildren();  // The whole tree is re-rendered when the underlying expression changes

    this.nodesToRender = Alpine.evaluate(this.$el, this.expression);
    this.parentNode = Alpine.evaluate(this.$el, this.parentNodeExpression);
    if (!this.nodesToRender) return;

    this.nodesToRender.forEach(rootNodeID => {
      let rootNode = this[this.treeControllerField].treeItems[rootNodeID];
      this.createNode(rootNode, contentRoot);
    });
  }

  createNode(node, contentRoot) {
    // Copy Alpine's logic for x-if
    // (https://github.com/alpinejs/alpine/blob/main/packages/alpinejs/src/directives/x-if.js)

    // 1. Clone the node from the DOM
    let nodeClone = document.importNode(
      this.templateElement.content,
      true,
    ).firstElementChild;

    // 2. Create a scope (context) to render the node with
    let nodeScope = { node, parentNode: this.parentNode };

    // 3. Add the scope to the node
    Alpine.addScopeToNode(nodeClone, Alpine.reactive(nodeScope), this.templateElement);

    // 4. Perform DOM mutation
    Alpine.mutateDom(() => {
      contentRoot.append(nodeClone);
      Alpine.initTree(nodeClone);
    });

  }

  getData(expression, parentNodeExpression = null) {
    return {
      expression,
      parentNodeExpression,
      nodesToRender: null,
      templateElement: null,
      parentNode: null,  // Keeping a reference to the parent node is useful for efficiently computing visibility/selected states
      treeControllerField: 'treeController',

      init: this.init,
      render: this.render,
      createNode: this.createNode,
    }
  }
}

/* Component that evaluates a given expression of nodes to render and, for each, applies a `treeNode` template.
*  Needs a `treeNodeTemplateRef` attribute set in its context.
*/
export class TreeRenderer extends AlpineBaseBind {
  dataClass = TreeRendererData;
}

export class TreeNodeData extends AlpineBaseData {

  init() {
    this.nodeRef = this;
  }

  getTreeNodeText() {
    return this.node.name;
  }

  getTreeNodeValue() {
    return this.node.pk;
  }

  handleItemClick() {}

  isArchived() {
    return false;
  }

  getData(node, parentNode) {
    return {
      node,
      parentNode,
      isExpanded: node.depth <= 5,
      visibleChildren: new Set(),
      isVisible: true,
      nodeRef: null,

      init: this.init,
      getTreeNodeText: this.getTreeNodeText,
      getTreeNodeValue: this.getTreeNodeValue,
      handleItemClick: this.handleItemClick,
      isArchived: this.isArchived,
      treeNodeIcon: () => ({
        ['@mousedown.stop.prevent']() {
          this.isExpanded = !this.isExpanded;
        },
        [':style']() {
          if (this.multiple) return {};
          return {
            margin: `0 0 0 calc(0.5rem + ${node.depth * 24}px)`,
          };
        },
        [':class']() {
          const classObject = {icon: true, 'x-flex': true};
          let iconClass = 'bi-geo-alt';
          if (!!node.children) {
            iconClass = this.isExpanded ? 'bi-chevron-down' : 'bi-chevron-right';
          }
          classObject[iconClass] = true;
          return classObject;
        },
      }),
      treeNodeItem: () => ({
        [':class']() {
          return {
            'tree-node-item': true,
          };
        },
        ['x-text']() {
          return this.getTreeNodeText();
        },
      }),
    };
  }

}

/* Component that renders a tree node. Needs to be used inside a treeRenderer component's context. */
export class TreeNode extends AlpineBaseBind {
  dataClass = TreeNodeData;

  getClass() {
    return { 'tree-node': true, 'archived': this.isArchived() };
  }

  getBind(node, parentNode) {
    return {
      ...super.getBind(node, parentNode),
      ...{
        ':class': this.getClass,
        [':id']() {
          return `tree-node--${node.name}`;
        },
        ['x-show']() {
          return this.isVisible;
        },
      },
    };
  }
}

export default {
  TreeRenderer,
  TreeRendererData,
  TreeNode,
  TreeNodeData,
};