import { get } from "lodash-es";

export const licenseContext = (props) => ({
  ':role': "isLimited && 'tooltip'",
  'tooltip-position': "left",
  ':tooltip-title': 'limitMsg',
  
  'x-data': {
    data: undefined,
    limitMsg: props?.limitMsg,
    
    get productLimit() {
      return this.data?.limit_products;
    },

    get scenarioLimit() {
      return this.data?.limit_assessments;    // FIXME: STM-2569 - Endpoint should be renamed to `limit_scenarios`
    },
    
    get canAddProduct() {
      return !!this.data?.can_add_product;
    },
    get canAddExposureScenario() {
      return !!this.data?.can_add_exposure_scenario;
    },
    get canAddLocation() {
      return !!this.data?.can_add_location;
    },
    get canAddUser() {
      return !!this.data?.can_add_user;
    },
    
    get canDeleteProducts() {
      return !!this.data?.can_delete_products;
    },
    get canDeleteExposureScenarios() {
      return !!this.data?.can_delete_exposure_scenarios;
    },
    
    get isLimited() {
      return get(this, props?.limitType) !== true;
    },
    
    async init() {
      await this.fetchData();
    },
    
    async fetchData() {
      const getLicense = this.$context('application', 'getLicense');
      this.data = await getLicense();
    },
    
    binds: {
      button: (props={}) => ({
        ':class': 'getStyles',
        [':disabled']() {
          // Default to null to allow other checks to still disable the button
          if (this.isLimited) return true;
        },
        'x-data': () => ({
          getStyles() {
            return {
              'disabled': this.isLimited
            }
          },
        })
      })
    }
  }
});

export default () => {
  Alpine.bind('licenseContext', Alpine.isolate(licenseContext, 'licenseContext'));
}