import {
  defaultsDeep,
  forEach,
  get, has,
  set, unset,
} from "lodash-es";
import { ComponentStateError } from "/plugins/isolate";
import { manyForm } from "./manyForm";


export const form = (props) => ({
  '@form:change': 'handleFormChange',
  ':class': 'getStyle',
  'x-data': () => ({
    instanceType: props?.instanceType,
    instancePk: props?.pk,
    
    controller: {},
    fields: {},
    
    initialData: {},
  
    isEditing: props.isEditing || false,
    validationErrors: {},
  
    /*
     We need to ensure `getFormController` awaits for the controller to be initialized.
     Therefore, `getFormController` needs a reference to the same Promise used in initForm().
  
     The order in which the controller and the fields in the form are initialized is not guaranteed.
     If this was not here, when <someField> would call getFormController(), it would return null,
     which would cause weird behaviour; e.g. fields with dependencies would reset their values after save,
     although no dependency really changed.
     */
    initControllerPromise: null,
    
    /*
      Array of objects, i.e.: {event: 'form:change', callback: someCallback}
     */
    _eventListeners: [],
  
    get data() {
      return defaultsDeep(this.controller?.data, this.initialData);
    },
    
    async init() {
      await this.initForm();
      if (!this.instancePk) {
        this.isEditing = true;
        this.canCancel = false;
        this.canArchive = false;
      }
    },

    async initForm() {
      this.initControllerPromise = this.initController();  // Notice the lack of "await"
      await this.initControllerPromise;
    },

    async initController() {
      const controller = new window.controllers[this.instanceType].detail();
      if (this.instancePk) {
        await controller.load(this.instancePk);
      } else {
        // When cloning an item we start off with initialData as props
        const { initialData=this.initialData } = props;
        await controller.resetData(initialData);
      }
      
      // Prevent setting the controller state to self if we're
      // being cleaned up. This avoids a lot of state reactivity
      // in Alpine on dying components.
      if (this._state === 'dying') {
        return Promise.reject(new ComponentStateError(`${this.constructor.name} is being cleaned up.`));
      }
      
      this.controller = controller;
      this.$dispatch('form:loaded');
      return this.controller;
    },
  
    async getFormController() {
      return this.initControllerPromise;
    },
    
    async registerEventListener(event, callback) {
      this._eventListeners.push({event, callback})
    },

    registerField(fieldName, field) {
      this.fields[fieldName] = field;
      
      /**
       * We have 3 sources of initialValue
       *  - The controller
       *  - The form
       *  - The field
       *
       * We use the controller initial value for anything other than undefined
       * We use the form initial value as a weak assignment only to be overwritten
       * by the fields if it's other than undefined or null.
       */
      switch (field.initialValue) {
        case undefined:
          return;
        case null:
          if (get(this.initialData, fieldName, undefined) === undefined) {
            set(this.initialData, fieldName, field.initialValue);
          }
          return;
        default:
          set(this.initialData, fieldName, field.initialValue);
      }
    },
    
    getStyle() {
      return {
        'is-archived': !!this.data?.date_archived
      }
    },
    
    display(fieldPath) {
      return this.controller.display(fieldPath);
    },
    
    async getDisplay(field) {
      const controller = await this.initControllerPromise;
      return await controller.display(field, this.data);
    },

    getValue(field) {
      return get(this.data, field);
    },

    setValue(field, value) {
      set(this.data, field, value);
      this.onFieldChange(field);
    },

    getField(field) {
      return get(this.fields, field);
    },
    
    getFiltersForField(fieldName, context=null) {
      return {};
    },
    
    setValidationErrors(response) {
      this.validationErrors = response;
      this.$dispatch('form:validationUpdated');
    },

    getValidationErrors(fieldName) {
      return get(this.validationErrors, fieldName);
    },

    hasValidationErrors(fieldName) {
      return has(this.validationErrors, fieldName);
    },

    resetValidationErrors(fieldName) {
      unset(this.validationErrors, fieldName);
      this.$dispatch('form:validationUpdated');
    },
  
    isMissingField(fieldName) {
      // A detail controller may optionally declare that a certain field is missing, i.e. is required but not filled in.
      return this.controller.isMissingField && this.controller.isMissingField(fieldName);
    },
    
    // Actions
    canSave() {
      return this.isEditing;
    },

    confirmSave(e) {
      const btn = e.target.closest('button.action-button');
      const confirmText = btn.dataset.confirmtext;
  
      // The controller does not define `shouldConfirmSave`: assume we always have to ask
      if (!this.controller.shouldConfirmSave) {
        if (confirmText && confirm(confirmText)) this.doSave();
      } else {
        // When `shouldConfirmSave` is defined, only ask if needed
        this.controller.shouldConfirmSave(this.data).then(shouldConfirmSave => {
          if (
            !shouldConfirmSave
            || (confirmText && confirm(confirmText))
          ) {
            this.doSave();
          }
        })
      }
    },
    async doSave() {
      const response = await this.controller.save(this.data, (response) => {
        // This will be called before updating this.data
        // By doing this here we prevent rerenders of formfields before isEditing = false;
        this.isEditing = false;
        this.setValidationErrors({});
      });
      
      if (!response.ok) {
        if (response.body?.non_field_errors) {
          response.body.non_field_errors.forEach(error => this.$toast('error', error))
        } else {
          this.$toast('error', Alpine.enums.ActionMessage.NO_SAVE.label);
        }
        this.setValidationErrors(response.body);
        return
      }
      
      if (response.statusCode === 201) {
        // We just created an object
        this.$dispatch('show-panel', {
          contentType: 'detail',
          instanceType: this.instanceType,
          pk: response.body.pk
        });
      }
    },
  
    onFieldChange(fieldName) {
      this.controller.onFieldChange && this.controller.onFieldChange(fieldName);
      this.resetValidationErrors(fieldName);
      this.$dispatch('form:change', {fieldName, value: this.getValue(fieldName)});
    },
    
    canEdit() {
      return !this.isEditing && !!this.data.date_archived === false;
    },

    doEdit() {
      this.isEditing = true;
      this.$dispatch('form:editing', {isEditing: this.isEditing});
    },

    canCancel() {
      return this.isEditing;
    },

    async doCancel() {
      // With this "transaction block", we prevent Alpine from triggering re-renders of all fields before the correct data is set.
      await Alpine.disableEffectScheduling(async () => {
        this.isEditing = false;
        this.setValidationErrors({});
        // We need to refresh the controller and its data
        await this.initForm();
      });
      this.$dispatch('form:editing', {isEditing: this.isEditing});
    },

    canCopy() {
      if (this.isEditing) return false;
      return this.controller?.canCopy && !!this.controller.canCopy();
    },

    async doCopy() {
      if (!this.canCopy()) return;
      const copyData = await this.controller.makeCopy();
      this.$dispatch('show-panel', {
        contentType: 'create',
        instanceType: this.controller.store.instanceType,
        initialData: { ...copyData },
      });
    },

    canArchive() {
      return !this.isEditing && this.controller?.canArchive && !!this.controller.canArchive();
    },

    async doArchive(e) {
      const btn = e.target.closest('button.action-button');
      const confirmText = btn.dataset.confirmtext;
      
      if (confirmText && confirm(confirmText)) {
        const response = await this.controller.archive();
        return response;
      }
    },

    canRestore() {
      return this.controller?.canRestore && !!this.controller.canRestore();
    },

    async confirmRestore(e) {
      const btn = e.target.closest('button.action-button');
      const confirmText = btn.dataset.confirmtext;
  
      if (confirmText && confirm(confirmText)) {
        await this.doRestore();
      }
    },

    async doRestore() {
      const response = await this.controller.restore();
      return response;
    },

    canDelete() {
      return this.controller?.canDelete && !!this.controller.canDelete();
    },

    isDeleteEnabled() {
      // In some cases, the delete button may be present, but disabled.
      if (!this.canDelete()) return false;

      // If the controller doesn't specify further, we assume the delete button is enabled when `canDelete` is true
      if (!this.controller?.isDeleteEnabled) return true;
      return !!this.controller.isDeleteEnabled();
    },

    actionMessage(action) {
      return this.controller?.actionMessage?.(action) || null;
    },
  
    async doDelete(e) {
      const btn = e.target.closest('button.action-button');
      const confirmText = btn.dataset.confirmtext;
      
      if (confirmText && confirm(confirmText)) {
        const response = await this.controller.delete();
        if (response.ok) {
          this.$dispatch('hide-panel');
        }
      }
    },
    
    handleFormChange(event) {
      const listeners = this._eventListeners.filter(l => l.event === event.type);
      forEach(listeners, (l) => l.callback(event));
    },
    
    binds: {
      manyForm: manyForm
    },
  }),
  
});

export default () => {
  Alpine.bind('form', Alpine.isolate(form, 'form'));
}
