import * as _ from 'lodash-es';
/*
This file contains the formFieldAutocomplete component and its constituent parts.

Structure for autocomplete component:
- formFieldAutocomplete

  (Multiple version)
  - manyForm for the pills
    - many entries of <multipleAutocompletePill />

  (Single version)
  - <singleAutocompletePill />

  - input
  - autocompleteOptions
    - many entries of <autocompleteOption />
 */

export const formFieldAutocomplete = (
  instanceType,
  controllerType,
  optionValueField,
  optionTextField = null,
  optionSelectedField = null,
  showArchivedOptions = false,
  multiple = false,
  extraOptions = null,
) => ({
  [':class']() {
    return {
      // CSS class selector binds for styling autocomplete components based on display/edit/single/multiple flags
      'autocomplete-multiple': !!this.multiple,
      'autocomplete-is-editing': !!this.isEditing,

      'field-autocomplete': true,
      'is-invalid': this.isInvalid(),
      'is-missing': this.isMissing(),
      'pill-selected': this.selectedPill !== null,
    };
  },
  ':id': 'fieldName',
  ':name': 'fieldName',
  'x-data': () => ({
    instanceType,
    controllerType,
    optionValueField,
    multiple,
    // Set whether the archived items should be shown as options
    showArchivedOptions,
    // Enable option selection with arrow keys and Enter
    keyboardSelectionEnabled: true,
    // Disable multiple selection with shift, since the selected options get hidden.
    // I leave it here as a feature flag in case this functionality is wanted back some day.
    shiftKeyEnabled: false,
    // Set whether the selected pills use the full rows or not
    pillsUseFullRows: true,
    autocompleteController: null,
    currentSearch: null,
    optionCursor: 0,
    selectedPill: null,
    optionsDropdownVisible: false,
    lastSelectedIndex: null,
    optionTextField: optionTextField || optionValueField,
    optionSelectedField: optionSelectedField || optionValueField,
    optionArchivedField: 'date_archived',
    
    async init() {
      this.processExtraOptions(extraOptions);
      
      if (this.multiple) {
        this.initialValue = [];
      } else {
        this.initialValue = null;
      }
      
      await this.getController();
      await this.updateCurrentSearch();
      await this.subscribeToFieldDependencies();
    },

    async subscribeToFieldDependencies() {
      const formController = await this.getFormController();
      const fieldDependencies = (
        formController.getFieldDependencies
        && formController.getFieldDependencies(this.fieldName)
      );
      if (!fieldDependencies) return;

      fieldDependencies.forEach(fieldDependency => {
        const fieldDependencyExpression = 'data.' + fieldDependency;
        this.$watch(fieldDependencyExpression, async () => {
          await this.applyDependencyFilters(formController);
        });
      });
    },

    // TODO use it in getController
    async applyDependencyFilters(formController = null) {
      // Ask the form controller for the filters to pre-apply to this particular field, if any
      if (!formController) formController = await this.getFormController();
      // Always access controller attributes safely in case the controller is null at some point
      const filtersToApply = (
        formController.getFiltersForField
        && (await formController.getFiltersForField(this.fieldName, this.data))
      );

      if (filtersToApply && Object.keys(filtersToApply).length) {
        if (this.autocompleteController.filterSelectionWouldChange(filtersToApply)) {
          this.clearAutocomplete();
          await this.autocompleteController.applyFilters(filtersToApply);
        }
      }
    },

    processExtraOptions(options) {
      if (!options) return;
      if (options.pillsUseFullRows !== undefined) {
        this.pillsUseFullRows = options.pillsUseFullRows;
      }
    },

    async getController() {
      this.autocompleteController = new window.controllers[this.instanceType][this.controllerType]();

      // Ask the form controller for the filters to pre-apply to this particular field, if any
      const formController = await this.getFormController();
      // Always access controller attributes safely in case the controller is null at some point
      const filtersToApply = (
        formController.getFiltersForField
        && (await formController.getFiltersForField(this.fieldName, this.data))
      );

      if (filtersToApply && Object.keys(filtersToApply).length) {
        await this.autocompleteController.applyFilters(filtersToApply);
      } else {
        await this.autocompleteController.getNextPage();
      }
    },

    clearAutocomplete() {
      if (!this.multiple) {
        _.set(this.data, this.fieldName, null);
      } else {
        this.items = [];
      }
      this.$dispatch('change');
    },

    /* Methods related to option fetching */

    async fetchFilteredOptions(fieldFilters = {}) {
      const filters = {
        ...fieldFilters,
        keyword: this.currentSearch || null,
      };
      await this.autocompleteController.applyFilters(filters);
    },

    async updateCurrentSearch(fieldFilters = {}) {
      await this.fetchFilteredOptions(fieldFilters);
      this.lastSelectedIndex = null;
      this.resetCursor();
    },

    /* Helper methods for manipulating option objects */

    getOptionValue(option) {
      if (typeof option === 'object') return option[this.optionValueField];
      return option;
    },

    getOptionText(option) {
      if (typeof option === 'object') return option[this.optionTextField];
      return option;
    },

    getOptionSelectedText(option) {
      if (typeof option === 'object') return option[this.optionSelectedField];
      return option;
    },

    isOptionArchived(option) {
      if (typeof option === 'object') return option[this.optionArchivedField];
      return false;
    },

    /* Methods for manipulating the option cursor */

    isCursorOnOption(option) {
      const optionValue = this.getOptionValue(option);
      const visibleOptions = this.filterVisibleOptions(this.autocompleteController.listItems);
      const visibleOptionIndex = visibleOptions.findIndex((item) => {
        return this.getOptionValue(item) === optionValue;
      });
      return visibleOptionIndex === this.optionCursor;
    },

    resetCursor() {
      this.optionCursor = 0;
    },

    /* Methods for manipulating option selection */

    // Returns a Set with the already-selected option values
    getSelectedOptionValues() {
      let selectedOptionValues;
      if (this.multiple) {
        selectedOptionValues = new Set(this.items ? this.items.map(item => this.getOptionValue(item)) : null);
      } else {
        selectedOptionValues = new Set([_.get(this.data, this.fieldName)]);
      }
      return selectedOptionValues;
    },

    filterSelectedOptions(options, callback) {
      const selectedOptionValues = this.getSelectedOptionValues();
      const callbackWithSet = (option) => { return callback(option, selectedOptionValues) };
      return [...options].filter(callbackWithSet);
    },

    filterVisibleOptions(options) {
      return this.filterSelectedOptions(options, (option, optionValueSet) => {
        return !this.isOptionSelected(option, optionValueSet);
      });
    },

    isOptionSelected(option, selectedOptionValues = null) {
      const optionValue = this.getOptionValue(option);
      if (!selectedOptionValues) selectedOptionValues = this.getSelectedOptionValues();
      let isOptionAlreadySelected = selectedOptionValues.has(optionValue);

      // Abide to uniqueness constraints
      if (this.uniqueField && this.uniqueField === this.fieldName) {
        isOptionAlreadySelected = isOptionAlreadySelected || this.uniqueFieldValues.has(optionValue);
      }
      return isOptionAlreadySelected;
    },

    selectOption(option, optionIndex = null, shiftKey = false) {
      // Feature flag enabling/disabling multiple selection with the Shift key
      shiftKey = this.shiftKeyEnabled && shiftKey;

      const optionValue = this.getOptionValue(option);

      // Single selection: either non-multiple mode...
      if (!this.multiple) {
        _.set(this.data, this.fieldName, optionValue);
        this.$dispatch('change');
        return;
      }

      // ... or a (valid) range was not selected
      if (!shiftKey || this.lastSelectedIndex === null) {
        if (!this.isOptionSelected(optionValue)) {
          this.items.push(optionValue);
          this.$dispatch('change');
        }
      } else if (this.lastSelectedIndex === optionIndex) {
        return;
      } else {
        // Range selection
        let start = this.lastSelectedIndex, end = optionIndex;

        // Consider the case of bottom-to-top (backwards) selection
        if (start > end) {
          start = optionIndex;
          end = this.lastSelectedIndex;
        }
        const optionsToSelect = this.filterVisibleOptions([
          ...this.autocompleteController.listItems.slice(start, end + 1)
        ]).map(item => this.getOptionValue(item));
        this.items = [...this.items, ...optionsToSelect];
        this.$dispatch('change');
      }

      // Keep track of the last selected index for allowing range selection
      this.lastSelectedIndex = optionIndex;

      // Reset the deletion safety mechanism just after selecting a new option
      this.selectedPill = null;
    },

    formFieldAutocompleteInput: () => ({
      'x-model': 'currentSearch',
      '@input.debounce.300ms': 'updateCurrentSearch',
      '@keyup.backspace': 'handleDelete',
      '@keyup.enter': 'handleEnter',
      '@keyup.arrow-down': 'handleArrowDown',
      '@keyup.arrow-up': 'handleArrowUp',
      '@focus': 'showOptionsDropdown',
      '@blur': 'hideOptionsDropdown',
      [':class']() {
        return {
          'autocomplete-input': true,
        };
      },
      ['x-show']() {
        return this.multiple || [undefined, null].includes(_.get(this.data, this.fieldName));
      },
      'x-data': () => ({
        /* Focus and blur event handling */

        showOptionsDropdown() {
          this.optionsDropdownVisible = true;
        },
        hideOptionsDropdown() {
          this.optionsDropdownVisible = false;
          this.lastSelectedIndex = null;
        },

        /* Keyboard event handling */

        handleDelete() {
          // If the search input has text, just return; it will be handled normally.
          if (this.currentSearch) return;
          if (!this.multiple) {
            _.set(this.data, this.fieldName, null);
            this.$dispatch('change');
            return;
          }
          // If there's no text, the first backspace selects the last pill, if any
          if (!this.selectedPill && this.items.length > 0) {
            this.selectedPill = this.items[this.items.length - 1];
          } else {
            // The next backspace deletes the previously-selected last pill
            this.items.pop();
            this.$dispatch('change');
            this.selectedPill = null; // Safety mechanism. Gather feedback: is it too cumbersome?
          }
        },

        handleEnter() {
          if (!this.keyboardSelectionEnabled) return;

          // The already-selected options are not shown; filter these out
          const visibleOptions = this.filterVisibleOptions(this.autocompleteController.listItems);

          // Return if there's nothing to select
          if (visibleOptions.length === 0) return;

          // Return if the cursor is out-of-bounds for some reason
          if (this.optionCursor >= visibleOptions.length) return;

          const optionToSelect = visibleOptions[this.optionCursor];

          // Safety check
          if (this.isOptionSelected(optionToSelect)) return;
          this.selectOption(optionToSelect);

          // When selecting the last item on the list, make sure we don't go out of bounds
          if (this.optionCursor === visibleOptions.length - 1) this.optionCursor -= 1;
        },

        handleArrowUp() {
          if (!this.keyboardSelectionEnabled) return;

          // The already-selected options are not shown; filter these out
          const visibleOptions = this.filterVisibleOptions(this.autocompleteController.listItems);

          // Return if there's nothing to select
          if (visibleOptions.length === 0) return;

          // Return if the cursor is out-of-bounds for some reason
          if (this.optionCursor <= 0) return;
          this.optionCursor -= 1;
        },

        handleArrowDown() {
          if (!this.keyboardSelectionEnabled) return;

          // The already-selected options are not shown; filter these out
          const visibleOptions = this.filterVisibleOptions(this.autocompleteController.listItems);

          // Return if there's nothing to select
          if (visibleOptions.length === 0) return;

          // Return if the cursor is out-of-bounds for some reason
          if (this.optionCursor >= visibleOptions.length - 1) return;
          this.optionCursor += 1;
        },

      }),
    }),

    formFieldAutocompleteOptions: () => ({
      'x-show': 'optionsDropdownVisible',
    }),

    autocompleteOption: (optionIndex) => ({
      'x-data': () => ({
        optionIndex,
        option: null,

        init() {
          this.option = this.autocompleteController.listItems[this.optionIndex];
          this.$watch('autocompleteController.listItems', newOptions => {
            if (newOptions[this.optionIndex]) this.option = newOptions[this.optionIndex];
          });
        },
      }),
      ['x-text']() {
        return this.getOptionText(this.option);
      },
      ':key'() {
        return this.getOptionValue(this.option);
      },
      '@mousedown.prevent': 'selectOption(option, optionIndex, $event.shiftKey)',
      [':class']() {
        return {
          'autocomplete-option': true,
          'selected': this.isCursorOnOption(this.option),
        };
      },
      ['x-show']() {
        if (this.isOptionSelected(this.option)) return false;
        // When the option is archived, only show it if `showArchivedOptions` is set
        return (!this.isOptionArchived(this.option) || this.showArchivedOptions);
      },
    }),

    multipleAutocompletePill: () => ({
      'x-data': () => ({
        pillText: '',

        async init() {
          await this.updatePillText();
          this.$watch('data', async () => {
            await this.updatePillText();
          });
        },

        async updatePillText() {
          const optionPk = this.data;
          const optionItem = await this.autocompleteController.store.getOne(optionPk);
          this.pillText = this.getOptionSelectedText(optionItem);
        }
      }),
      ['x-text']() {
        return this.pillText;
      },
    }),

    singleAutocompletePill: () => ({
      ['x-show']() {
        return ![undefined, null].includes(_.get(this.data, this.fieldName));
      },
      'x-data': () => ({
        pillText: '',

        async init() {
          await this.updatePillText();
          const watchExpression = ['data', ...this.fieldName.split('.')].join('?.');
          this.$watch(watchExpression, async () => {
            await this.updatePillText();
          });
        },

        async updatePillText() {
          const optionPk = _.get(this.data, this.fieldName);
          const optionItem = await this.autocompleteController.store.getOne(optionPk);
          this.pillText = this.getOptionSelectedText(optionItem);
        },

        pillTextBinding: () => ({
          ['x-text']() {
            return this.pillText;
          },
        }),
        removePillBinding: () => ({
          '@click.prevent': 'clearAutocomplete',
          'x-show': 'isEditing',
        })
      }),
    }),

  }),
});

export default formFieldAutocomplete;