export function includingDescriptionTemplates(template) {
  // Make sure this is a regular function, not an arrow function to get `this` to point to the instance of choices.
  return {
    item: ({ classNames }, data, removeItemButton) => {
      return template(`
        <div class="d-flex flex-row justify-content-between fill ${classNames.item}
            
            ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}
            ${data.placeholder ? classNames.placeholder : ''}"
            data-item data-id="${data.id}" data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''}
            ${data.disabled ? 'aria-disabled="true"' : ''}
            ${data.active ? 'aria-selected="true"' : '' }
            ${removeItemButton ? 'data-deletable=""' : '' }
        >
            ${ data.customProperties.description !== undefined
              ? `<div class="d-flex flex-column"><span class="label">${ data.label }</span><span class="description">${ data.customProperties.description }</span></div>`
              : `<div>${ data.label }</div>`
            }
            ${ removeItemButton
              ? `<button type="button" class="${ classNames.button }" data-button="">Remove item</button>`
              : '' }
        </div>
      `);
    },
    choice: ({classNames}, data) => {
      return template(`
        <div class="d-flex flex-row ${ classNames.item } ${ classNames.itemChoice } ${ data.disabled ? classNames.itemDisabled : classNames.itemSelectable }"
            data-select-text="${ this.config.itemSelectText }"
            data-choice ${ data.disabled ? 'data-choice-disabled' : 'data-choice-selectable' }
            data-id="${ data.id }"
            data-value="${ data.value }"
            ${ data.groupId > 0 ? 'role=treeItem' : 'role="option"' }
        >
            ${ data.placeholder
              ? `<span>${ data.label }</span>`
              : `<div class="d-flex flex-column"><span class="label">${ data.label }</span><span class="description">${ data.customProperties.description }</span></div>`
            }
            </div>
        </div>
      `);
    }
  }
}