import { get, isArray, isFinite, toNumber } from "lodash-es";
import { extendComponent } from "/utils/utils";

export const resultCard = (props) => ({
  'x-data': {
    isExpanded: false,
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
    
    get data() {
      const { getValue, fieldName } = props;
      return getValue(fieldName);
    },
  }
});

export const qualitativeResultCard = extendComponent(resultCard, (props) => ({
  'x-data': {
    binds: {
      // Qualitative
      RiskPriorityIcon: () => ({
        [':class']() {
          if (![1, 2, 3].includes(this.data.priority_class)) return '';
          return `fa-${this.data.priority_class}`;
        },
      }),
      RiskPriorityBackground: () => ({
        [':class']() {
          return `bg-priority-class-${this.data.priority_class}`;
        },
      }),
      RiskPriorityColour: () => ({
        [':class']() {
          return `priority-class-${this.data.priority_class}`;
        },
      }),
      HazardIndication: () => ({
        [':class']() {
          return `hazard-indication-${this.data.hazard_indication}`;
        },
      }),
      ExposureBand: () => ({
        'x-text': 'data.exposure_band',
        [':class']() {
          return `exposure-band-${this.data.exposure_band}`;
        },
      }),
    }
  }
}));

export const quantitativeResultCard = extendComponent(resultCard, (props) => ({
  'x-data': {
    async getRCR(rcrType = 'task', withPPE = false) {
      const concentration = this.getConcentration(rcrType, 90, withPPE);
      const limitValue = toNumber((await this.getLimitValue(rcrType))?.limit_value);
      
      try {
        if (!isFinite(concentration) || !isFinite(limitValue))
          return;
        return concentration / limitValue;
      } catch (error) {
        return
      }
    },

    getConcentration(rcrType = 'task', percentile = 90, withPPE = false) {
      if (!this.data.results) {
        return null;
      }

      // TODO: We need the matter state of the component serialized in the composition serializer to ask for the correct
      // model. For now, we assume the first model in component.percentiles
      const percentileResult = this.data.results?.percentiles?.find(p => p.percentile === percentile);
      const concentrationString = `${rcrType}_concentrations`;
      const ppeString = withPPE ? 'with_ppe' : 'base';
      
      return get(percentileResult, `${concentrationString}.${ppeString}`, null);
    },

    async getLimitValue(limitValueType = 'task') {
      if (!['task', 'daily'].includes(limitValueType)) return '';
      const limitValueString = (limitValueType === 'task') ? 'limit_value_task' : 'limit_value_time';
      const pk = get(this.data, limitValueString);
      if (!pk) return;
      return await Alpine.store('LimitValues').getOne(pk);
    },

    getRCRCategory(RCRvalues) {
      if (!isArray(RCRvalues)) {
        RCRvalues = [RCRvalues]
      }
      
      if (RCRvalues.includes(null) || RCRvalues.includes(undefined)) return 'unknown';
      return RCRvalues.every(rcr => rcr < 1) ? 'low' : 'high';
    },
    
    binds: {
      // Component
      RCRBadge: (rcrType, withPPE) => ({
        async [':class']() {
          const rcrCategory = this.getRCRCategory(await this.getRCR(rcrType, withPPE));
          if (rcrCategory === 'unknown')
            return { 'badge': true, 'bg-dark': true };
          
          return {
            'badge': true,
            [ rcrCategory === 'low' ? 'bg-success' : 'bg-danger' ]: true,
          };
        },
        async ['x-text.rcr']() {
          return await this.getRCR(rcrType, withPPE);
        },
      }),
      RCRColor: (rcrType, withPPE) => ({
        async [':class']() {
          const category = this.getRCRCategory(await this.getRCR(rcrType, withPPE));
          return `rcr-${category}`;
        },
      }),
      ComponentExposureBackground: () => ({
        async [':class']() {
          const exposures = [
            await this.getRCR('daily', true),
            await this.getRCR('task', true)
          ];
          return `bg-rcr-${this.getRCRCategory(exposures)}`;
        },
      }),
      TableRCR: (rcrType, withPPE) => ({
        async ['x-effect']() {
          this.rcr = await this.getRCR(rcrType, withPPE);
        },
        [':class']() {
          return `rcr-${this.getRCRCategory(this.rcr)}`;
        },
        'x-data': () => ({
          rcr: null,
        }),
      }),
      TableLimitValues: (limitValueType) => ({
        'x-data': () => ({
          limitValue: {},
        }),
        async ['x-effect']() {
          this.limitValue = await this.getLimitValue(limitValueType);
        },
      }),
      ComponentExposureIcon: () => ({
        async [':class']() {
          const exposures = [
            await this.getRCR('daily', true),
            await this.getRCR('task', true)
          ];
          const category = this.getRCRCategory(exposures);
          return {
            'fa-check': category === 'low',
            'fa-xmark': category === 'high'
          }
        },
      }),
    }
  },
}));

export const componentQuantitativeResultCard = extendComponent(quantitativeResultCard, (props) => ({
  'x-show': 'shouldShow',
  'x-data': {
    get data() {
      const { item } = props;
      return item || {}
    },
    get label() {
      return this.data?.component_display;
    },
    shouldShow() {
      return this.data?.results;
    }
  }
}));

export const inhalableDustQuantitativeResultCard = extendComponent(quantitativeResultCard, (props) => ({
  'x-show': 'shouldShow',
  'x-data': {
    get data() {
      const { getValue, fieldName } = props;
      const percentiles = getValue(fieldName);
      
      return {
        results: {
          percentiles
        },
      };
    },
    get label() {
      const { label } = props;
      return label;
    },
    shouldShow() {
      return !!this.data.results.percentiles?.length > 0;
    },
  }
}));

export default () => {
  Alpine.bind('resultCard', Alpine.isolate(resultCard));
  Alpine.bind('qualitativeResultCard', Alpine.isolate(qualitativeResultCard));
  Alpine.bind('quantitativeResultCard', Alpine.isolate(quantitativeResultCard));
  Alpine.bind('componentQuantitativeResultCard', Alpine.isolate(componentQuantitativeResultCard));
  Alpine.bind('inhalableDustQuantitativeResultCard', Alpine.isolate(inhalableDustQuantitativeResultCard));
};
