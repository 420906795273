import checkboxFields from './checkboxField';
import choiceFields from './choiceField';
import decimalSliderFields from './decimalSliderField';
import inputFields from './inputField';
import radioFields from './radioField';
import rangeFields from './rangeField';

export default () => {
  checkboxFields();
  choiceFields();
  decimalSliderFields();
  inputFields();
  radioFields();
  rangeFields();
}
