/*
This is literally the $dispatch functionality, but available from Alpine.
Its primary purpose is to dispatch events from non-Alpine components.
$dispatch is a mere shorthand for dispatchEvent(CustomEvent(...)),
but we wish to dispatch events exactly how Alpine does it.
 */

function dispatch(el, name, detail = {}) {
    el.dispatchEvent(
        new CustomEvent(name, {
            detail,
            bubbles: true,
            // Allows events to pass the shadow DOM barrier.
            composed: true,
            cancelable: true,
        })
    )
}

export default dispatch;
