export const navigation = (props) => ({
  ':class': 'getStyles',
  '@toggle-sidebar.window': 'toggleOpen',
  '@displayMode:change.document.debounce': 'handleDisplayModeChange',
  'x-data': {
    requested: undefined,
    open: undefined,
    
    init() {
      this.evaluateOpen();
    },
    getStyles() {
      return {
        'open': this.open,
        'closed': this.open === false,
      }
    },
    evaluateOpen() {
      if (this.requested !== undefined) {
        this.open = this.requested;
        return;
      }
      const displayMode = this.$context('application', 'displayMode');
      this.open = displayMode === 'desktop';
    },
    toggleOpen() {
      this.requested = !this.open;
      this.evaluateOpen();
    },
    handleDisplayModeChange(e) {
      // When displayMode changes we want to reset whatever the user requested
      this.requested = undefined;
      this.evaluateOpen();
    },
  }
});

export default () => {
  Alpine.bind('navigation', Alpine.isolate(navigation, 'navigation'));
}