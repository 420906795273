/*
* Alpine Base Classes enable expressing Alpine components as class-based components.
*
* This enables extendability using super() and method overriding.
*/


/*
* Auxiliary class that enables extendability for Alpine x-data objects.
*
* For now, when using a component derived from it, a call to .toArrowFunction() is needed.
*/
export class AlpineBaseData {

  toArrowFunction(...bindArgs) {
    return () => this.getData(...bindArgs);
  }

  /*
  Entrypoint for the component's data. It receives all the arguments passed to `x-bind` as context.
   */
  getData(...bindArgs) {
    return {...bindArgs};
  }
}

/*
* Auxiliary class that enables extendability for Alpine x-bind objects.
*
* For now, when using a component derived from it, a call to .toArrowFunction() is needed.
*/
export class AlpineBaseBind {

  dataClass = AlpineBaseData;

  toArrowFunction() {
    return (...bindArgs) => this.getBind(...bindArgs);
  }

  getDataFunction(...bindArgs) {
    return new this.dataClass().toArrowFunction(...bindArgs);
  }

  /*
  Entrypoint for the component. Defines the bindings for this component, which normally includes `x-data`.
  Classes extending this base can define their `dataClass` to use, which will be instantiated in `x-data`.
   */
  getBind(...bindArgs) {
    return {
      'x-data': this.getDataFunction(...bindArgs),
    };
  }
}

export default {
  AlpineBaseData,
  AlpineBaseBind,
};