import isolate, { ComponentStateError } from '/plugins/isolate';
import { cloneDeep, defaultTo, isArray, isEmpty, isFunction, isObject, set } from 'lodash-es';

export const formField = (props) => ({
  '@fieldUpdated': 'validate',
  '@change.stop.debounce': 'handleChange',
  [':class']() {
    return {
      'is-invalid': this.hasFieldValidationErrors(),
      'required': this.isEditing && props.required
    }
  },
  'x-data': () => ({
    fieldName: props.fieldName,
    fieldLabel: props.fieldLabel,
    fieldShortLabel: props.fieldShortLabel,
    showLabel: defaultTo(props.showLabel, true),
    initialValue: props.initialValue,
    shouldFocusSelf: defaultTo(props.shouldFocusSelf, false),
    
    get isEditing() {
      const isEditing = this.$context('form', 'isEditing');
      return !!isEditing;
    },
    
    get fieldPath() {
      // Get proper fieldPath for fields inside manyForm
      const fieldPath = this.$context('form', 'fieldPath');
      return fieldPath ? `${fieldPath}.${this.fieldName}` : this.fieldName;
    },
    
    init() {
      const registerField = this.$context('form', 'registerField');
      registerField(this.fieldName, this);
    },
    
    getFieldValue() {
      const { getValue } = props;
      return getValue(this.fieldName);
    },
    setFieldValue(value) {
      const { setValue } = props;
      return setValue(this.fieldName, value);
    },
    
    getFieldPreDefinedData(name) {
      const dataElement = this.$el.querySelector(`script[name="${name}"]`);
      if (!dataElement) return;
      return JSON.parse(dataElement.textContent);
    },
    
    getFieldFilters() {
      const { getFilters, fieldName } = props;
      return getFilters(fieldName);
    },
    getFieldValidationErrors() {
      const getValidationErrors = this.$context('form', 'getValidationErrors');
      return getValidationErrors?.(this.fieldName);
    },
    hasFieldValidationErrors() {
      const errors = this.getFieldValidationErrors();
      if (!errors) return false;
      
      if (isArray(errors)) return errors.length > 0;
      if (isObject(errors)) return Object.keys(errors).length > 0;
    },
    setFieldValidationErrors(errors) {
      const validationErrors = this.$context('form', 'validationErrors');
      if (!validationErrors) return;
      
      const newValidationErrors = cloneDeep(validationErrors);
      set(newValidationErrors, this.fieldPath, errors);

      const setValidationErrors = this.$context('form', 'setValidationErrors');
      return setValidationErrors?.(newValidationErrors);
    },
    
    validate() {
      // Raise error for empty fields if field is required
      if (props.required) {
        const fieldValue = this.getFieldValue();
        if (typeof fieldValue === 'boolean') return;
        if (["", null, undefined].includes(fieldValue) || isEmpty(fieldValue)) {
          this.setFieldValidationErrors([Alpine.enums.ActionMessage.FIELD_REQUIRED.label]);
          return;
        }
      }
    },
    
    // Event handlers
    handleChange(e) {
      const onFieldChange = props.onFieldChange || this.$context('form', 'onFieldChange');
      onFieldChange?.(this.fieldPath);
      this.validate();
    },
  
    binds: {
      label: () => ({
        ':for': 'fieldName',
        'x-text': 'fieldLabel',
        'x-show': 'showLabel'
      }),
      display: (directive='x-text') => {
        return {
          'x-show': '!isEditing',
          [directive]: 'displayValue',
          'x-effect': 'setDisplayValue',
          'x-data': () => ({
            displayValue: null,
            async init() {
              await this.setDisplayValue();
            },
            async setDisplayValue() {
              try {
                const getDisplay = this.$context('form', 'getDisplay');
                if (!isFunction(getDisplay)) {
                  return;
                }
                this.displayValue = await getDisplay(this.fieldName);
              } catch (error) {
                if (!(error instanceof ComponentStateError)) {
                  throw error;
                }
              }
            }
          })
        }
      },
      displayRaw: (props) => ({
        'x-show': '!isEditing',
        'x-data': () => ({
          get data() {
            const { fieldData } = props;
            const fieldValue = this.getFieldValue();
            
            if (!fieldData) return fieldValue;
            
            // If fieldData given, enrich the fieldValue
            const preDefinedData = this.getFieldPreDefinedData(fieldData);
            if (!preDefinedData || isEmpty(preDefinedData)) return fieldValue;
            
            if (isArray(fieldValue)) {
              return fieldValue.map(value => preDefinedData.find(item => item.value === value));
            }
            return preDefinedData.find(item => item.value === fieldValue);
          },
        })
      }),
    }
  })
});

export default () => {
  Alpine.bind('formField', isolate(formField, 'formField'));
}