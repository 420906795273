
export const rangeField = (props) => ({
  ':id': 'fieldName',
  ':name': 'fieldName',
  'x-data': {
    fieldName: props?.fieldName,

    get fieldData() {
      const { getValue } = props;
      return getValue(this.fieldName);
    },

    set fieldData(value) {
      const { setValue } = props;
      return setValue(this.fieldName, value);
    },
    
    // Event Handlers
    handleChange(e) {
      this.$dispatch('fieldUpdated');
    },
    
    binds: {
      lower: () => ({
        '@blur': 'handleChange',
        'x-model': 'data',
        'x-data': () => ({
          get data() {
            return this.fieldData?.lower;
          },
          set data(value) {
            this.fieldData.lower = value;
          }
        })
      }),
      upper: () => ({
        '@blur': 'handleChange',
        'x-model': 'data',
        'x-data': () => ({
          get data() {
            return this.fieldData?.upper;
          },
          set data(value) {
            this.fieldData.upper = value;
          }
        })
      })
    }
  }
});

export default () => {
  Alpine.bind('rangeField', Alpine.isolate(rangeField, 'rangeField'));
}