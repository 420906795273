import { choiceField } from "./base";
import { extendComponent } from "/utils/utils";
import isolate from "/plugins/isolate";

export const inhalationAssessmentEmployeeSeparation = extendComponent(choiceField,(props) => {
  return {
    '@form:loaded.document': 'handleFormLoad',
    'x-data': {
      async init() {
        const form = this.$context('form');
        await this.initChoices();
        form.registerEventListener('form:change', this.handleFormChange.bind(this));
      },
      
      async handleFormLoad() {
        const getValue = this.$context('form', 'getValue');
        if (!getValue) return;
        this.fieldValue = await getValue(this.fieldName);
        this.setChoicesByInBreathingZone(getValue('configuration.process.in_breathing_zone'));
      },
      
      async handleFormChange({ detail }) {
        const { fieldName, value } = detail;
        
        switch (fieldName) {
          // Disable EmployeeSeparation when process is in breathing zone.
          case 'configuration.process.in_breathing_zone':
            this.setChoicesByInBreathingZone(value);
            break;
        }
      },
      
      setChoicesByInBreathingZone(in_breathing_zone) {
        const { onChange } = props;
        if (in_breathing_zone === true) {
          // Set fieldValue to
          this.fieldValue = Alpine.enums.EmployeeSeparation.NO_CABIN.value;
          onChange(Alpine.enums.EmployeeSeparation.NO_CABIN.value);
          this.choices.disable();
          return;
        }
        this.choices.enable();
      }
    }
  }
});

export default () => {
  Alpine.bind('inhalationAssessmentEmployeeSeparation', isolate(inhalationAssessmentEmployeeSeparation, 'choiceField'))
}
