export const toastManager = (props) => ({
  '@trigger-messages.window': 'receiveMessages',
  'x-data': {
    messages: [],
    
    addMessage(message) {
      const messageID = this.$id(message.tag);
      this.messages.push({
        id: messageID,
        ...message
      });
    },
    
    dismissMessage(id) {
      this.messages = this.messages.filter(msg => msg.id !== id);
    },
    
    receiveMessages(e) {
      e.detail.messages.forEach((message) => {
        this.addMessage(message)
      });
    },
    
    binds: {
      toastMessage: (messageProps) => ({
        ':class': 'tag',
        'x-data': () => ({
          id: messageProps?.id,
          message: messageProps?.message,
          tag: messageProps?.tag,
          autoDismiss: true,
          
          init() {
            if (this.autoDismiss === true) {
              this.setDismissTimer();
            }
          },
          
          setDismissTimer() {
            setTimeout(() => {this.dismiss()}, 10000);
          },
          
          dismiss() {
            this.dismissMessage(this.id);
          }
        }),
      }),
      toastMessageLoader: (message, tags) => ({
        // Responsible for loading message rendered by django directly in the template.
        'x-data': () => ({
          init() {
            this.addMessage({message, tag: tags});
          }
        })
      }),
    }
  },
});

export default () => {
  Alpine.bind('toastManager', Alpine.isolate(toastManager, 'toastManager'));
}