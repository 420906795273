/*
Allows for calling $parent on any x-data with a parent.
*/

function alpineSuper(el) {
  if (!el || !el.parentNode) return {};
  return Alpine.mergeProxies(Alpine.closestDataStack(el.parentNode))
}

export default alpineSuper;
