import { bootstrapData } from "./bootstrap.js";
import { TreeRenderer } from './tree/treeHelpers.js';
import { LocationsDatagridNode } from './tree/locationsDataTree.js';

/*
Component that renders a tree of a given instance type. Designed as a drop-in replacement for datagrid.
 */
export const tree = (instanceType) => ({
  'x-data': () => ({
    ...bootstrapData(),
    instanceType,
    treeController: null,
    treeNodeTemplateRef: 'treeNodeTemplate',

    init() {
      this.initBootstrap();
      this.getController();
    },

    getController() {
      this.treeController = new window.controllers[this.instanceType].tree();
      this.treeController.getNextPage();
    },

    // Event Handlers
    handleItemAdded(item) {
      this.treeController.addItem(item);
    },
    handleItemUpdated(item) {
      const {pk} = item;
      this.treeController.refreshNodeFamily(pk);
    },
    handleItemDeleted(item) {
      const {pk} = item;
      this.treeController.deleteItem(pk);
    },

    treeRenderer: new TreeRenderer().toArrowFunction(),
    treeNode: new LocationsDatagridNode().toArrowFunction(),

  }),
  [`@${instanceType}-created.document`]: 'handleItemAdded($event.detail)',
  [`@${instanceType}-updated.document`]: 'handleItemUpdated($event.detail)',
  [`@${instanceType}-deleted.document`]: 'handleItemDeleted($event.detail)',
})

export default () => {
  Alpine.bind('tree', tree);
};