import { manyForm } from "/components/forms/base/manyForm";
import { get } from 'lodash-es';
import ResourceCRUDAdapter from "/libs/ResourceCRUDAdapter.js";

export const vapourPressuresForm = (props) => ({
  'x-data': () => ({
    get vapourPressures() {
      return props.getFieldValue();
    },
    
    set vapourPressures(value) {
      props.setFieldValue(value);
    },

    get composition() {
      const { getFormValue } = props;
      return getFormValue('composition');
    },

    getProductVP() {
      return this.vapourPressures?.product_vp;
    },

    setProductVP(pk, value) {
      this.vapourPressures.product_vp = value;
    },

    getComponentVP(componentPk) {
      return this.vapourPressures?.component_vps[componentPk] || null;
    },

    setComponentVP(componentPk, value) {
      this.vapourPressures.component_vps[componentPk] = value;
    },

    getProductErrors() {
      const validationErrors = props.getFieldValidationErrors();
      return get(validationErrors, 'product_vp', []);
    },

    getComponentErrors(componentPk) {
      const validationErrors = props.getFieldValidationErrors();
      return get(validationErrors, ['component_vps', componentPk], []);
    },

    getProcessTemperature() {
      const { getFormValue } = props;
      return getFormValue('assessment_process.process_temperature');
    },
    
    binds: {
      // We use manyForm for the unique row id assignments
      manyForm: manyForm
    }
  }),
});

export const vapourPressureRow = (props) => ({
  '@input': 'handleInput',
  'x-effect': 'handleEffect',
  'x-data': () => ({
    _item: undefined,
    showDropdown: false,
    value: null,
    calculationAPI: null,
    isCalculated: false,
    manualValue: null,

    handlePTChangeTimeout: null,
    handlePTChangeDebounce: 250,
    
    getProcessTemperature: props?.getProcessTemperature,
    
    get pk() {
      const { pk, store } = props;
      if (store === 'Products') {
        // If product we need to fetch the PK from the form.
        // Passing it through the DOM does not update on product change.
        const getValue = this.$context('form', 'getValue');
        return getValue && getValue('product');
      }
      return pk;
    },
    
    get item() {
      const { store } = props;
      if (this.pk && (!this._item || this._item.pk !== this.pk)) {
        // We first assign the promise, and replace it with the actual item on success
        this._item = Alpine.store(store).getOne(this.pk)
          .then(item => {this._item = item});
      }
      
      return this._item;
    },
    
    get errors() {
      const { getErrors } = props;
      return getErrors(this.pk);
    },
    
    get processTemperature() {
      return this.getProcessTemperature();
    },
    
    get vapourPressure() {
      return this.getVapourPressure(this.pk);
    },
    
    get status() {
      // Display errors if we have any
      if (this.errors && this.errors.length > 0) {
        return this.errors[0];
      }

      // Display info message for solids and gasses
      if (this.item?.physical_state === 3) {
        return 'No vapour pressure for gasses.'
      } else if (this.item?.physical_state === 1) {
        return 'No vapour pressure for solids.'
      }
      
      const vapourPressure = this.vapourPressure;
      if (!vapourPressure || vapourPressure === '') {
        return;
      }
      
      const predefinedVP = this.findMatchingVP(this.processTemperature);
      if (predefinedVP && Number(predefinedVP.pressure) === Number(vapourPressure)) {
        return 'predefined';
      }

      const manualValueIsEmpty = !this.manualValue || this.manualValue === '';
      if (this.isCalculated) {
        return 'calculated';
      }
      return manualValueIsEmpty ? null : 'manual';
    },
    
    async init() {
      const registerEventListener = this.$context('form', 'registerEventListener');
      registerEventListener && registerEventListener('form:change', this.handleFormChange.bind(this));
      this.calculationAPI = ResourceCRUDAdapter(Alpine.getURLForStore('VapourPressures'));

      // For truly manual values coming from the BE, initialize the manualValue to them, to prevent losing them.
      await this.item;
      const predefinedVP = this.findMatchingVP(this.processTemperature);
      if (!(predefinedVP && Number(predefinedVP.pressure) === Number(this.vapourPressure))) {
        // It's not a predefined value; assume it's manual
        this.manualValue = this.vapourPressure;
      }
    },
    
    getVapourPressure() {
      const { getVapourPressure } = props;
      return getVapourPressure(this.pk);
    },
    setVapourPressure(value) {
      const { setVapourPressure } = props;
      return setVapourPressure(this.pk, value);
    },

    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },

    closeDropdown() {
      if (!this.showDropdown) return;
      this.showDropdown = false;
    },

    handleInput() {
      this.setVapourPressure(this.value);
      this.manualValue = this.value;
      this.isCalculated = false;
    },

    handleEffect() {
      this.value = this.getVapourPressure();
    },

    async handleFormChange({ detail }) {
      const { fieldName } = detail;
      switch (fieldName) {
        case 'assessment_process.process_temperature':
          await this.handleProcessTemperatureChange();
          break;
        case 'product':
          this._item = undefined;
          // Give item getter time to fetch the new item
          await this.item;
          await this.handleProcessTemperatureChange();
          break;
      }
    },

    async handleProcessTemperatureChange() {
      // A change task was already queued; cancel and create a new one
      if (this.handlePTChangeTimeout) {
        clearTimeout(this.handlePTChangeTimeout);
        this.handlePTChangeTimeout = null;
      }
      this.handlePTChangeTimeout = setTimeout(async () => {
        // All values will be reset first. Then, and only then, will we attempt a predefined match or a calculation
        this.setVapourPressure(null);
        this.manualValue = null;
        this.isCalculated = false;

        // Now, we'll match it to a predefined existing vapour pressure, if any...
        const match = this.findMatchingVP(this.processTemperature);
        if (match) {
          this.setVapourPressure(match?.pressure);
          this.manualValue = null;
          this.isCalculated = false;
        } else if (this.canCalculate) {
          // ... or attempt a calculation.
          await this.setCalculatedVapourPressure();
        }
        clearTimeout(this.handlePTChangeTimeout);
        this.handlePTChangeTimeout = null;
      }, this.handlePTChangeDebounce);
    },

    findMatchingVP(temperature) {
      return this.item?.vapour_pressures?.find(vp => Number(vp.temperature) === Number(temperature));
    },

    get canCalculate() {
      /*
      To calculate, we need
        1. a boiling point;
        2. a process temperature between [20 degrees Celsius, boiling point); and
        3. at least one existing vapour pressure for reference.
       */
      return (
        this.item?.physical_state === 2  // We cannot calculate a vapour pressure if it's a gas or solid
        && !!this.item?.boiling_point
        && (Number(this.processTemperature) >= 20.0 && Number(this.processTemperature) < Number(this.item.boiling_point))
        && this.item?.vapour_pressures?.length > 0
      );
    },

    get disabledTooltipText() {
      if (!(Number(this.processTemperature) >= 20.0)) {
        return Alpine.enums.ScenarioVPError.NO_CALC_PROCESS_TEMP.label;
      }
      if (this.item?.physical_state === 3) {
        return Alpine.enums.ScenarioVPError.NO_CALC_GAS.label;
      } else if (this.item?.physical_state === 1) {
        return Alpine.enums.ScenarioVPError.NO_CALC_SOLID.label;
      }
      if (!this.item?.boiling_point) {
        return Alpine.enums.ScenarioVPError.NO_CALC_BOILING_POINT.label;
      } else if (Number(this.processTemperature) >= Number(this.item.boiling_point)) {
        return Alpine.enums.ScenarioVPError.NO_CALC_TEMP_BOILING_POINT.label;
      }
      if (!(this.item?.vapour_pressures?.length > 0)) {
        return Alpine.enums.ScenarioVPError.NO_CALC_PREDEFINED.label;
      }
      return '';
    },

    async calculateVapourPressure() {
      if (!this.canCalculate || !this.calculationAPI) return null;
      const response = await this.calculationAPI.call({
        method: 'POST',
        action: null,
        data: {
          target_temperature: this.processTemperature,
          boiling_point: this.item.boiling_point,
          vapour_pressures: this.item.vapour_pressures.map(vp => ({
            value: vp.pressure,
            temperature: vp.temperature,
          })),
        },
      });
      if (!response.ok) {
        // Show BE validation errors, if any
        if (response.body?.vapour_pressures?.length > 0) {
          this.$dispatch('trigger-messages', {
          messages: [
            {
              tag: 'error',
              message: response.body.vapour_pressures[0]
            }
          ]
        });
        }
        return null;
      }
      if (
        !response.body.target_vapour_pressure
        || isNaN(response.body.target_vapour_pressure)
      ) return null;

      // We need to round it, the FE expects an integer
      return Math.round(response.body.target_vapour_pressure);
    },

    async setCalculatedVapourPressure() {
      if (!this.canCalculate || !this.calculationAPI) return null;
      const calculatedPressure = await this.calculateVapourPressure();
      if (calculatedPressure !== null) {
        this.setVapourPressure(calculatedPressure);
        this.manualValue = null;
        this.isCalculated = true;
      }
      // Returns the pressure that was set, or null if a calculation was not possible.
      return calculatedPressure;
    },

    bindComponentPhysicalStateIcon: () => ({
      [':class']() {
        return {
          'fa-cube': this.item?.physical_state === 1,
          'fa-droplet': this.item?.physical_state === 2,
          'fa-wind': this.item?.physical_state === 3,
        };
      },
    }),

    bindVapourPressureOption: (value) => ({
      ['@click.prevent']() {
        this.closeDropdown();
        this.setVapourPressure(value);
        this.manualValue = value;
        this.isCalculated = false;
      }
    }),

    calculateButton: () => ({
      [':disabled']() {
        return !this.canCalculate;
      },
      '@click.stop.prevent': 'setCalculatedVapourPressure',
    }),
  }),
});

export default () => {
  Alpine.bind('vapourPressuresForm', Alpine.isolate(vapourPressuresForm, 'vapourPressuresForm'));
  Alpine.bind('vapourPressureRow', Alpine.isolate(vapourPressureRow, 'vapourPressureRow'));
};
