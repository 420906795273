import ResourceCRUDAdapter from "/libs/ResourceCRUDAdapter";
import { debounce, find, get, isArray, remove } from "lodash-es";
import { extendComponent } from "/utils/utils.js";

export const scenarioVapourPressuresContext = (props) => ({
  'x-data': () => ({
    getFieldValidationErrors: props.getFieldValidationErrors,
    setFieldValidationErrors: props.setFieldValidationErrors,
    resetValidationErrors: props.resetValidationErrors,
    
    get product() {
      return this.$context('form', 'substance');
    },
    
    get components() {
      return this.$context('form', 'components');
    },
    
    get vapourPressures() {
      return props.getFieldValue() || [];
    },
    
    set vapourPressures(value) {
      const { setFieldValue } = props;
      setFieldValue(value);
    },
    
    get processTemperature() {
      const { getFormValue } = props;
      return getFormValue('product.process_temperature');
    },
    
    get physicalState() {
      const { getFormValue } = props;
      return getFormValue('product.physical_state');
    },
    
    isVPApplicable(item) {
      const { getFormValue } = props;
      if ([getFormValue('product.product'), getFormValue('pgc')].includes(item.pk)) {
        return true;
      }
      return Alpine.enums.PhysicalState.get(item.physical_state) === Alpine.enums.PhysicalState.LIQUID;
    },
    
    getVapourPressure(pk) {
      if (!pk) return;
      return this.vapourPressures.find(vp => vp.reference_id === pk);
    },

    setVapourPressure(pk, value, status) {
      const matchingVP = this.getVapourPressure(pk);
      if (!matchingVP) return;
      matchingVP.pressure = value;
      matchingVP.status = status;
    },

    isPredefined(value, item) {
      if (!value || !item || !isArray(item?.vapour_pressures)) return;

      return !!item.vapour_pressures.find(vp => {
        return (Number(vp.temperature) === Number(this.processTemperature))
          && (Number(vp.pressure) === Number(value));
      })
    },

    findMatchingPredefined(item) {
      if (!item || !isArray(item?.vapour_pressures)) return;
      return item.vapour_pressures.find(vp => Number(vp.temperature) === Number(this.processTemperature));
    },
    
    canCalculateForItem(item) {
      /**
       * Requirements:
       * 1. a boiling point
       * 2. a process temperature between 20 and boiling point
       * 3. at least one existing vapour pressure for reference
       */

      // The scenario can override the product's physical state
      let itemPhysicalState = (
        item.pk === this.product?.pk
        ? this.physicalState
        : item.physical_state
      );
      return (
        Alpine.enums.PhysicalState.get(itemPhysicalState) === Alpine.enums.PhysicalState.LIQUID
        && !!item.boiling_point
        && Number(item.boiling_point) > Number(this.processTemperature)
        && Number(this.processTemperature) >= 20.0
        && item.vapour_pressures?.length > 0
      );
    },
    async calculateVapourPressure(item, itemIndex) {
      const api = ResourceCRUDAdapter(Alpine.getURLForStore('VapourPressures'));
      if (!item.boiling_point || !item.vapour_pressures) {
        console.warn('Item did not have the needed data for calculation of vapour pressure.')
        return null;
      }
      
      const response = await api.call({
        method: 'POST',
        data: {
          target_temperature: this.processTemperature,
          boiling_point: item.boiling_point,
          vapour_pressures: item.vapour_pressures.map(vp => ({
            value: vp.pressure,
            temperature: vp.temperature
          }))
        }
      });
      
      if (!response.ok) {
        // Show BE validation errors, if any
        if (response.body?.vapour_pressures) {
          // Since `vapour_pressures` is a list of many entries, but we calculate per-entry, we pad the field errors
          // so the field validation errors are displayed on the correct row.
          this.setFieldValidationErrors([...Array(itemIndex).fill({}), ...response.body.vapour_pressures]);
        } else if (typeof body !== 'string' && response.body?.length > 0) {
          // Prevent generating a thousand toasts if the body is an HTML error page
          for (const error of response.body || []) {
            this.$toast('error', error);
          }
        }
        return null;
      } else {
        this.resetValidationErrors('product');
      }
      
      const target = response.body.target_vapour_pressure;
      if (!target || isNaN(target)) {
        return null;
      }
      
      // We need to round it, the FE expects an integer
      return Math.round(target);
    },
    
    // STM-2197: Due to an internal bug with Alpine's timing & cleanup, we may not have the binds
    // object when the vapourPressureRow x-bind is evaluated by Alpine. Sometimes this will just
    // fail if a user clicks fast enough. There doesn't seem to be a good way to prevent this, so
    // this note is left here for whoever is brave enough to try and fix this next.
    binds: {
      vapourPressureRow: (props) => ({
        '@input': 'handleInput',
        'x-data': () => ({
          item: props.item,
          showDropdown: false,
          
          get isApplicable() {
            const status = Alpine.enums.VapourPressureStatus.get(this.record.status);
            return status !== Alpine.enums.VapourPressureStatus.NOT_APPLICABLE;
          },
          
          get isEditing() {
            // When changing products we lose the isEditing status in the rows
            // This is a workaround, haven't gotten to the bottom of why this happens.
            return this.$context('form', 'isEditing');
          },
          
          get record() {
            const { item } = props;
            
            if (!item) return {};
            return this.getVapourPressure?.(item.pk) || {};
          },
          
          get statusLabel() {
            return Alpine.enums.VapourPressureStatus.get(this.record.status)?.label;
          },
          
          get errors() {
            const fieldErrors = this.getFieldValidationErrors();
            return get(fieldErrors, props.index, []);
          },
          
          get canCalculate() {
            return this.canCalculateForItem(this.item);
          },
          
          toggleDropdown() {
            this.showDropdown = !this.showDropdown;
          },
          
          closeDropdown() {
            this.showDropdown = false;
          },
          
          async setCalculatedVapourPressure() {
            if (!this.canCalculate) return;

            const calculatedVapourPressure = await this.calculateVapourPressure(this.item, props.index);
            if (calculatedVapourPressure) {
              const statusToSet = this.isPredefined(calculatedVapourPressure, this.item)
                ? Alpine.enums.VapourPressureStatus.PREDEFINED.value
                : Alpine.enums.VapourPressureStatus.CALCULATED.value;

              this.setVapourPressure(this.item.pk, calculatedVapourPressure, statusToSet)
            }
          },
          
          handleInput(e) {
            this.record.pressure = e.target.value;

            if (!e.target.value) {
              this.record.status = null;
            } else if (this.isPredefined(e.target.value, this.item)) {
              this.record.status = Alpine.enums.VapourPressureStatus.PREDEFINED.value;
            } else {
              this.record.status = Alpine.enums.VapourPressureStatus.MANUAL.value;
            }
          },
          
          binds: {
            'physicalStateIcon': () => ({
              [':class']() {
                // We tried to define a `get physicalState()` here, but since this is not an isolated component,
                // the getter returns a Proxy(PhysicalState), which impairs the enum calculation.
                return {
                  'fa-cube': this.item?.physical_state === Alpine.enums.PhysicalState.SOLID.value,
                  'fa-droplet': this.item?.physical_state === Alpine.enums.PhysicalState.LIQUID.value,
                  'fa-wind': this.item?.physical_state === Alpine.enums.PhysicalState.GAS.value,
                };
              },
            }),
            'pressureInput': () => ({
              'x-model': 'record.pressure'
            }),
            'calculateButton': () => ({
              ':disabled': '!canCalculate',
              '@click.stop.prevent': 'setCalculatedVapourPressure'
            }),
            'vapourPressureOption': (value) => ({
              ['@click.prevent']() {
                this.setVapourPressure(
                  this.item.pk,
                  value,
                  Alpine.enums.VapourPressureStatus.PREDEFINED.value
                );
                this.closeDropdown();
              }
            }),
            'disabledCalculateTooltip': () => ({
              'x-show': '!canCalculate',
              'x-data': () => ({
                get tooltipText() {
                  let itemPhysicalState = Alpine.enums.PhysicalState.get(this.item.physical_state);
                  // For the product, we ask the scenario directly for the physical state; it may be overridden
                  if (this.item.pk === this.product?.pk) {
                    itemPhysicalState = this.physicalState;
                  }
                  
                  switch (itemPhysicalState) {
                    case Alpine.enums.PhysicalState.SOLID:  // Solid
                      return Alpine.enums.ScenarioVPError.NO_CALC_SOLID.label;
                    case Alpine.enums.PhysicalState.GAS:  // Gas
                      return Alpine.enums.ScenarioVPError.NO_CALC_GAS.label;
                    case Alpine.enums.PhysicalState.LIQUID:  // Liquid
                    default:
                      if (!(Number(this.processTemperature) >= 20.0)) {
                        return Alpine.enums.ScenarioVPError.NO_CALC_PROCESS_TEMP.label;
                      }
                      if (!this.item?.boiling_point) {
                        return Alpine.enums.ScenarioVPError.NO_CALC_BOILING_POINT.label;
                      }
                      if (Number(this.processTemperature) >= Number(this.item.boiling_point)) {
                        return Alpine.enums.ScenarioVPError.NO_CALC_TEMP_BOILING_POINT.label;
                      }
                      if (!(this.item?.vapour_pressures?.length > 0)) {
                        return Alpine.enums.ScenarioVPError.NO_CALC_PREDEFINED.label;
                      }
                  }
                  return '';
                }
              })
            }),
            'calculationErrorTooltip': () => ({
              'x-show.important': 'errors.length > 0',
              'x-data': () => ({
                get tooltipText() {
                  if (typeof this.errors === 'string') {
                    return this.errors;
                  } else if (Array.isArray(this.errors)) {
                    return this.errors[0] || '';
                  }
                  return '';
                }
              })
            })
          }
        })
      })
    }
  })
});

const scenarioVapourPressuresForm = extendComponent(scenarioVapourPressuresContext, (props) => ({
  '@form:loaded.document': 'initVapourPressures',
  'x-data': () => ({
    debouncedPTChange: null,
    
    async init() {
      const registerEventListener = this.$context('form', 'registerEventListener');
      registerEventListener('form:change', this.handleFormChange.bind(this));
      this.debouncedPTChange = debounce(this.handleProcessTemperatureChange.bind(this), 400);
      await this.initVapourPressures();
    },
    
    async initVapourPressures() {
      await this.product;
      await this.components;
      if (!this.product) {
        return;
      }
      
      if (this.physicalState !== Alpine.enums.PhysicalState.LIQUID.value) {
        this.vapourPressures = null;
        return;
      }
      
      // Make a local copy of vapourPressures
      // This is needed because initVapourPressures can be called each time items changes
      // If this happens too fast we might run initVapourPressures twice simultaneously.
      let oldVapourPressures = [...this.vapourPressures];
      
      const items = [await(this.product), ...await(this.components)];
      
      // Remove stale records
      // Clean up items no longer available, ie after changing product
      remove(
        oldVapourPressures,
        vp => find(items, {pk: vp.reference_id}) === undefined
      );
      
      // Remove all records of incorrect process temperature
      remove(
        oldVapourPressures,
        vp => Number(this.processTemperature) !== Number(vp.temperature)
      );

      // Construct new set of vapour pressure objects
      const newVapourPressures = [];
      for (const [itemIndex, item] of items.entries()) {
        const matchOld = find(oldVapourPressures, {reference_id: item.pk});
        if (matchOld) {
          newVapourPressures.push(matchOld);
          continue;
        }

        const initialVP = await this.getInitialVapourPressure(item, itemIndex);
        if (initialVP) {
          newVapourPressures.push(initialVP);
        }
      }

      // Store new vapour pressures
      this.vapourPressures = newVapourPressures;
    },
    
    async getInitialVapourPressure(item, itemIndex) {
      if (!item?.pk) return;
      // if (!this.isEditing) return;
      
      let initial = {
        reference_id: item.pk,
        name: item.name,
        temperature: this.processTemperature,
        pressure: null,
        status: undefined
      }
      
      // If item is component and not physical state liquid
      // We do not want a vapour pressure
      if (!this.isVPApplicable(item)) {
        initial.status = Alpine.enums.VapourPressureStatus.NOT_APPLICABLE.value;
        return initial;
      }

      const match = this.findMatchingPredefined(item);
      if (match) {
        initial.pressure = match.pressure
        initial.status = Alpine.enums.VapourPressureStatus.PREDEFINED.value;
        return initial;
      }
      
      // See if we can calculate
      if (this.canCalculateForItem(item)) {
        const calculatedPressure = await this.calculateVapourPressure(item, itemIndex);
        if (calculatedPressure) {
          initial.pressure = calculatedPressure;
          initial.status = Alpine.enums.VapourPressureStatus.CALCULATED.value;
          return initial;
        }
      }
      
      // 3. Return default empty
      return initial;
    },
    
    async handleFormChange({ detail }) {
      const { fieldName } = detail;
      switch (fieldName) {
        case 'product.process_temperature':
          this.debouncedPTChange();
          break;
      }
    },
    
    async handleProcessTemperatureChange() {
      await this.initVapourPressures();
    },
  })
}));

export default () => {
  Alpine.bind('scenarioVapourPressuresContext', Alpine.isolate(scenarioVapourPressuresContext, 'vapourPressuresContext'));
  Alpine.bind('scenarioVapourPressuresForm', Alpine.isolate(scenarioVapourPressuresForm, 'vapourPressuresForm'));
};
