import formatters from "/formatters";

export const assessmentCard = (props) => ({
  'x-data': () => ({
    isExpanded: false,
    assessment: props.assessment,
    getVersionNumber: props.getVersionNumber,

    store: null,
    alreadyLoaded: false,
    fullAssessmentCache: undefined,
    getPDFBackgroundRequest: null,

    get fullAssessment() {
      if (!this.fullAssessmentCache) {
        this.fullAssessmentCache = new Promise((resolve, reject) => {
          this.store.getOne(this.assessment.pk, 'extended').then(result => resolve(result))
        });
      }
      return this.fullAssessmentCache;
    },

    init() {
      this.store = Alpine.store('InhalationAssessments');
    },

    async toggle() {
      this.isExpanded = !this.isExpanded;
    },

    dateCreated() {
      return formatters.naturaldt(this.assessment.date_created);
    },

    cardSubtitle() {
      return `${this.dateCreated()} - ${this.assessment.finalised_by}`;
    },

    async generatePDF() {
      // TODO: Very naive implementation of a "background" task
      if (!!this.getPDFBackgroundRequest) return;
      const generatePDFResponse = await this.store.api.call({
        method: 'POST',
        pk: this.assessment.pk,
        action: 'pdf',
      });
      if (!generatePDFResponse.ok || !generatePDFResponse.body?.pk) {
        Alpine.dispatch(document, 'trigger-messages', {
          messages: [
            {
              tag: 'error',
              message: Alpine.enums.PdfErrorMessage.PDF_GENERATE_FAILED.label
            }
          ]
        });
        return;
      }
      // The file is already generated, no need to poll in the background for it
      if (!!generatePDFResponse.body?.generated_file) {
        await this.downloadPDFContents(generatePDFResponse.body.generated_file);
        return;
      }

      this.getPDFBackgroundRequest = setInterval(async () => {
        const getPDFResponse = await this.getPDF();
        if (!getPDFResponse.ok) {
          Alpine.dispatch(document, 'trigger-messages', {
            messages: [
              {
                tag: 'error',
                message: Alpine.enums.PdfErrorMessage.PDF_DETAILS_RETRIEVE_FAILED.label
              }
            ]
          });
          clearTimeout(this.getPDFBackgroundRequest);
          this.getPDFBackgroundRequest = null;
          return;
        }

        // If the file has been generated, stop the polling
        if (!!getPDFResponse.body?.generated_file) {
          clearTimeout(this.getPDFBackgroundRequest);
          this.getPDFBackgroundRequest = null;
          await this.downloadPDFContents(getPDFResponse.body.generated_file);
        } else if (getPDFResponse.body?.resolution === 'failed') {
          Alpine.dispatch(document, 'trigger-messages', {
            messages: [
              {
                tag: 'error',
                message: Alpine.enums.PdfErrorMessage.PDF_RENDER_ERROR.label
              }
            ]
          });
          clearTimeout(this.getPDFBackgroundRequest);
          this.getPDFBackgroundRequest = null;
        }

      }, 2000);
    },

    async getPDF() {
      return await this.store.api.call({
        method: 'GET',
        pk: this.assessment.pk,
        action: `pdf`,
      });
    },

    async downloadPDFContents(fileContents) {
      const base64Response = await fetch(`data:application/pdf;base64,${fileContents}`);
      const pdfBlob = await base64Response.blob();
      // TODO we need to ensure this works in all browsers
      const pdfURL = URL.createObjectURL(pdfBlob);
      window.open(pdfURL, '_blank');
    },

    binds: {
      fullAssessment: () => ({
        async ['x-text']() {
          if (!this.isExpanded) return '';
          return JSON.stringify((await this.fullAssessment)?.assessment, null, 2);
        },
      }),
      pdfButton: () => ({
        'class': 'bi bi-file-earmark-pdf finalised-assessment-pdf-button',
        '@click.stop': 'generatePDF',
        ':disabled': '!!getPDFBackgroundRequest',
      }),
      pdfLoadingIndicator: () => ({
        'x-show': '!!getPDFBackgroundRequest',
        'class': 'pdf-loading-indicator',
      }),
    },
  }),
});

export default () => {
  Alpine.bind('assessmentCard', Alpine.isolate(assessmentCard));
};
