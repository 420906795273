import * as _ from 'lodash-es';
export const concentrationMixin = () => ({
  taskBasedConcentration(percentile, withPPE = false) {
    return this.getConcentration('task', percentile, withPPE);
  },

  timeBasedConcentration(percentile, withPPE = false) {
    return this.getConcentration('daily', percentile, withPPE);
  },

  getConcentration(concentrationType, percentile, withPPE = false) {
    if (!['task', 'daily'].includes(concentrationType)) return '';
    const concentrationData = this.getConcentrationData();
    if (!concentrationData?.percentiles) return '';
    return _.get(
      // TODO: For some components, more than one model may be applicable. For now, only one is assumed.
      _.head(_.values(concentrationData.percentiles)),
      [
        percentile,
        `${concentrationType}_concentrations`,
        `${withPPE ? 'with_ppe' : 'base'}`,
      ],
      '',
    );
  },

  getConcentrationData() {
    return {};
  },
});

export default () => {
  Alpine.data('concentrationMixin', concentrationMixin);
}