export default () => {
  Alpine.directive(
    'key',
    (el, { value, modifiers, expression }, { Alpine, effect, cleanup }) => {
      Alpine.mutateDom(() => {
        el.setAttribute('x-key', expression);
      });
      cleanup(() => {
        Alpine.mutateDom(() => {
          el.removeAttribute('x-key');
        });
      });
    }
  );
};