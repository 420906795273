import * as _ from 'lodash-es';
export const hazardIndications = () => ({
  'x-effect'() {
    if (!this.data?.hazard_classification?.hazard_codes) return;
    this.getWorstCaseIndications(this.data.hazard_classification.hazard_codes);
  },
  'x-data': () => ({
    store: null,
    indications: {
      inhalation: 'A',
      skinLocal: null,
      skinSystemic: null,
      eyes: 'B'
    },
    hazardStatements: [],
    
    init() {
      this.store = Alpine.store('HazardStatements');
    },
    
    show() {
      this.$dispatch('open-modal:hazard-indications--modal');
    },
    
    async getWorstCaseIndications(hazardCodes, physicalState) {
      if (!this.store) return;
      this.hazardStatements = await this.store.getMany(hazardCodes);
      
      const inhalation = _.map(this.hazardStatements, 'indications.inhalation').filter(x => x !== undefined);
      const skinLocal = _.map(this.hazardStatements, 'indications.skin_local').filter(x => x !== undefined);
      const skinSystemic = _.map(this.hazardStatements, 'indications.skin_systemic').filter(x => x !== undefined);
      const eyes = _.map(this.hazardStatements, 'indications.eyes').filter(x => x !== undefined);
      
      this.indications = {
        inhalation: _.defaultTo(_.max(inhalation), 'A'),
        skinLocal: _.max(skinLocal),
        skinSystemic: _.max(skinSystemic),
        eyes: _.defaultTo(_.max(eyes), 'B')
      };
    },
  }),
});

export default () => {
  Alpine.bind('hazardIndications', hazardIndications);
}