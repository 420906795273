import { isFunction } from "lodash-es";

export const radioField = (props) => ({
  'x-data': () => ({
    fieldName: props?.fieldName,
    
    get fieldData() {
      return this.$context('form', 'getValue')?.(this.fieldName);
    },
    
    binds: {
      radioOption: ({ id, value }) => ({
        'x-data': () => ({
          id,
          value,
          
          binds: {
            input: () => ({
              'type': 'radio',
              ':id': 'id',
              ':name': 'fieldName',
              ':value': 'value',
              '@change': 'handleFieldChange',
              ':checked': 'checked',
              'x-data': () => ({
                get checked() {
                  return this.value === this.fieldData;
                },
                handleFieldChange(e) {
                  const setValue = this.$context('form', 'setValue');
                  if (!isFunction(setValue)) return;
                  setValue(this.fieldName, this.value);
                }
              })
            }),
            label: () => ({
              ':for': 'id',
            })
          }
        })
      }),
    }
  })
});

export default () => {
  Alpine.bind('radioField', Alpine.isolate(radioField, 'radioField'));
}