import { bootstrapData } from "./bootstrap.js";
import { tabsData } from "./tabs.js";

// TODO: Refactor to use Alpine isolated framework at some point
export const panel = (props) => ({
  ':id': 'panelId',
  '@form:validationUpdated': 'reactivateTab',
  '@hide-tab': 'hideTab',
  'x-data': () => ({
    ...bootstrapData(),
    ...tabsData(),

    panelId: props.panelId,

    init() {
      this.initPanel();
    },
    initPanel() {
      // Call registerPanel on the content manager
      this.registerPanel(this.panelId, this);
      this.initBootstrap();
      this.initTabs();
    },

    // Actions
    canExpand() {
      const displayMode = this.$context('application', 'displayMode');
      if (displayMode === 'mobile') return false;
      return this.$context('content', 'articleVisible');
    },
    doExpand() {
      this.isExpanded = true;
      this.$dispatch('hide-article');
    },
    canContract() {
      const displayMode = this.$context('application', 'displayMode');
      if (displayMode === 'mobile') return false;
      return !this.$context('content', 'articleVisible');
    },
    doContract() {
      this.$dispatch('show-article');
    },
    canClose() {
      return this.$context('content', 'isArticleSet');
    },
    doClose() {
      this.$dispatch('hide-panel');
    },
  }),
});

export default () => {
  Alpine.bind('panel', panel);
}
