import { marked } from 'marked';
import DOMPurify from 'dompurify';

export const commentList = (props) => ({
  '@comments:commentSaved': 'handleCommentSaved',
  '@comments:commentDeleted': 'fetchComments',
  '@comments:commentCancelled': 'handleCommentCancelled',
  'x-show': '!!resourceId',
  'x-data': {
    resourceId: props.resourceId,
    resourceType: props.resourceType,
    
    list: new window.controllers['Comments'].list(),
    
    // New comment
    showNewCommentInput: false,
    
    async init() {
      if (!this.resourceId) {
        // We have no resource ID, we're most likely in a create form
        // Call hide-tab to make sure we no longer show the tab-comments
        this.$dispatch('hide-tab', {tabId: 'tab-comments'});
        return;
      }
      this.fetchComments();
    },
    
    get comments() {
      return this.list.listItems || [];
    },
    
    async fetchComments() {
      if (!this.resourceId) return;
      await this.list.applyFilters({
        resource_type: this.resourceType,
        resource_id: this.resourceId
      });
    },
    
    addNewComment() {
      this.showNewCommentInput = true;
    },
    reset() {
      this.showNewCommentInput = false;
    },
    
    handleCommentSaved({detail}) {
      const { pk } = detail;
      if (pk === undefined) {
        this.reset();
      }
      this.fetchComments();
    },
    handleCommentCancelled({detail}) {
      const { pk } = detail;
      if (pk === undefined) {
        this.reset();
      }
    },
  }
});

export const commentInstance = (props) => ({
  'x-data': {
    resourceType: props?.resourceType,
    resourceId: props?.resourceId,
    
    _comment: undefined,
    _controller: undefined,
    isEditing: false,
    newCommentText: "",
    
    get comment() {
      const { comment } = props;
      if (!this._comment && comment?.pk) {
        this.fetchComment(comment.pk);
      }
      return this._comment;
    },
    
    init() {
      const { comment } = props;
      if (!comment?.pk) this.isEditing = true;
    },
    
    get isEdited() {
      if (!this.comment) return false;
      const created = Math.floor(new Date(this.comment?.date_created).getTime() / 1000);
      const modified = Math.floor(new Date(this.comment?.date_modified).getTime() / 1000);
      return Math.abs(modified - created) > 1;
    },
    get markdown() {
      if ([undefined, null].includes(this.comment?.comment)) return "";
      return DOMPurify.sanitize(marked.parse(this.comment.comment, {breaks: true}));
    },
    get controller() {
      if (!this._controller) {
        this._controller = new window.controllers['Comments'].detail();
      }
      return this._controller;
    },
    
    async fetchComment(pk) {
      this._comment = await this.controller.store.getOne(pk);
    },
    
    async doEdit() {
      if (this.comment) {
        this.newCommentText = this.comment?.comment;
      }
      this.isEditing = true;
    },
    async doDelete(e) {
      if (!this.comment?.pk) {
        return;
      }
      
      const confirmText = e.target.dataset.confirmtext;
      if (confirmText && confirm(confirmText)) {
        await this.controller.store.api.call({
          method: 'DELETE',
          pk: this.comment.pk,
          data: {
            resource_type: this.resourceType,
            resource_id: this.resourceId
          }
        })
        // await this.controller.store.delete(this.comment.pk);
        this.$dispatch('comments:commentDeleted', {pk: this.comment?.pk});
      }
    },
    async doSave() {
      if (!this.isEditing) return;
      await this.controller.save({
        pk: this.comment?.pk,
        resource_id: this.resourceId,
        resource_type: this.resourceType,
        comment: this.newCommentText
      });
      this.reset();
      this.$dispatch('comments:commentSaved', {pk: this.comment?.pk});
    },
    doCancel() {
      this.reset();
      this.$dispatch('comments:commentCancelled', {pk: this.comment?.pk});
    },
    reset() {
      // Reset newComment after save
      this.newCommentText = "";
      if (this.comment?.pk) {
        this.isEditing = false;
      }
    },
    
    binds: {
      commentText: () => ({
        'x-model': 'newCommentText',
      }),
      btnEdit: () => ({
        'x-show': 'comment?.can_edit',
        'class': 'btn btn-sm btn-link p-0',
        '@click.stop': 'doEdit',
      }),
      btnDelete: () => ({
        'x-show': 'comment?.can_delete',
        'class': 'btn btn-sm btn-link p-0',
        '@click.stop': 'doDelete',
      }),
      btnSave: () => ({
        'class': 'btn btn-primary btn-sm',
        '@click.stop': 'doSave',
      }),
      btnCancel: () => ({
        'class': 'btn btn-default btn-sm',
        '@click.stop': 'doCancel',
      }),
      editedTag: () => ({
        'x-show': 'isEdited',
        ':title': 'new Date(comment?.date_modified).toLocaleString()'
      })
    }
  }
});


export default () => {
  Alpine.bind('commentList', Alpine.isolate(commentList, 'comments'));
  Alpine.bind('commentInstance', Alpine.isolate(commentInstance, 'comment'));
}
