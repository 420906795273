import registerBase from "./base";
import registerInhalationAssessmentConfigurations from "./InhalationAssessmentConfigurations";
import scenarios from './Scenarios'
import helpdesk from './Helpdesk';

export default () => {
  helpdesk();
  registerBase();
  registerInhalationAssessmentConfigurations();
  scenarios();
}
