import { extendComponent } from '/utils/utils';
import { form } from '/components/forms/base/form';

export const HelpdeskForm = extendComponent(form, (props={}) => ({
  'x-data': (props) => ({
    isEditing: true,
    
    async doSave() {
      const response = await this.controller.save(this.data, (response) => {
        // Callback function to execute on success
        this.$toast('success', Alpine.enums.ActionMessage.HELPDESK_SEND_MESSAGE_SUCCESSFUL.label);
        const closeModal = this.$context('modal', 'closeModal');
        closeModal?.();
      });
      
      if (!response.ok) {
        this.$toast('error', Alpine.enums.ActionMessage.HELPDESK_SEND_MESSAGE_FAILED.label);
        this.setValidationErrors(response.body);
      }
    },
  }),
}));

export default () => {
  Alpine.bind('HelpdeskForm', Alpine.isolate(HelpdeskForm, 'form'));
};