import magicContext from "./context";
import dispatch from "./dispatch";
import magicSuper from "./super";
import toast from "./toast";

export default () => {
  Alpine.dispatch = dispatch;
  Alpine.magic('context', magicContext);
  Alpine.magic('super', magicSuper);
  Alpine.magic('toast', toast);
}