/*
* This file contains the formFieldTreeSelect component, which is intended to be used within the autocomplete component.
* In particular, it replaces the default "autocompleteOptions" textual selection with a tree-based selection.
* */

import {
  AutocompleteTreeRenderer,
  AutocompleteTreeNode,
} from "./autocompleteTreeSelect.js";

export const formFieldTreeSelect = (
) => ({
  [':class']() {
    return {
      'field-autocomplete': true,
      'field-tree-select': true,
      'is-invalid': this.isInvalid(),
      'is-missing': this.isMissing(),
    };
  },
  [':role']() {
    return 'tree';
  },
  ':id': 'fieldName',
  ':name': 'fieldName',
  'x-data': () => ({
    // Reference (x-ref) for the tree node template. This is set here because setting x-ref by binds does not work.
    treeNodeTemplateRef: 'treeNodeTemplate',

    init() {
      // For now, disable keyboard selection with arrow keys and enter.
      this.keyboardSelectionEnabled = false;
    },

    /* Component that evaluates a given expression of nodes to render and, for each, applies a `treeNode` template. */
    treeRenderer: new AutocompleteTreeRenderer().toArrowFunction(),
    treeNode: new AutocompleteTreeNode().toArrowFunction(),

  }),
});