import * as _ from 'lodash-es';
import {
  TreeRenderer,
  TreeRendererData,
  TreeNode,
  TreeNodeData,
} from '../../../../tree/treeHelpers.js';

export class AutocompleteTreeNodeData extends TreeNodeData {
  init() {
    this.nodeRef = this;
    this.updateVisibility();
    this.$watch('currentSearch', () => {
      this.updateVisibility();
    });
  }

  selectOption() {
    const optionValue = this.getOptionValue(this.node);
    // Single selection
    if (!this.multiple) {
      _.set(this.data, this.fieldName, optionValue);
      this.$dispatch('change');
    } else {
      // The multiple selection has its custom behaviour

      // 1. Selecting all children of a parent soft-selects it (only presentation).
      //    The parent itself is not selected and thus its value is not added to the selection.
      // 2. Selecting a parent supersedes the selection of their descendants.
      //    All descendants of this parent will be deselected, and replaced by a soft-selection.

      if (!!this.node.children) {
        this.items = _.difference(this.items, this.getDescendants());
      }
      this.items = [...this.items, optionValue];
    }
  }

  deselectOption() {
    const optionValue = this.getOptionValue(this.node);
    if (!this.multiple) {
      this.clearAutocomplete();
    } else {
      // The multiple deselection has its custom behaviour

      // 1. Deselecting a parent will deselect all of its descendants.
      // 2. Deselecting a node, if all its siblings are selected, will deselect its parent
      //    and instead select each of the children individually.
      if (this.parentNode && this.parentNode.isTreeNodeSelected()) {
        this.parentNode.deselectOption();
        this.items = [...this.items, ...this.getSiblings()];
      } else {
       let optionIndex = this.items.indexOf(optionValue);
        if (optionIndex !== -1) {
          this.items.splice(optionIndex, 1);
        } else if (this.allChildrenSelected()) {
          // If the node being deselected was a soft-selected parent with all children selected,
          // then deselect all children.
          this.items = _.difference(this.items, this.node.children);
        }
      }
    }
  }

  // Helper method to gather the siblings of a node
  getSiblings(includeSelf = false) {
    if (!this?.parentNode?.node) return null;
    let siblings = [...this.parentNode.node.children];
    if (!includeSelf) siblings = siblings.filter(nodePk => nodePk !== this.getOptionValue(this.node));
    return siblings;
  }

  // Helper method to gather all descendants of a node (recursively).
  getDescendants(includeSelf = false) {
    return this.autocompleteController.getDescendants(
      this.getOptionValue(this.node),
      this.autocompleteController.treeItems,
      includeSelf,
    );
  }

  isTreeNodeSelected() {
    return this.isOptionSelected(this.node) || this.isTreeNodeSoftSelected(this.node);
  }

  isTreeNodeIndeterminate() {
    // When all children are selected, it is also considered indeterminate
    if (!this.node.children) return false;
    // const numberChildren = this.node.children.length;
    const numberSelectedChildren = _.intersection(this.items, this.getDescendants()).length;
    return (numberSelectedChildren > 0);
    // return (numberSelectedChildren > 0 && numberSelectedChildren !== numberChildren);
  }

  allChildrenSelected() {
    if (!this.node.children) return false;
    const numberChildren = this.node.children.length;
    const numberSelectedChildren = _.intersection(this.items, this.node.children).length;
    return (numberSelectedChildren > 0 && numberSelectedChildren === numberChildren);
  }

  isTreeNodeSoftSelected() {
    // A tree node is soft-selected (i.e. selected only in appearance) when

    //    a. All direct children of the node are selected individually; or
    // if (this.allChildrenSelected()) return true;

    //    b. The parent of the node is selected.
    if (!this.parentNode) return false;
    return this.parentNode.isTreeNodeSelected();
  }

  // Method to efficiently compute the node's visibility from the bottom-up, not a top-down recursive approach.
  updateVisibility() {
    const optionText = this.getOptionText(this.node).toLowerCase();
    const optionValue = this.getOptionValue(this.node);

    const keywordMatches = (
      !this.currentSearch
      || optionText.search(this.currentSearch.toLowerCase()) !== -1
    );
    this.isVisible = keywordMatches || !!this.visibleChildren.size;

    // If we do not want to display archived items, hide archived nodes
    if (this.isArchived() && !this.showArchivedOptions) this.isVisible = false;

    if (this.parentNode) {
      this.isVisible
        ? this.parentNode.visibleChildren.add(optionValue)
        : this.parentNode.visibleChildren.delete(optionValue);
      this.parentNode.updateVisibility();
    }
  }

  handleItemClick() {
    if (this.isTreeNodeSelected()) {
      this.deselectOption();
    } else {
      this.selectOption();
    }
  }

  isArchived() {
    return !!this.node.date_archived;
  }

  getData(node, parentNode) {
    const dataObject = {
      ...super.getData(node, parentNode),
      ...{
        selectOption: this.selectOption,
        deselectOption: this.deselectOption,
        getSiblings: this.getSiblings,
        getDescendants: this.getDescendants,
        isTreeNodeSelected: this.isTreeNodeSelected,
        isTreeNodeSoftSelected: this.isTreeNodeSoftSelected,
        isTreeNodeIndeterminate: this.isTreeNodeIndeterminate,
        allChildrenSelected: this.allChildrenSelected,
        updateVisibility: this.updateVisibility,

        treeNodeCheckbox: () => ({
          ['x-show']() {
            return this.multiple;
          },
          [':class']() {
            return {
              'form-check-input': true,
            };
          },
          [':style']() {
            return {
              margin: `0 0 0 calc(0.5rem + ${node.depth * 24}px)`,
            };
          },
          ['x-effect']() {
            this.$el.indeterminate = this.isTreeNodeIndeterminate();
          },
          ':value': 'isTreeNodeSelected',
          ['@click.stop.prevent']() {
            // Prevents a glitch that triggers the checkbox twice in rapid succession (mouseover + click).
            // For click listeners inside the autocomplete's dropdown, we use @mousedown
            // because it doesn't trigger a blur event, which would close the dropdown itself.
          },
          ['@mousedown.stop.prevent']() {
            this.handleItemClick();
          },
        }),
        treeNodeItem: () => ({
          [':class']() {
            return {
              'tree-node-item': true,
            };
          },
          ['x-text']() {
            return this.getOptionText(node);
          },
        }),
      },
    };

    return dataObject;
  }

}

export class AutocompleteTreeNode extends TreeNode {
  dataClass = AutocompleteTreeNodeData;
}

export class AutocompleteTreeRendererData extends TreeRendererData {
  getData(expression, parentNodeExpression = null) {
    const dataObject = super.getData(expression, parentNodeExpression);
    dataObject['treeControllerField'] = 'autocompleteController';
    return dataObject;
  }
}

export class AutocompleteTreeRenderer extends TreeRenderer {
  dataClass = AutocompleteTreeRendererData;
}

export default {
  AutocompleteTreeNode,
  AutocompleteTreeRenderer,
};