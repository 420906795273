import { isObject, round } from 'lodash-es';

const _formatNumber = (value, options={}, ignoreLocale=false) => {
  const locale = ignoreLocale ? 'en-GB' : navigator.language;
  return new Intl.NumberFormat(locale, options).format(value)
};

// Formats concentration objects to display properly in non-edit mode.
// @param value: An object formatted as {lower: Number, upper: Number}.
export const concentration = (value) => {
  if (!isObject(value) && !value['upper'] && !value['lower']) return '';
  
  const { lower, upper } = value;
  const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
  
  if (lower && upper) {
    if (Number(lower) === Number(upper)) {
      return `${_formatNumber(lower, options)}%`;
    }
    return `${_formatNumber(lower, options)}% - ${_formatNumber(upper, options)}%`
  } else if (lower) {
    return `≥ ${_formatNumber(lower, options)}%`
  } else if (upper) {
    return `< ${_formatNumber(upper, options)}%`
  } else {
    return '';
  }
};

export const relevantdecimal = (value, precision=2) => {
  /**
   * Returns a formatted number with relevant digits equal to the precision
   */
  if ([undefined, null, ''].includes(value)) return value;
  
  precision = Number(precision);
  
  if (value >= 100) {
    // Round to nearest integer value
    return decimal(Math.round(value));
  }
  else if (value < 1) {
    // Return precision significant digits
    return _formatNumber(value, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 7,
      maximumSignificantDigits: precision,
    });
  } else {
    return decimal(value, precision);
  }
}

// Displays a locale-aware decimal with optional rounding.
export const decimal = (value, precision=null) => {
  if ([undefined, null, ''].includes(value)) return value;
  if (precision) {
    return _formatNumber(value, {
      minimumFractionDigits: Number(precision),
      maximumFractionDigits: Number(precision),
    });
  }
  return _formatNumber(value);
};

// Displays a locale-aware RCR value with a different number of decimal places depending on value.
export const rcr = (value) => {
  if ([undefined, null, ''].includes(value)) return '';
  const RCRValue = Number(value);
  if (isNaN(RCRValue)) return value;

  if (RCRValue < 0.01) {
    return '< ' + _formatNumber(0.01);
  } else if (RCRValue < 0.1) {
    return _formatNumber(RCRValue, { minimumFractionDigits: 3, maximumFractionDigits: 3 });
  } else if (RCRValue >= 100) {
    // Round to nearest integer value
    return decimal(Math.round(RCRValue));
  } else {
    return _formatNumber(RCRValue, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
};

// Displays a Roman Numeral for a given input value
export const romannumeral = (value) => {
  if ([undefined, null, ''].includes(value)) return NaN;
  let num = Math.floor(Number(value));
  if (isNaN(num)) return NaN;
  
  // https://stackoverflow.com/a/41358305
  const r = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
    };
  let str = '';
  
  for (let i of Object.keys(r)) {
    let q = Math.floor(num / r[i]);
    num -= q * r[i];
    str += i.repeat(q);
  }
  return str;
};

export const fractiontopercentage = (value) => {
  if (isNaN(value) || !isFinite(value)) return '-';
  return round(value * 100);
};

export default {
  concentration,
  decimal,
  relevantdecimal,
  rcr,
  romannumeral,
  fractiontopercentage
};
