import { get } from "lodash-es";

export function componentsTemplates(template) {
  // Make sure this is a regular function, not an arrow function to get `this` to point to the instance of choices.
  return {
    item: ({ classNames }, data) => {
      return template(`
        <div class="d-flex flex-column ${classNames.item}
            ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}
            ${data.placeholder ? classNames.placeholder : ''}"
            data-item data-id="${data.id}" data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''}
            ${data.disabled ? 'aria-disabled="true"' : ''}
        >
            <span class="label overflow-wrap-anywhere">${ data.label }</span>
            <ul class="list-horizontal separator text-muted">
                ${ get(data.customProperties, 'cas_numbers.0', null)
                    ? `<li class="small cas-number">${ data.customProperties.cas_numbers[0] }</li>`
                    : ''
                }
            </ul>
            
        </div>
      `);
    },
    choice: ({classNames}, data) => {
      return template(`
        <div class="d-flex flex-column ${ classNames.item } ${ classNames.itemChoice } ${ data.disabled ? classNames.itemDisabled : classNames.itemSelectable }"
            data-select-text="${ this.config.itemSelectText }"
            data-choice ${ data.disabled ? 'data-choice-disabled' : 'data-choice-selectable' }
            data-id="${ data.id }"
            data-value="${ data.value }"
            ${ data.groupId > 0 ? 'role=treeItem' : 'role="option"' }
        >
            <span class="label overflow-wrap-anywhere">${ data.label }</span>
            <ul class="list-horizontal separator text-muted">
            ${ get(data.customProperties, 'cas_numbers.0', null)
                ? `<li class="small cas-number">${ data.customProperties.cas_numbers[0] }</li>`
                : ''
            }
            </ul>
        </div>
      `);
    }
  }
}