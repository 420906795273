import isolate from '/plugins/isolate';
import { extendComponent, objectValuesAreEmpty } from '/utils/utils.js';
import { formField } from '/components/forms/base/field.js';

export const formFieldRange = extendComponent(formField, (props={}) => ({
  'x-data': () => ({
    validate() {
      // Override validate method in formField to handle rangeFields FE validation. Raise error if
      // field is empty & required.
      if (props.required) {
        const fieldValue = this.getFieldValue();
        if (objectValuesAreEmpty(fieldValue)) {
          this.setFieldValidationErrors([Alpine.enums.ActionMessage.FIELD_REQUIRED.label]);
          return;
        }
      }
    },
  }),
}));

export default () => {
  Alpine.bind('formFieldRange', isolate(formFieldRange, 'formFieldRange'));
};
