import { extendComponent } from "/utils/utils";
import { form } from "/components/forms/base/form";
import { flatMap, get, set } from "lodash-es";

export const ScenarioLimitValuesForm = extendComponent(form, (props={}) => ({
  '@click.outside': '$context("modal", "closeModal")?.()',
  'x-data': (props) => ({
    scenarioPk: props.scenario,
    exposureType: props.exposureType,
    context: props.context,
    isEditing: true,
    
    _scenario: undefined,
    _substance: undefined,
    
    initialData: {
      items: []
    },
    
    get scenario() {
      if (this._scenario === undefined) {
        this._scenario = this.fetchScenario();
      }
      return this._scenario;
    },
    get substance() {
      if (this._substance === undefined) {
        this._substance = this.fetchSubstance();
      }
      return this._substance;
    },
    
    async init() {
      await this.initForm();
      await this.fetchFormData();
    },

    async fetchScenario() {
      const scenario = await Alpine.store('ExposureScenarios').getOne(this.scenarioPk, 'extended');
      if (!scenario) return;
      this._scenario = scenario;
      return scenario;
    },
    async fetchSubstance() {
      const scenario = await this.scenario;
      const scenarioType = Alpine.enums.ScenarioType.get(scenario?.scenario_type);
      
      let substance;
      switch (scenarioType) {
        case Alpine.enums.ScenarioType.PRODUCT:
          substance = await Alpine.store('Products').getOne(this.scenario.product.product, 'extended');
          break;
        case Alpine.enums.ScenarioType.PGC:
          substance = await Alpine.store('ProcessGeneratedContaminants').getOne(this.scenario.pgc, 'extended');
          break;
      }
      
      if (!substance) return;
      this._substance = substance;
      return substance;
    },

    async shouldShowDustLVSelector() {
      const scenario = await this.scenario;
      const substanceType = Alpine.enums.SubstanceType.get(scenario?.substance_type);
      switch (substanceType) {
        case Alpine.enums.SubstanceType.SOLID:
        case Alpine.enums.SubstanceType.STONE:
        case Alpine.enums.SubstanceType.METAL:
        case Alpine.enums.SubstanceType.WOOD:
          return true;
        default:
          // For now, calculations on PGCs do not return a product-level calculation with dust.
          return false;
      }
    },
    
    async getFiltersForField(fieldName, data={}) {
      const LimitValueType = Alpine.enums.LimitValueType;
      const filterTypes = [];
      switch (fieldName) {
        case 'limit_value_st':
          filterTypes.push(LimitValueType.TWA_15M.value, LimitValueType.CEILING.value);
          break;
        case 'limit_value_lt':
          filterTypes.push(LimitValueType.TWA_8H.value);
          break;
      }

      if (!!get(data, 'isDustLV', false)) {
          // Product level filtering
          return {
            dust_type: get(this.substance, 'dust_type'),
            limit_type: filterTypes,
            exposure_type: this.exposureType,
          }
      } else {
          // Component filtering
          return {
            component: get(data, 'composition_id'),
            limit_type: filterTypes,
            exposure_type: this.exposureType,
          }
      }
    },
    
    async fetchFormData() {
      /**
       * fetchFormData is used to collect existing LimitValue records from the back-end
       * and create initial values for those items in the composition that did not have
       * existing records yet.
       */
      await this.substance;
      const store = Alpine.store('ExposureScenarioLimitValues');
      
      // Construct form data after substance data has been fetched.
      const response = await store.fetchList(
        {
          filters: {
            scenario: this.scenarioPk,
            exposure_type: this.exposureType
          }
        },
        'extended'
      );
      
      let storeData = [];
      if (response.ok) {
        // Get pks from body
        const pks = flatMap(response.body, c => c.pk);
        storeData = await store.getMany(pks, 'extended');
      }
      
      const formData = [];

      // If applicable, add an entry to allow selecting inhalable / respirable dust LVs
      if (await this.shouldShowDustLVSelector()) {
        const match = storeData.find(c => c.composition_id === null);
        if (match) {
          formData.push({...match, isDustLV: true});
        } else {
          formData.push({
            scenario: this.scenarioPk,
            exposure_type: this.exposureType,
            composition_id: null,
            limit_value_st: null,
            limit_value_lt: null,
            isDustLV: true,  // Use this as a sentinel for extra robustness, compared to checking `composition_id === null`
          });
        }
      }

      for (const item of this.substance.composition) {
        const match = storeData.find(c => c.composition_id === item.component);
        if (match) {
          // Use existing record
          formData.push(match);
        } else {
          // Create initial value
          formData.push({
            scenario: this.scenarioPk,
            exposure_type: this.exposureType,
            composition_id: item.component,
            limit_value_st: null,
            limit_value_lt: null
          });
        }
      }
      
      this.setValue('items', formData);
    },
    
    async doSave() {
      // Make sure to only post items
      const response = await this.controller.save(this.data.items);
      
      if (!response.ok) {
        if (response.body?.non_field_errors) {
          response.body.non_field_errors.forEach(error => this.$toast('error', error))
        } else {
          this.$toast('error', Alpine.enums.ActionMessage.NO_SAVE.label);
        }
        // Store validation errors on the items key
        this.setValidationErrors({items: response.body});
        return;
      }
      
      // We must have had a successful save
      this.$dispatch('configuration:saved', {pk: this.pk});
      const closeModal = this.$context('modal', 'closeModal');
      closeModal();
    },
  }),
}));

export default () => {
  Alpine.bind('ScenarioLimitValuesForm', Alpine.isolate(ScenarioLimitValuesForm, 'form'));
}
