import { defaultTo, every, filter, get, has, isArray, isFunction, set, uniqueId } from "lodash-es";

export const manyFormRow = (props) => ({
  // Each ManyForm row shadows a form itself.
  // It handles getValue and setValue as if it's the full form.
  'x-key': 'form',
  'x-data': () => ({
    get data() {
      return this.item;
    },
    
    get fieldPath() {
      // There may be multiple manyForms using the same underlying data, but filtering out some records each.
      // Since the BE only receives/returns one set of records, use the global index to find the record's errors.
      const unfilteredIndex = this.getUnfilteredIndex?.(this.item, this.index) ?? this.index;
      return `${this.fieldName}.${unfilteredIndex}`;
    },
    get rowValidationErrors() {
      const formValidationErrors = this.$context('form', 'validationErrors');
      return get(formValidationErrors, this.fieldPath, {});
    },
    
    getValue(fieldName) {
      return get(this.item, fieldName);
    },
    setValue(fieldName, value) {
      set(this.item, fieldName, value);
    },
    remove() {
      // Pass rowIdField or default to item itself
      this.removeRow(get(this.item, this.rowIdField, this.item));
    },
    
    async getDisplay(fieldName) {
      const controller = await this.initControllerPromise;
      return await controller.display(fieldName, this.data);
    },
    
    getValidationErrors(fieldName) {
      return get(this.rowValidationErrors, fieldName, []);
    },
    hasValidationErrors(fieldName) {
      return has(this.rowValidationErrors, fieldName);
    },
    
    getFiltersForField(fieldName, context) {
      const getFilters = this.$context('form', 'getFiltersForField');
      return getFilters && getFilters(fieldName, this.data);
    },

    registerField(fieldName, field) {
      // Since manyFormRow & manyForm are _not_ isolated components, the fields under the row
      // were calling registerField on the global form itself. We intercept this call.
      return undefined;
    },
    
    binds: {
      btnRemove: () => ({
        '@click.prevent': 'remove',
        'x-show': '!!isEditing'
      }),
    }
  })
});

export const manyForm = (props) => ({
  'x-data': () => ({
    fieldName: props.fieldName,
    rowIdField: defaultTo(props?.rowIdField, '_rowId'),
    
    async init() {
      // Await form to finish loading
      await this.initControllerPromise;
    },
    
    get items() {
      let items = this.getValue(this.fieldName);
      
      // Initiate items with Array if not yet
      if (!isArray(items)) {
        this.setValue(this.fieldName, []);
        items = this.getValue(this.fieldName);
      }
      
      // Assign rowIds to each item
      items = this.injectRowIds(items);
      
      return items;
    },
    set items(newItems) {
      return this.setValue(this.fieldName, newItems);
    },
    
    get filteredItems() {
      const { filters } = props;
      return filter(this.items, filters);
    },

    getUnfilteredIndex(item, index) {
      if (!this.rowIdField || !item[this.rowIdField]) {
        return index;
      }
      const globalIndex = this.items.findIndex(i => i[this.rowIdField] === item[this.rowIdField]);
      return globalIndex ?? index;
    },
    
    getValue(fieldName) {
      const form = this.$context('form');
      const getValue = this.$context('form', 'getValue');
      if (!isFunction(getValue)) return;
      return getValue(fieldName);
    },
    setValue(fieldName, value) {
      const setValue = this.$context('form', 'setValue');
      if (!isFunction(setValue)) return;
      return setValue(fieldName, value);
    },
    
    injectRowIds(items) {
      // When no rowIdField, the item itself is the row
      if (!this.rowIdField) {
        return items;
      }
      
      if (!every(items, i => get(i, this.rowIdField) !== undefined)) {
        items.forEach(item => {
          // Only set _rowId if we don't already have one
          if (!get(item, this.rowIdField)) {
            set(item, this.rowIdField, item.pk || uniqueId('new'))
          }
        });
        
        this.setValue(this.fieldName, items);
        items = this.getValue(this.fieldName);
      }
      return items;
    },
    addRow() {
      const { getEmptyRow } = props;
      const emptyRowMethod = get(this.controller, getEmptyRow).bind(this.controller);
      const emptyRow = emptyRowMethod();
      this.items.push(emptyRow);
    },
    removeRow(rowId) {
      if (this.rowIdField) {
        this.items = this.items.filter(i => i._rowId !== rowId);
      } else {
        this.items = this.items.filter(i => i !== rowId);
      }
    },
    
    binds: {
      btnAdd: () => ({
        '@click.prevent': 'addRow',
        'x-show': '!!isEditing'
      }),
      row: manyFormRow
    }
  })
});
