import Choices from "choices.js";
import { renderTemplates } from "./templates";
import { get, isArray, isEmpty } from "lodash-es";
import isolate from "/plugins/isolate";

// TODO: Refactor this at some point to reduce the amount of code complexity / spaghetti
export const choiceField = (props) => ({
  '@search.debounce.200ms': 'fetchOnSearch && handleSearch',
  '@form:loaded.document': 'handleFormReady',
  '@change.stop': 'handleChange',
  'x-effect': 'handleEffect',
  ':id': 'fieldName',
  ':name': 'fieldName',
  'x-data': () => ({
    choices: undefined,
    fieldName: props.fieldName,

    _store: undefined,

    isReady: false,
    initialValue: null,

    // Configuration
    required: props.required,
    fetchUrl: props.fetchURL,
    fetchOnSearch: props.fetchOnSearch && props.fetchURL,
    fetchOnInit: props.fetchOnInit && props.fetchURL,
    multiple: props.multiple,
    
    optionLabelField: props.optionLabelField || 'name',
    optionValueField: props.optionValueField || 'pk',
    
    get fieldValue() {
      return this.choices?.getValue(true);
    },
    set fieldValue(value) {
      if (!this.choices) return;
      
      if (this.multiple) {
        this.choices.removeActiveItems();
        
        if (isEmpty(value)) {
          return;
        }
      }

      this.choices.setChoiceByValue(this.cleanValue(value));
    },
    
    get store() {
      if (!this._store)
        this._store = Alpine.getStoreByURL(this.fetchUrl);
      return this._store;
    },
    
    async init() {
      await this.initChoices();
    },
    async initChoices() {
      const {searchFields, choicesConfig, templateName='default', fieldName} = props;
      
      // Startup Choices.js
      const preDefinedChoices = await this.getPreDefinedChoices();
      this.choices = new Choices(this.$el, {
        classNames: {containerOuter: `choices ${fieldName}`},
        callbackOnInit: () => this.onChoicesInit(this),
        callbackOnCreateTemplates: renderTemplates(templateName),
        searchFields: searchFields.split(','),
        shouldSort: false,
        ...(preDefinedChoices && {choices: this.formatChoices(preDefinedChoices)}),
        ...choicesConfig,
      });
      await this.handleEffect();  // Trigger loading fieldValue after choices is initialised
    },
    async fetchSelectedChoices(fieldValue) {
      if (!this.store) return;

      // Now that we have a value, we should make sure the matching choice exists
      const selectedChoices = isArray(fieldValue)
        ? await this.store.getMany(fieldValue)
        : await this.store.getOne(fieldValue);
      await this.setChoices(selectedChoices);
    },
    
    hasValue(value) {
      return this.getChoices().find(c => c.value === value);
    },
    cleanValue(value) {
      if (this.multiple) {
        if (!isArray(value)) {
          value = [value];
        }
        value = value.filter(v => ![null, undefined].includes(v));
      } else {
        value = [null, undefined].includes(value) ? '' : value;
      }
      return value;
    },
    getChoices() {
      return this.choices && this.choices._store.choices;
    },

    async onChoicesInit(self) {
      /**
       * Triggered after choices.js initialized.
       * Inside this callback `this` points to the choices instance, use `self` for reference
       * to the Alpine component.
       */
      const { fetchURL, fetchOnInit, getFieldValue } = props;

      /**
       * Initial value could be null/undefined when we ask for getFieldValue(). The hope is that
       * handleEffect() runs some number of time while we're in the process of fetching choices
       * that initialValue updates with the correct value from the field.
       * We then set fieldValue from initialValue.
       */
      self.initialValue = await getFieldValue();

      // Try fetching choices
      if (fetchURL) {
        // If we're asked to fetch on init, fetch choices.
        if (fetchOnInit) {
          await self.fetchChoices();
        }

        // If field has been initialized with pk(s), fetch appropriate choice(s) from the store.
        if (![undefined, null].includes(self.initialValue) && !isEmpty(self.initialValue)) {
          await self.fetchSelectedChoices(self.initialValue);
        }
      }

      // Pray that initial value is actually set properly by the time we reach this line.
      self.fieldValue = self.initialValue;

      // When ready handleEffect functions as normal.
      self.isReady = true;
    },
    
    async getPreDefinedChoices() {
      const { getFieldPreDefinedData } = props;
      return getFieldPreDefinedData && getFieldPreDefinedData(`${this.fieldName}__choices`) || [];
    },
    
    formatChoices(choices) {
      if ([null, undefined].includes(choices)) {
        return [];
      }

      const { optionLabelField, optionValueField } = props;
      if (!isArray(choices)) {
        choices = [choices];
      }
      const isGrouped = choices.filter(c => get(c, 'choices', false)).length > 0;
      const formatChoice = (item) => {
        if (item.customProperties !== undefined)
          return item;
        return {
          label: get(item, optionLabelField || 'label'),
          value: get(item, optionValueField || 'value'),
          customProperties: {
            ...item
          }
        };
      };
      
      if (isGrouped) {
        return choices.map(group => ({
          label: group.label,
          id: group.id,
          choices: group.choices.map(item => formatChoice(item))
        }));
      }
      
      return choices.map(item => formatChoice(item));
    },
    async fetchChoices(filters={}) {
      const { getFieldFilters, fieldName } = props;
      const fieldFilters = await getFieldFilters(fieldName);
      
      const response = await this.store.fetchList({
        pagination: {offset: 0, limit: 100},
        filters: {...filters, ...fieldFilters}
      });
      await this.setChoices(response.body);
    },
    async setChoices(choices, resetChoices=false) {
      const formattedChoices = this.formatChoices(choices);
      if (resetChoices) {
        const placeHolderChoice = this.getChoices().find(c => c.placeholder === true);
        this.choices.clearChoices();
        await this.choices.setChoices([placeHolderChoice, ...formattedChoices]);
      } else {
        const existingValues = new Set(this.getChoices().map(c => c.value));
        const filteredChoices = formattedChoices.filter(c => !existingValues.has(c.value));
        await this.choices.setChoices(filteredChoices);
      }
    },
    async handleFormReady() {
      const predefinedChoices = await this.getPreDefinedChoices();
      await this.setChoices(predefinedChoices, true);
    },
    async handleEffect() {
      /**
       * Triggered when data changes in the form.
       * We use this to set the correct value in the choice field to reflect the change of data in the form.
       * Use the getFieldValue callback to fetch the current value from the form for this field.
       */
      const { getFieldValue } = props;
      const fieldValue = await getFieldValue();

      if (!this.isReady) {
        this.initialValue = fieldValue;
        return;
      }

      await this.fetchSelectedChoices(fieldValue);
      this.fieldValue = fieldValue;
    },
    handleChange(event) {
      /**
       * Triggered when a user changes the value of the choice field.
       * Be careful not to use the event.detail.value as it only contains the change value,
       * not the total field value. This is mostly applicable to multiple select fields.
       */
      const { onChange } = props;
      onChange(this.fieldValue);
      this.$dispatch('fieldUpdated');
    },
    async handleSearch(event) {
      /**
       * Triggered when a user searches the choice field.
       * We use this to fetch additional choices from the server using keyword searches.
       */
      if (!this.fetchOnSearch) return;
      const { detail } = event;
      await this.fetchChoices({keyword: detail.value});
      this.choices._searchChoices(detail.value);
    },
  })
});

export default () => {
  Alpine.bind('choiceField', isolate(choiceField, 'choiceField'));
}