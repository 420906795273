import { extendComponent } from "/utils/utils";
import { cloneDeep, forIn, get, isEqual, keys, pickBy, set } from "lodash-es";
import { form } from "./forms/base/form";

export const datagridFilters = extendComponent(form,(props={}) => ({
  'x-data': () => ({
    showFilterForm: false,
    isEditing: true,
    data: {},  // Overriding form data as we're setting filterData directly
    datagrid: props?.datagrid,
    
    async init() {
      await this.initForm();
      this.$watch("JSON.stringify(data)", () => this.applyFilters());
    },
    
    registerField(fieldName, field) {
      this.fields[fieldName] = field;
      // We set the initialValue directly on data for filters
      set(this.data, fieldName, field.initialValue);
    },
    
    async initController() {
      this.controller = this.datagrid.listController;
      return this.controller;
    },
    
    hasFilterForm() {
      return Object.keys(this.fields).filter(k => k !== 'keyword').length > 0;
    },
    
    getFilterCount() {
      const activeFilters = keys(pickBy(this.data, (v, k) => {
        return !isEqual(v, this.fields[k]?.initialValue);
      }));
      return activeFilters.length;
    },
        
    applyFilters() {
      this.datagrid.listController.applyFilters(this.data);
    },
    
    resetFilters() {
      forIn(this.fields, (value, key) => {
        if (get(this.data, key) !== undefined) {
          set(this.data, key, cloneDeep(value.initialValue));
        }
      });
    },
    
    // Binds
    filterKeywordInput: () => ({
      'name': 'keyword',
      'type': 'search',
      'autocomplete': 'off',
      'class': 'text-muted',
      'x-model.debounce.500ms': 'data.keyword',
      'x-data': () => ({
        init() {
          this.initialValue = '';
          if (typeof this.fields === 'object') {
            this.fields['keyword'] = this;
          }
        }
      })
    }),
    filterButton: () => ({
      '@click': 'showFilterForm=!showFilterForm',
      ['x-show']() {
        return this.hasFilterForm();
      },
      [':class']() {
        return {
          'open': this.showFilterForm
        }
      }
    }),
    filterResetButton: () => ({
      'type': 'button',
      '@click': 'resetFilters',
      [':disabled']() {
        return !this.getFilterCount();
      },
      [':class']() {
        return {
          'disabled': !this.getFilterCount()
        }
      },
      ['x-show']() {
        return this.hasFilterForm();
      },
    }),
    filterBadge: () => ({
      ['x-show']() {
        return !!this.getFilterCount();
      },
      'x-text': 'getFilterCount'
    }),
    filterForm: () => ({
      ['x-show']() {
        return !!this.showFilterForm;
      },
    }),
  }),
}));

export default () => {
  Alpine.bind('datagridFilters', Alpine.isolate(datagridFilters, 'form'));
};